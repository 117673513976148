import { GridColDef } from "@mui/x-data-grid";
import { observer } from "mobx-react";
import formatDate from "../../../../utils/formatDate";
import { useStores } from "../../../../hooks/useStores";
import { DataGridBox } from "../../../../styles/dataGridBox";
import { DataGridPro } from "@mui/x-data-grid-pro";

const columns: GridColDef[] = [
  {
    field: "projectNumber",
    headerName: "Project Number",
    flex: 1,
    minWidth: 180,
  },
  {
    field: "projectDescription",
    headerName: "Project Description",
    flex: 1,
    minWidth: 180,
  },
  {
    field: "updatedDate",
    headerName: "Modified On",
    flex: 1,
    minWidth: 150,
    valueFormatter: formatDate,
  },
  {
    field: "updatedBy",
    headerName: "Modified By",
    flex: 1,
    minWidth: 200,
  },
];

const TagDetailInfoTab = observer(() => {
  const {
    projectAreaCardViewInfoStore: { infoList, fetchInfoById },
    projectAreaCardViewStore: { assetDetail },
  } = useStores();

  return (
    <DataGridBox>
      <DataGridPro
        columns={columns}
        rows={infoList ?? []}
        density="comfortable"
        sx={{ height: "700px" }}
      />
    </DataGridBox>
  );
});

export default TagDetailInfoTab;
