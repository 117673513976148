import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import {
  Box,
  Button,
  TextField,
  Stack,
  Typography,
  Backdrop,
  CircularProgress
} from "@mui/material";
import { useState, useEffect } from "react";
import usePageTitle from "../../../hooks/usePageTitle";
import { areaboxStyle} from "../../../constants/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { NOTIFICATION_TYPES } from "../../../constants/notificationTypes";
import { NullableString } from "../../../models/common/response/types";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { UpdateLocationRequest } from "../../../models/Dictionaries/request/updateLocationRequest";
import { validationColor } from "../../../utils/validation";

const LocationItem = observer(() => {
  usePageTitle("References - Location");

  const navigate = useNavigate();

  const [urlParams] = useSearchParams()

  const { referencesStore, notificationStore } = useStores();
  
  const [locationId, setLocationId] = useState<number>(0);
  const [location, setLocation] = useState<NullableString>("");
  const [locationCode, setLocationCode] = useState<NullableString>("");
  const [locationDescription, setLocationDescription] = useState<NullableString>("");
  const [locationRemarks, setLocationRemarks] = useState<NullableString>("");

 
  useEffect(() => {
    (async () => {   
      await initLocation();
    })()
  }, []);

  const initLocation = async () => {

    const idParam = parseInt(urlParams.get("id") ?? "0");
    await referencesStore.fetchLocationById(idParam);

    setLocationId(idParam);
    setLocation(referencesStore.location?.location ?? "");
    setLocationCode(referencesStore.location?.locationCode ?? "");
    setLocationDescription(referencesStore.location?.locationDescription ?? "");
    setLocationRemarks(referencesStore.location?.locationRemarks ?? "");
  }
  
  const handleCloseClick = () => {
    navigate(-1);
  };

  const handleSaveClick = async () => {
    try {
      const locationItem: UpdateLocationRequest = {
        id: locationId,
        location: location,
        locationCode: locationCode,
        locationDescription: locationDescription,
        locationRemarks: locationRemarks
      }
      await referencesStore.updateLocation(locationItem);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Location edited successfully");
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error editing location: " + error);
    }
    finally {
      notificationStore.setShowNotification(true);
      navigate(ROUTES.referencesLocations);
    }
  }

  return (
    referencesStore.location ?
    <form>
    <Box
          sx={ areaboxStyle }
        > 
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "#fff",
              display: { xs: "block", md: "flex", width: "50%" }
            }}
          >
            <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
              <ArrowBackOutlinedIcon />
            </Button>
            <Button 
              variant="contained" 
              sx={{ ml: 2 }} 
              disabled={!location || !locationCode || !locationDescription}
              onClick={() => handleSaveClick()}>
              <span>Save</span>
            </Button> 
          </Box>
        </Stack>
      </Box>
    {    
    <Box sx={{ "overflow-y": "scroll", height: "80vh" }}> 
      <Box
          sx={ areaboxStyle }
        >  
        <Typography
            id="add-tag-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
          Location
        </Typography>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Location"
            value={location}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(location) }}
            onChange={(e) => setLocation(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Location Code"
            value={locationCode}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(locationCode) }}
            onChange={(e) => setLocationCode(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Location Description"
            value={locationDescription}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(locationDescription) }}
            onChange={(e) => setLocationDescription(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Location Remarks"
            value={locationRemarks}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            onChange={(e) => setLocationRemarks(e.target.value)}
          />
        </Stack>
        </Box> 
      </Box>
      }
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={referencesStore.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </form>
      :
      null
  );
});

export default LocationItem;
