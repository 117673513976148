import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import {
  Box,
  Button,
  TextField,
  Stack,
  Tabs,
  Tab,
  Backdrop
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useEffect } from "react";
import usePageTitle from "../../../hooks/usePageTitle";
import { areaboxStyle} from "../../../constants/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import CustomTabPanel from "../../../components/common/CustomTabPanel";
import { NullableString } from "../../../models/common/response/types";
import { NOTIFICATION_TYPES } from "../../../constants/notificationTypes";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { a11yProps } from "../gridView/utils/utils";
import EquipmentTagsTab from "./tabs/equipmenttags/EquipmentTagsTab";
import { UpdateMasterDocumentEquipmentTagsById } from "../../../models/MasterArea/request/updateMasterDocumentEquipmentTagsById";

const MasterAreaEditDocument = observer(() => {
  usePageTitle("Master Area - Document");

  const navigate = useNavigate();

  const [urlParams] = useSearchParams()

  const { masterAreaDocumentsStore, notificationStore } = useStores();

  const [tabValue, setValue] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [documentId, setDocumentId] = useState<number>(0);
  const [documentNumber, setDocumentNumber] = useState<NullableString>(null);
  const [documentCode, setDocumentCode] = useState<NullableString>(null);
  const [documentType, setDocumentType] = useState<NullableString>(null);
  const [documentUrl, setDocumentUrl] = useState<NullableString>(null);

 
  useEffect(() => {
    (async () => {   
      await initDocument();
    })()
  }, []);

  const initDocument = async () => {

    const idParam = parseInt(urlParams.get("id") ?? "0");
    await masterAreaDocumentsStore.fetchDocumentById(idParam);

    setDocumentId(idParam);
    setDocumentNumber(masterAreaDocumentsStore.documentResponse?.documentNumber);
    setDocumentCode(masterAreaDocumentsStore.documentResponse?.documentNumber);
    setDocumentType(masterAreaDocumentsStore.documentResponse?.documentType);
    setDocumentUrl(masterAreaDocumentsStore.documentResponse?.documentUrl);

    await masterAreaDocumentsStore.fetchMasterAssetsByDocumentId(idParam);
  }
  
  const handleCloseClick = () => {
    navigate(-1);
  };

  const handleSaveClick = async () => {
    try {
      const documentEquipmentTags: UpdateMasterDocumentEquipmentTagsById = {
        masterDocumentId: documentId,
        masterEquipmentsIds: masterAreaDocumentsStore.equipmentTags.map(eq => eq.id)
      }
      await masterAreaDocumentsStore.updateDocumentEquipmentTagsById(documentEquipmentTags);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Document edited successfully");
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error editing document: " + error);
    }
    finally {
      notificationStore.setShowNotification(true);
      navigate(ROUTES.masterAreaGridDocuments);
    }
  }

  return (
    masterAreaDocumentsStore.documentResponse?.documentNumber ?
    <form>
    <Box
          sx={ areaboxStyle }
        > 
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "#fff",
              display: { xs: "block", md: "flex", width: "50%" }
            }}
          >
            <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
              <ArrowBackOutlinedIcon />
            </Button>
            <Button 
              variant="contained" 
              disabled={masterAreaDocumentsStore.equipmentTags?.length < 1} 
              sx={{ ml: 2 }} 
              onClick={() => handleSaveClick()}>
              <span>Save</span>
            </Button> 
          </Box>
        </Stack>
      </Box>
    {    
    <Box sx={{ "overflow-y": "scroll", height: "80vh" }}> 
    <div style={ areaboxStyle }>
      <Tabs
        value={tabValue}
        variant="scrollable"
        aria-label="New-Tag-Tabs"
        onChange={handleTabChange}
      >
        <Tab label="General" {...a11yProps(0)}/>
        <Tab label="Equipment Tags" {...a11yProps(1)} />
      </Tabs>    
    </div>
    <CustomTabPanel value={tabValue} index={0}>
        <Box
          sx={ areaboxStyle }
        >  
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Document"
            value={documentNumber + ""}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            disabled
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Document Code"
            value={documentCode + ""}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            disabled
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Document Type"
            value={documentType + ""}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            disabled
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Document Url"
            value={documentUrl + ""}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            disabled
          />
        </Stack>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <EquipmentTagsTab Toolbar={true}/>
      </CustomTabPanel>
      </Box>
      }
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={masterAreaDocumentsStore.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </form>
      :
      null
  );
});

export default MasterAreaEditDocument;
