import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import { formatDateWithTime } from "../../../../utils/formatDate";
import { DataGridBox } from "../../../../styles/dataGridBox";
import TagDetailTagAttributesToolBar from "../components/tagAttributes/TagDetailTagAttributesToolBar";
import { useEffect } from "react";

const columns: GridColDef[] = [
  {
    field: "tagAttributeName",
    headerName: "Attribute Name",
    flex: 1,
    minWidth: 180,
  },
  {
    field: "tagAttributeValue",
    headerName: "Attribute Value",
    flex: 1,
    minWidth: 180,
  },
  {
    field: "uom",
    headerName: "UOM",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "createdDate",
    headerName: "Created On",
    flex: 1,
    minWidth: 150,
    valueFormatter: formatDateWithTime,
  },
  {
    field: "createdBy",
    headerName: "Created By",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "updatedDate",
    headerName: "Modified On",
    flex: 1,
    minWidth: 150,
    valueFormatter: formatDateWithTime,
  },
  {
    field: "updatedBy",
    headerName: "Modified By",
    flex: 1,
    minWidth: 200,
  },
];

const TagDetailAttributesTab = observer(() => {
  const {
    projectAreaCardViewStore: { tagAttributes },
    projectAreaCardViewTagAttributesStore: tagAttributeStore,
    dictionaryStore,
  } = useStores();

  useEffect(() => {
    dictionaryStore.fetchTagAttributesNamesByTypeOptions("", 0);
    dictionaryStore.fetchUnitOfMeasuresOptions("");
  }, [dictionaryStore, tagAttributes]);

  return (
    <DataGridBox>
      <DataGrid
        columns={columns}
        rows={tagAttributes ?? []}
        density="comfortable"
        checkboxSelection
        onRowSelectionModelChange={(ids) => {
          const selectedIds = new Set(ids);
          tagAttributeStore.setSelectedIds(
            Array.from(selectedIds).map((id) => parseInt(id as string))
          );
          tagAttributeStore.setSelectedId(tagAttributeStore.selectedId);
        }}
        slots={{
          toolbar: TagDetailTagAttributesToolBar,
        }}
        sx={{ height: "700px" }}
      />
    </DataGridBox>
  );
});

export default TagDetailAttributesTab;
