import {
  GridToolbarColumnsButton,
  GridToolbarContainer
} from "@mui/x-data-grid";

import { observer } from "mobx-react";
import CustomQuickSearchBarMasterArea from "./CustomQuickSearchBarMasterArea";
import CustomExcelExportButtonMasterArea from "./CustomExcelExportButtonMasterArea";
import CustomMultiActionButtonMasterArea from "./CustomMultiActionButtonMasterArea";
import MasterAreaAddTag from "./MasterAreaAddTag";
import MasterAreaEditTag from "./MasterAreaEditTag";
import MasterAreaCopyTag from "./MasterAreaCopyTag";
import MasterAreaDeleteTag from "./MasterAreaDeleteTag";
import MasterAreaCategoryFilter from "./MasterAreaCategoryFilter";
import CustomFilterButtonMasterArea from "./CustomFilterButtonMasterArea";
import MasterAreaWorkingCopyTag from "./MasterAreaWorkingCopyTag";
import ImportFromExcelMasterAreaButton from "./importFromExcel/ImportFromExcelMasterAreaButton";
import MasterAreaRefreshPage from "./MasterAreaRefreshPage";

interface CustomToolBarMasterAreaProps {
  setRowSelectionModel: any;
  applyFilter: boolean;
}

const CustomToolBarMasterArea = observer((props: CustomToolBarMasterAreaProps) => {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", margin: "0 0 0 10px" }}>
        <GridToolbarContainer>
          <MasterAreaAddTag />
          <MasterAreaEditTag />
          <MasterAreaCopyTag />
          <MasterAreaWorkingCopyTag />
          <MasterAreaDeleteTag />
          <MasterAreaRefreshPage setRowSelectionModel={props.setRowSelectionModel}/>
          <CustomFilterButtonMasterArea applyFilter={props.applyFilter}/>
          <GridToolbarColumnsButton />
          <CustomExcelExportButtonMasterArea />
          <ImportFromExcelMasterAreaButton />
          <CustomMultiActionButtonMasterArea />        
        </GridToolbarContainer>
        <CustomQuickSearchBarMasterArea />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", margin: "0 0 0 10px" }}>
        <GridToolbarContainer>
          <MasterAreaCategoryFilter applyFilter={props.applyFilter} />      
        </GridToolbarContainer>
      </div>
    </div>
  );
});

export default CustomToolBarMasterArea;
