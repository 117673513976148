import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import {
  Box,
  Button,
  TextField,
  Stack,
  Tabs,
  Tab,
  Backdrop
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useEffect } from "react";
import usePageTitle from "../../../hooks/usePageTitle";
import { areaboxStyle} from "../../../constants/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import CustomTabPanel from "../../../components/common/CustomTabPanel";
import { NullableString } from "../../../models/common/response/types";
import { a11yProps } from "../gridView/utils/utils";
import { NOTIFICATION_TYPES } from "../../../constants/notificationTypes";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import EquipmentTagsTab from "./tabs/equipmenttags/EquipmentTagsTab";
import { UpdateMasterProjectEquipmentTagsById } from "../../../models/MasterArea/request/updateMasterProjectEquipmentTagsById";

const MasterAreaEditProject = observer(() => {
  usePageTitle("Master Area - Project");

  const navigate = useNavigate();

  const [urlParams] = useSearchParams()

  const { masterAreaProjectsStore, notificationStore } = useStores();

  const [tabValue, setValue] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [projectId, setProjectId] = useState<number>(0);
  const [projectNumber, setProjectNumber] = useState<NullableString>(null);
  const [projectCode, setProjectCode] = useState<NullableString>(null);
  const [projectDescription, setProjectDescription] = useState<NullableString>(null);

 
  useEffect(() => {
    (async () => {   
      await initProject();
    })()
  }, []);

  const initProject = async () => {

    const idParam = parseInt(urlParams.get("id") ?? "0");
    await masterAreaProjectsStore.fetchProjectById(idParam);
    
    setProjectId(idParam);
    setProjectNumber(masterAreaProjectsStore.projectResponse?.projectNumber);
    setProjectCode(masterAreaProjectsStore.projectResponse?.projectCode);
    setProjectDescription(masterAreaProjectsStore.projectResponse?.projectDescription);

    await masterAreaProjectsStore.fetchMasterAssetsByProjectId(idParam);
  }

  const handleSaveClick = async () => {
    try {
      const projectEquipmentTags: UpdateMasterProjectEquipmentTagsById = {
        projectId: projectId,
        masterEquipmentId: masterAreaProjectsStore.equipmentTags.map(eq => eq.id)
      }
      await masterAreaProjectsStore.updateProjectEquipmentTagsById(projectEquipmentTags);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Project edited successfully");
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error editing project: " + error);
    }
    finally {
      notificationStore.setShowNotification(true);
      navigate(ROUTES.masterAreaGridProjects);
    }
  }
  
  const handleCloseClick = () => {
    navigate(-1);
  };

  return (
    masterAreaProjectsStore.projectResponse?.projectNumber ?
    <form>
    <Box
          sx={ areaboxStyle }
        > 
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "#fff",
              display: { xs: "block", md: "flex", width: "50%" }
            }}
          >
            <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
              <ArrowBackOutlinedIcon />
            </Button>
            <Button 
              variant="contained" 
              disabled={masterAreaProjectsStore.equipmentTags?.length < 1} 
              sx={{ ml: 2 }} 
              onClick={() => handleSaveClick()}>
              <span>Save</span>
            </Button> 
          </Box>
        </Stack>
      </Box>
    {    
    <Box sx={{ "overflow-y": "scroll", height: "80vh" }}> 
    <div style={ areaboxStyle }>
      <Tabs
        value={tabValue}
        variant="scrollable"
        aria-label="New-Tag-Tabs"
        onChange={handleTabChange}
      >
        <Tab label="General" {...a11yProps(0)}/>
        <Tab label="Equipment Tags" {...a11yProps(1)} />
      </Tabs>    
    </div>
    <CustomTabPanel value={tabValue} index={0}>
        <Box
          sx={ areaboxStyle }
        >  
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Project Number"
            value={projectNumber + ""}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            disabled
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Project Code"
            value={projectCode + ""}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            disabled
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Project Description"
            value={projectDescription + ""}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            disabled
          />
        </Stack>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <EquipmentTagsTab Toolbar={true}/>
      </CustomTabPanel>
      </Box>
      }
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={masterAreaProjectsStore.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </form>
      :
      null
  );
});

export default MasterAreaEditProject;
