import { makeAutoObservable, runInAction } from "mobx";
import { GridSortModel } from "@mui/x-data-grid";
import { SortOrder } from "../../models/common/request/sorting";
import { GetMasterAreaDocumentsResponse } from "../../models/MasterArea/response/getMasterAreaDocumentsResponse";
import { GetMasterAreaDocumentsRequest } from "../../models/MasterArea/request/getMasterAreaDocumentsRequest";
import { GetMasterAreaDocumentByIdResponse } from "../../models/MasterArea/response/getMasterAreaDocumentByIdResponse";
import {  Filter, GetMasterAssetsRequest,  MasterAssetsFilterOptions, MasterAssetsSortOptions } from "../../models/MasterArea/request/getMasterAssetsRequest";
import apiClient from "../../api/clients";
import { NullableBoolean, NullableDate, NullableString } from "../../models/common/response/types";
import { UpdateMasterDocumentEquipmentTagsById } from "../../models/MasterArea/request/updateMasterDocumentEquipmentTagsById";
import { GetMasterDocumentsByIdResponse } from "../../models/MasterArea/response/getMasterDocumentsByIdResponse";

export type DocumentEquipmentTag = {
  id: number,
  tagNumber: string,
  category: NullableString;
  createdBy: NullableString,
  createdOn: NullableDate,
  controlAutonaming: NullableBoolean,
  status: NullableString,    
}

class MasterAreaDocumentsStore {
  documentResponse: GetMasterAreaDocumentByIdResponse | null = null;
  documents?: GetMasterDocumentsByIdResponse[];
  documentsResponse: GetMasterAreaDocumentsResponse = {
    items: [],
    pageIndex: 0,
    pageSize: 0,
    totalPages: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    totalRecords: 0,
  };

  showAddEquipmentTagModal: boolean = false;
  equipmentTagsSelectedIds: number[] = [];
  equipmentTags: DocumentEquipmentTag[] = [];

  paginationModel = {
    pageSize: 250,
    page: 0,
  };
  sortModel: GridSortModel = [{field: "createdOn", sort: "desc"}];
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setEquipmentTagsSelectedIds = (ids: number[]) => {
    this.equipmentTagsSelectedIds = ids;
  };

  setEquipmentTags = (equipmentTags: DocumentEquipmentTag[]) => {
    this.equipmentTags = equipmentTags;
  };

  setPaginationModel = (paginationModel: any) => {
    this.paginationModel = paginationModel;
  };

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  setShowAddEquipmentTagModal = (value: boolean) => {
    this.showAddEquipmentTagModal = value;
  };

  fetchDocuments = async () => {    
    const { page, pageSize } = this.paginationModel;

    const request: GetMasterAreaDocumentsRequest = {
      pagination: { pageIndex: page, pageSize }
    };

    try {
      this.setIsLoading(true);
      const data = await apiClient.getMasterAreaDocuments(request);
      runInAction(() => {
        this.documentsResponse = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

  fetchDocumentById = async (id: number) => {    
    try {
      this.setIsLoading(true);
      const data = await apiClient.getMasterAreaDocumentById(id);
      runInAction(() => {
        this.documentResponse = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

  fetchDocumentsById = async (id: number) => {
    try {
      const asset = await apiClient.getMasterDocumentsById(id);
      runInAction(() => {
        this.documents = asset;
      });
    } catch (error) {
      console.error("Error fetching Documents:", error);
    }
  };

  async updateDocumentEquipmentTagsById(request: UpdateMasterDocumentEquipmentTagsById) {
    try {
      this.setIsLoading(true);
      await apiClient.updateMasterDocumentEquipmentTagsById(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error editing document equipment tag:", error);
      throw error;
    }
  }

  fetchMasterAssetsByDocumentId = async (documentId: number) => {    
    const { page, pageSize } = this.paginationModel;

    const sortOptions: MasterAssetsSortOptions = {
      projectNumber: "projectNumber",
      tagNumber: "tagNumber",
      area: "area",
      facility: "facility",
      unit: "unit",
      status: "status",
      category: "category",
      serviceDescription: "serviceDescription",
      type: "type",
      parentTag: "parentTag",
      remarks: "remarks",
      requestNumber: "requestNumber",
      suffix: "suffix",
      sequenceNumber: 'sequenceNumber',
      disciplineTagNumber: 'disciplineTagNumber',
      createdBy: "createdBy",
      createdOn: "createdDate"
    };

    const filterOptions: MasterAssetsFilterOptions = {
      categoryId: 0,
      tagNumber: "",
      searchText: "",
      projectNumberId: 0,
      equipmentStatusId: 0,
      areaId: 0,
      facilityId: 0,
      unitId: 0,
      equipmentTypeId: 0,
      tagTypeId: 0,
      parentTagNumber: "",
      requestNumber: "",
      sequenceNumber: "",
      remarks: "",
      suffix: "",
      serviceDescription: "",
      documentId: documentId
    };
    const sortField = this.sortModel?.[0]?.field;
    const sortOrder =
      this.sortModel?.[0]?.sort === "asc" ? SortOrder.ASC : SortOrder.DESC;
    const sortProperty = sortField
      ? sortOptions[sortField as keyof MasterAssetsSortOptions]
      : undefined;

    const sort = sortProperty
      ? {
          [sortProperty]: { sortOrder },
        }
      : undefined;

    const filter: Filter = Object.keys(filterOptions).reduce((acc, key) => {
      const filterValue = filterOptions[key as keyof MasterAssetsFilterOptions];
      if (filterValue !== undefined) {
        acc[key as keyof Filter] = { filterValue: filterValue as never };
      }
      return acc;
    }, {} as Filter);

    const request: GetMasterAssetsRequest = {
      pagination: { pageIndex: page, pageSize },
      filter,
      sort,
    };

    try {
      this.setIsLoading(true);
      const data = await apiClient.getMasterAssets(request);
      runInAction(() => {
        this.equipmentTags = data.items;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

}

export default MasterAreaDocumentsStore;
