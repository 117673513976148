import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { DeleteRelatedTagRequest } from "../../../../../models/ProjectArea/request/deleteRelatedTagRequest";
import ConfirmationDialogModal from "../../../../../components/common/ConfirmationDialog";
import { useState } from "react";

const TagDetailDeleteRelatedTag = observer(() => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const {
    projectAreaCardViewRelatedTagStore,
    projectAreaCardViewStore: { fetchRelatedTagsById, assetDetail, fetchAssetById },
    notificationStore,
  } = useStores();

  const handleDeleteClick = async () => {
    const relatedTagIds = projectAreaCardViewRelatedTagStore.selectedIds;

    const data: DeleteRelatedTagRequest = {
      ids: relatedTagIds,
    };

    await projectAreaCardViewRelatedTagStore.deleteRelatedTag(data);

    fetchRelatedTagsById(assetDetail?.id!, assetDetail?.projectId!);
    notificationStore.setNotificationMessage("Related tag deleted successfully");
    notificationStore.setShowNotification(true);
    fetchAssetById(assetDetail?.id!);
    setShowConfirm(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<DeleteIcon />}
        disabled={projectAreaCardViewRelatedTagStore.selectedIds.length < 1}
        onClick={() => setShowConfirm(true)}
      >
        Delete
      </Button>
      <ConfirmationDialogModal
        handleOkClick={handleDeleteClick}
        handleCancelClick={() => setShowConfirm(false)}
        showConfirmationDialog={showConfirm}
        headerText="Confirm Deleting Related Tags"
        confirmationText="Are you sure you want to delete selected related tags?"
        okButtonText="Ok"
        cancelButtonText="Cancel"
      />
    </>
  );
});

export default TagDetailDeleteRelatedTag;
