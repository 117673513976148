import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import Button from "@mui/material/Button";
import RefreshIcon from '@mui/icons-material/Refresh';

interface MasterAreaRefreshPageProps {
  setRowSelectionModel: any;
}

const MasterAreaRefreshPage = observer((props: MasterAreaRefreshPageProps) => {
  const { masterAssetsStore } = useStores();

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<RefreshIcon />}
        onClick={() => {props.setRowSelectionModel([]); masterAssetsStore.fetchMasterAssets();}}
        >
        Refresh
      </Button>
    </>
  );
});

export default MasterAreaRefreshPage;
