import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import {
  Box,
  Button,
  TextField,
  Typography,
  Autocomplete,
  Stack,
  Tabs,
  Tab,
  FormControlLabel,
  Checkbox,
  Backdrop,
  Chip,
  Divider,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useEffect } from "react";
import { useDebounce } from "../../../hooks/useDebounce";
import { SelectOption } from "../../../models/common/selectOption";
import {
  editBBTag,
  checkDuplicateBBTag,
  updateCounter,
  editBBTagNoClose,
} from "./hooks/useFetchRequests";
import usePageTitle from "../../../hooks/usePageTitle";
import {
  areaboxStyle,
  areaboxStyleFlex,
  areaboxStyleNoMargin,
} from "../../../constants/styles";
import { useNavigate, useSearchParams, useBlocker } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import CustomTabPanel from "../../../components/common/CustomTabPanel";
import { validationColor } from "../../../utils/validation";
import { a11yProps, processString, buildStringOption, validationColorWithControlAutonaming, isPrimaryTagRequired, getPrimaryTagLabel,
  isFittingNumberRequired, isCircuitNumberRequired, isBuildingRequired, buildingColor, isLocationRequired, isInsulationTypeRequired,
  isNominalDiameterRequired, isPipeClassRequired, isFluidRequired, isServiceDescriptionRequired, disciplineTagNumberColor, validationPrimaryTagColor,
  isSubstationRequired, validateField, validateFieldWithControlAutonaming, validateDisciplineTagNumber, sequenceNumberColor, validateSequenceNumber,
  validateBuilding, validatePrimaryTag, masterDocumentsToSelectOptions, 
  selectOptionToMasterDocument,
  hideTagLimit} from "./utils/utils";
import MasterTagDetailAttributesTab from "./tabs/MasterTagDetailAttributesTab";
import MasterTagDetailDocumentsTab from "./tabs/MasterTagDetailDocumentsTab";
import MasterRelatedTagsTab from "./tabs/MasterTagDetailRelatedTagsTab";
import MasterTagDetailHistoryTab from "./tabs/MasterTagDetailHistoryTab";
import {
  EditBBTagRequest,
  EditRelatedTags,
  EditTagAttributes,
} from "../../../models/TagRequest/request/editBBTagRequest";
import {
  NullableString,
  NullableNumber,
} from "../../../models/common/response/types";
import ConfirmationDialogModal from "../../../components/common/ConfirmationDialog";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import { CheckDuplicateBBTag } from "../../../models/TagRequest/request/checkDuplicateBBTag";
import { UpdateCounterRequest } from "../../../models/MasterArea/request/updateCounterRequest";
import DuplicateTagWarningModal from "../gridView/components/DuplicateTagWarningModal";
import ValidateSequenceNumberWarningModal from "../gridView/components/ValidateSequenceNumberWarningModal";
import { MasterAssetDetailRelatedTags, MasterDocuments } from "../../../models/MasterArea/response/getAssetsDetailResponse";
import { TagLimitOptions } from "./constants/constants";

const MasterAreaEditTag = observer(() => {
  usePageTitle("Master Area - Edit Tag");

  const navigate = useNavigate();

  const [urlParams] = useSearchParams();

  const {
    masterAreaCardViewStore,
    masterAssetsStore,
    notificationStore,
    dictionaryStore,
    userStore,
    referencesStore,
    masterAreaTagAttributesStore
  } = useStores();

  const [tabValue, setValue] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    validateForm();
  });

  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [isRefreshInProgress, setIsRefreshInProgress] =
    useState<boolean>(false);
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
  const [isSaveWithoutBlocking, setIsSaveWithoutBlocking] = useState<boolean>(false);

  const [tagId, setTagId] = useState<NullableNumber>(null);
  const [isControlAutonaming, setIsControlAutonaming] = useState<boolean>(true);
  const [createdOn, setCreatedOn] = useState<NullableString>("");
  const [createdBy, setCreatedBy] = useState<NullableString>("");
  const [tagNumber, setTagNumber] = useState<NullableString>("");
  const [tagNumberInput, setTagNumberInput] = useState<NullableString>("");
  const [disciplineTagNumber, setDisciplineTagNumber] =
    useState<NullableString>("");
  const [serviceDescription, setServiceDescription] =
    useState<NullableString>("");
  const [suffix, setSuffix] = useState<NullableString>("");
  const [remarks, setRemarks] = useState<NullableString>("");
  const [multipleTags, setMultipleTags] = useState<NullableString>("");
  const [tagLimitOption, setTagLimitOption] = useState<SelectOption | null>(null);
  const [statusOption, setStatusOption] = useState<SelectOption | null>(null);
  const [primaryTagOption, setPrimaryTagOption] = useState<SelectOption | null>(
    null
  );
  const [tagCategoryOption, setTagCategoryOption] =
    useState<SelectOption | null>(null);
  const [projectOption, setProjectsOption] = useState<SelectOption | null>(
    null
  );
  const [facilityOption, setFacilityOption] = useState<SelectOption | null>(
    null
  );
  const [areaOption, setAreaOption] = useState<SelectOption | null>(null);
  const [unitOption, setUnitOption] = useState<SelectOption | null>(null);
  const [equipmentTypeOption, setEquipmentTypeOption] =
    useState<SelectOption | null>(null);
  const [buildingOption, setBuildingOption] = useState<SelectOption | null>(
    null
  );
  const [wellOption, setWellOption] = useState<SelectOption | null>(null);
  const [parentTagOption, setParentTagOption] = useState<SelectOption | null>(
    null
  );
  const [insulationType, setInsulationType] = useState<NullableString>("");
  const [circuitNumber, setCircuitNumber] = useState<NullableString>("");
  const [fluidOption, setFluidOption] = useState<SelectOption | null>(null);
  const [fittingNumber, setFittingNumber] = useState<NullableString>("");
  const [pipeClassOption, setPipeClassOption] = useState<SelectOption | null>(
    null
  );
  const [nominalDiameter, setNominalDiameter] = useState<NullableString>("");
  const [sequenceNumber, setSequenceNumber] = useState<NullableString>("");
  const [requestNumber, setRequestNumber] = useState<NullableString>("");
  const [locationOption, setLocationOption] = useState<SelectOption | null>(
    null
  );
  const [substationOption, setSubstationOption] = useState<SelectOption | null>(
    null
  );
  const [documentsOptions, setDocumentsOption] = useState<SelectOption[]>([]);

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && isFormChanged && !isSaveWithoutBlocking
  );

  useEffect(() => {
    (async () => {
      await initTag();
      setValue(0);
      setIsFormChanged(false);
    })();
  }, [urlParams]);

  const initTag = async () => {
    setIsRefreshInProgress(true);

    const id = urlParams.get("id");
    const tag = await masterAreaCardViewStore.getMasterAssetById(parseInt(id!));

    setTagId(tag?.id);
    setCreatedOn(tag?.createdOn.toString().slice(0, 10));
    setCreatedBy(tag?.createdBy);
    setTagNumber(tag?.tagNumber);
    setTagNumberInput(tag?.tagNumber);
    setDisciplineTagNumber(tag?.disciplineTagNumber ?? "");
    setServiceDescription(tag?.serviceDescription ?? "");
    setSuffix(tag?.suffix ?? "");
    setRemarks(tag?.remarks ?? "");
    setSequenceNumber(tag?.sequenceNumber ?? "");
    setRequestNumber(tag?.requestNumber ?? "");
    setIsControlAutonaming(tag?.controlAutonaming ?? false);

    setStatusOption({ id: tag?.equipmentStatusId!,  label: processString(tag?.equipmentStatus) });
    setTagCategoryOption({ id: tag?.tagCategoryId!,  label: processString(tag?.tagCategoryName), code: processString(tag?.tagCategoryCode) });
    setEquipmentTypeOption({ id: tag?.equipmentTypeId!,  label: processString(tag?.equipmentType) });
    setFacilityOption({ id: tag?.facilityId!,  label: buildStringOption(tag?.facilityCode, tag?.facilityDescription) });
    setAreaOption({ id: tag?.areaId!,  label: buildStringOption(tag?.areaCode, tag?.areaDescription) });
    setUnitOption({ id: tag?.unitId!,  label: buildStringOption(tag?.unitCode, tag?.unitDescription) });
    setPrimaryTagOption({ id: tag?.primaryTagNumberId!,  label: processString(tag?.primaryTagNumber) });
    setProjectsOption({ id: tag?.projectId!,  label: processString(tag?.projectNumber) });
    setBuildingOption({ id: tag?.buildingId!,  label: buildStringOption(tag?.buildingNumber, tag?.buildingDescription) });
    setWellOption({ id: tag?.wellId!,  label: processString(tag?.wellCode) });
    setParentTagOption({ id: tag?.parentTagNumberId!,  label: processString(tag?.parentTagNumber) });
    setPipeClassOption({ id: tag?.pipeClassId!,  label: processString(tag?.pipeClassCode) });
    setFluidOption({ id: tag?.fuildId!,  label: processString(tag?.fluidCode) });     
    setLocationOption({ id: tag?.locationId!,  label: tag?.locationId ? processString(tag?.locationCode + " - " + tag?.locationDescription) : "" });
    setSubstationOption({ id: tag?.substationId!,  label: tag?.substationId ? processString(tag?.substationCode) : "" });
    
    setInsulationType(tag?.insulationType ?? "");
    setNominalDiameter(tag?.nominalDiameter ?? "");
    setCircuitNumber(tag?.circuitNumber ?? "");
    setFittingNumber(tag?.fittingNumber ?? "");

    masterAreaCardViewStore.setMasterHistory(tag?.changeHistories!);
    masterAreaCardViewStore.setMasterAttributes(tag?.attributes!);
    masterAreaCardViewStore.setMasterDocuments(tag?.documents!);
    masterAreaCardViewStore.setMasterRelatedTags(tag?.relatedTags ?? []);
    masterAreaCardViewStore.setMasterRelatedChildTags(tag?.relatedTags?.filter(t => t.relationTypeId === 8) ?? []);

    const docOptions = masterDocumentsToSelectOptions(tag?.documents ?? []);
    setDocumentsOption(docOptions);

    setIsRefreshInProgress(false);

    dictionaryStore.fetchTagCategoryOptions("");
    dictionaryStore.fetchProjectOptions("");
    dictionaryStore.fetchAreaOptions("");
    dictionaryStore.fetchUnitOptions("");
    dictionaryStore.fetchBuildingOptions("");
    dictionaryStore.fetchWellOptions("");
    dictionaryStore.fetchParentTagNumberByCategoryOptions("");
    dictionaryStore.fetchFacilityOptions("");
    dictionaryStore.fetchMasterDocumentsByNumber("");
    dictionaryStore.fetchPrimaryTagNumberByCategoryOptions("", tag?.tagCategoryCode!);
    dictionaryStore.fetchPipeClassesOptions("");
    dictionaryStore.fetchFluidsOptions("");
    dictionaryStore.fetchLocationOptions("");
    dictionaryStore.fetchSubstationOptions("");
  };

  const debouncedHandleInputChange = useDebounce(500);

  const handlePrimaryTagChange = async (selectedOption: SelectOption) => {
    setPrimaryTagOption(selectedOption);
    if (selectedOption) {
      const primaryTag = dictionaryStore.primaryTagExtendedOptions.filter(
        (p) => p.id === selectedOption.id
      )[0];
      setSequenceNumber(primaryTag.sequence);
      
      if(tagCategoryOption?.label == "Junction Box") {
        const eqType = await dictionaryStore.fetchEqTypeById(primaryTag.tagTypeId);
        if (eqType) {
          setEquipmentTypeOption({id: primaryTag.tagTypeId, label: eqType});
        } 
      }
      const facility = await referencesStore.getFacilityById(
        primaryTag.facilityId
      );
      if (facility) {
        setFacilityOption({
          id: facility.id,
          label: facility.facilityCode + " - " + facility.facilityDescription,
        });
      }
      const area = await referencesStore.getAreaById(primaryTag.areaId);
      if (area) {
        setAreaOption({
          id: area.id,
          label: area.areaCode + " - " + area.areaDescription,
        });
      }
      const unit = await referencesStore.getUnitById(primaryTag.unitId);
      if (unit) {
        setUnitOption({
          id: unit.id,
          label: unit.unitShortCode + " - " + unit.unitDescription,
        });
      }
      const well = await referencesStore.getWellById(primaryTag.wellId);   
      if(well) {
        setWellOption({id: well.id, label: well.wellCode});
      } 
    }
  };

  const handleProjectInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchProjectOptions(inputValue);
    });
  };

  const handleFacilityInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchFacilityOptions(inputValue);
    });
  };

  const updateFacilityOption = async (selectedValue: SelectOption) => {
      setFacilityOption(null);
      setAreaOption(null);
      setUnitOption(null);
      setBuildingOption(null);
      setWellOption(null);
      setFacilityOption(selectedValue);
  };

  const handleAreaInputChange = async (
    inputValue: string,
    facilityId?: number,
    categoryId?: number
  ) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchAreaOptions(inputValue, facilityId, categoryId);
    });
  };

  const updateAreaOption = async (selectedValue: SelectOption) => {
    setAreaOption(null);
    setUnitOption(null);
    setBuildingOption(null);
    setWellOption(null);
    setAreaOption(selectedValue);
  };

  const handleUnitInputChange = async (inputValue: string, areaId?: number, categoryId?: number) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchUnitOptions(inputValue, areaId, categoryId);
    });
  };

  const updateUnitOption = async (selectedValue: SelectOption) => {
    if (!selectedValue) {
      setUnitOption(null);
      setBuildingOption(null);
      setWellOption(null);
    } else setUnitOption(selectedValue);
  };

  const handleEquipmentTypeAndPrimaryTagChange = async (
    inputValue: string,
    tagRequestCategoryId?: number,
    tagRequestCategoryCode?: string
  ) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchEqTypeOptions(inputValue, tagRequestCategoryId);
      dictionaryStore.fetchPrimaryTagNumberByCategoryOptions(
        inputValue,
        tagRequestCategoryCode
      );
    });
  };

  const handleEquipmentTypeInputChange = async (
    inputValue: string,
    tagRequestCategoryId?: number
  ) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchEqTypeOptions(inputValue, tagRequestCategoryId);
    });
  };

  const handleBuildingInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchBuildingOptions(inputValue);
    });
  };

  const handleWellInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchWellOptions(inputValue);
    });
  };

  const handleParentTagInputChange = async (
    inputValue: string,
    categoryId?: number
  ) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchParentTagNumberByCategoryOptions(
        inputValue,
        categoryId
      );
    });
  };

  const handleTagCategoryInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchTagCategoryOptions(inputValue);
    });
  };

  const updateTagCategoryOption = async (selectedValue: SelectOption) => {
    setTagCategoryOption(selectedValue);
    setTagNumberInput("");
    setPrimaryTagOption(null);
    setEquipmentTypeOption(null);
    setDisciplineTagNumber("");
    setServiceDescription("");
    setSuffix("");
    setRemarks("");
    setSequenceNumber("");
    setRequestNumber("");
    setFacilityOption(null);
    setAreaOption(null);
    setUnitOption(null);
    setProjectsOption(null);
    setBuildingOption(null);
    setWellOption(null);
    setParentTagOption(null);
    setPipeClassOption(null);
    setFluidOption(null);
    setLocationOption(null);
    setSubstationOption(null);
    setInsulationType("");
    setNominalDiameter("");
    setCircuitNumber("");
    setFittingNumber("");
    setMultipleTags("");
    setTagLimitOption(null);
  };

  const handlePrimaryTagInputChange = async (
    inputValue: string,
    categoryCode?: string
  ) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchPrimaryTagNumberByCategoryOptions(
        inputValue,
        categoryCode
      );
    });
  };

  const handleStatusInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchEquipmentStatusOptions(inputValue);
    });
  };

  const handlePipeClassInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchPipeClassesOptions(inputValue);
    });
  };

  const handleFluidInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchFluidsOptions(inputValue);
    });
  };

  const handleLocationInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchLocationOptions(inputValue);
    });
  };

  const handleSubstationInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchSubstationOptions(inputValue);
    });
  };

  const handleDocumentsChange = (selectedValue: SelectOption[]) => {
    const masterDocuments = selectOptionToMasterDocument(selectedValue ?? [], masterAreaCardViewStore.masterDocuments ?? []);
    masterAreaCardViewStore.setMasterDocuments(masterDocuments);
    setDocumentsOption(selectedValue);
  };

  const handleParentTagChange  = (masterRelatedTags: MasterAssetDetailRelatedTags[]) => {
    const parentTags = masterRelatedTags.filter(tag => tag.relationTypeId === 2);
    if (parentTags.length > 0) {
      const parentTag = parentTags[0];
      setParentTagOption({id: parentTag.id, label: parentTag.tagNumber!});
    }
  };

  const handleGeneralDocumentsChange  = (masterDocuments: MasterDocuments[]) => {
    const options = masterDocumentsToSelectOptions(masterDocuments ?? []);
    setDocumentsOption(options);
  };
  
  const handleDocumentsInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchMasterDocumentsByNumber(inputValue);
    });
  };

  const handleFittingNumberChange = async (inputValue: string) => {
    if(inputValue.length > 3) inputValue = inputValue.substring(0, 3);
    setFittingNumber(inputValue);
    validateForm();
  };

  const handleCloseClick = () => {
    if (isFormChanged) {
      blocker?.reset?.();
    }
    navigate(ROUTES.masterAreaGrid);
  };

  const buildDuplicateBBTag = (): CheckDuplicateBBTag => {
    const duplicateBBTag: CheckDuplicateBBTag = {
      masterEquipmentId: tagId,
      categoryId: tagCategoryOption?.id,
      categoryCode: tagCategoryOption?.code,
      tagTypeId: equipmentTypeOption?.id,
      facilityId: facilityOption?.id,
      areaId: areaOption?.id,
      unitId: unitOption?.id,
      buildingId: buildingOption?.id,
      wellId: wellOption?.id,
      suffix: suffix,
      multipleTags: parseInt(multipleTags!),
      tagLimit: tagLimitOption?.id,
      sequenceNumber: sequenceNumber,
      locationId: locationOption?.id,
      disciplineTagNumber: disciplineTagNumber,
      controlAutonaming: isControlAutonaming,
      primaryTagId: primaryTagOption?.id,
      nominalDiameter: nominalDiameter,
      pipeClassId: pipeClassOption?.id,
      insulationType: insulationType,
      circuitNumber: circuitNumber,
      fittingNumber: fittingNumber,
      fluidId: fluidOption?.id,
      substationId: substationOption?.id
    };

    return duplicateBBTag;
  };

  const confirmSave = async () => {
    const duplicateBBTag = buildDuplicateBBTag();
    const isDuplicate = await checkDuplicateBBTag(
      masterAssetsStore,
      duplicateBBTag
    );

    if (isDuplicate) {
      masterAreaCardViewStore.setShowDuplicateWarningModal(true);
    } else handleSaveClick();
  };

  const confirmSaveAndClose = async () => {
    setIsSaveWithoutBlocking(true);
    if (!isFormChanged) {
      navigate(ROUTES.masterAreaGrid);
    } else {
      const duplicateBBTag = buildDuplicateBBTag();
      const isDuplicate = await checkDuplicateBBTag(
        masterAssetsStore,
        duplicateBBTag
      );

      if (isDuplicate) {
        masterAreaCardViewStore.setShowDuplicateWarningModal(true);
      } else handleSaveAndCloseClick();
    }
  };

  const buildBBTag = (): EditBBTagRequest => {
    const documentsIds: number[] = [];
    for (const document of documentsOptions)
      documentsIds.push(document.id);

    const editRelatedTags: EditRelatedTags[] = [];
    for (const relatedTag of masterAreaCardViewStore.masterRelatedTags)
      editRelatedTags.push({
        id: relatedTag.relatedTagId ? relatedTag.relatedTagId : relatedTag.id,
        relationType: relatedTag.relationTypeId!,
      });

    let editAttributes: EditTagAttributes[] = [];
    const seenTagAttributeIds = new Set<number>();

  for (const attribute of masterAreaCardViewStore.masterTagAttributes) {
    if (!seenTagAttributeIds.has(attribute?.tagAttributeId!)) {
      seenTagAttributeIds.add(attribute?.tagAttributeId!);
      editAttributes.push({
        id: attribute.category === "new" ? null : attribute.id,
        tagAttributeId: attribute.tagAttributeId,
        tagAttributeValue: attribute.tagAttributeValue,
        uomId: attribute.uomId,
      });
    }
  }

    const editTag: EditBBTagRequest = {
      id: tagId!,
      categoryId: tagCategoryOption?.id,
      categoryCode: tagCategoryOption?.code,
      tagTypeId: equipmentTypeOption?.id,
      facilityId: facilityOption?.id,
      areaId: areaOption?.id,
      unitId: unitOption?.id,
      buildingId: buildingOption?.id,
      wellId: wellOption?.id,
      locationId: locationOption?.id,
      fluidId: fluidOption?.id,
      documentId: null,
      projectId: projectOption?.id,
      serviceDescription: serviceDescription ? serviceDescription : null,
      remarks: remarks ? remarks : null,
      parentTagId: parentTagOption?.id,
      primaryTagId: primaryTagOption?.id,
      suffix: suffix ? suffix : null,
      sequenceNumber: sequenceNumber ? sequenceNumber : null,
      controlAutonaming: isControlAutonaming,
      disciplineTagNumber: disciplineTagNumber ? disciplineTagNumber : null,
      nominalDiameter: nominalDiameter ? nominalDiameter : null,
      pipeClassId: pipeClassOption?.id,
      insulationType: insulationType ? insulationType : null,
      circuitNumber: circuitNumber ? circuitNumber : null,
      fittingNumber: fittingNumber ? fittingNumber : null,
      requestNumber: requestNumber ? requestNumber : null,
      documentsIds: documentsIds,
      majorEquipmentTagMapId: parentTagOption?.id,
      equipmentStatusId: statusOption?.id,
      relatedTags: editRelatedTags,
      tagAttributes: editAttributes,
      substationId: substationOption?.id,
      multipleTags: parseInt(multipleTags!),
      tagLimit: tagLimitOption?.id,
    };


    return editTag;
  };

  const updateParentTag = (selectedValue: SelectOption) => {
    setParentTagOption(selectedValue);
    if (selectedValue?.id) {
      const parentTag = masterAreaCardViewStore.masterRelatedTags.filter(
        (pTag) => pTag.relationType?.toLowerCase() === "parent"
      )[0];
      if (parentTag) {
        parentTag.id = selectedValue.id;
        parentTag.tagNumber = selectedValue?.label;
      } else {
        masterAreaCardViewStore.masterRelatedTags.push({
          id: selectedValue.id,
          relatedTagId: selectedValue.id,
          relationTypeId: 2,
          relationType: "Parent",
          tagNumber: selectedValue?.label,
          isControlAutonaming: null,
          category: null,
          disciplineTagNumber: null,
          status: null,
          createdBy: userStore.currentUserAccount?.name,
          createdOn: new Date(),
        });
      }
    }
  };

  const updateServiceDescription = async (inputValue: string) => {
    const serviceDescriptionAttrName = "Service Description";
    setServiceDescription(inputValue);
    if (inputValue) {
      const attrTag = masterAreaCardViewStore.masterTagAttributes.filter((aTag) => aTag.attributeClass?.toLowerCase() === serviceDescriptionAttrName.toLowerCase())[0];
      if (attrTag) {
        attrTag.tagAttributeValue = inputValue;
      }
      else {
        await dictionaryStore.fetchEclTagAttributesOptions(serviceDescriptionAttrName);
        const serviceDescriptionAttr = dictionaryStore.eclTagAttributesOptions[0];
        masterAreaCardViewStore.masterTagAttributes.push({
          id: masterAreaTagAttributesStore.idCounter++,
          subClassName: null,
          tagAttributeId: serviceDescriptionAttr?.id,
          tagAttributeValue: inputValue,
          uom: null,
          uomId: null,
          category: serviceDescriptionAttrName,
          attributeClass: serviceDescriptionAttrName,
          createdOn: new Date(),
          createdBy: userStore.currentUserAccount?.name,
          modifiedOn: new Date(),
          modifiedBy: userStore.currentUserAccount?.name
        });
      }
    }
  };

  const updateRequestNumber = async (inputValue: string) => {
    const requestNumberAttrName = "Request Number";
    setRequestNumber(inputValue);
    if (inputValue) {
      const attrTag = masterAreaCardViewStore.masterTagAttributes.filter((aTag) => aTag.attributeClass?.toLowerCase() === requestNumberAttrName.toLowerCase())[0];
      if (attrTag) {
        attrTag.tagAttributeValue = inputValue;
      } else {
        await dictionaryStore.fetchEclTagAttributesOptions(requestNumberAttrName);
        const requestNumberAttr = dictionaryStore.eclTagAttributesOptions[0];
        masterAreaCardViewStore.masterTagAttributes.push({
          id: masterAreaTagAttributesStore.idCounter++,
          subClassName: null,
          tagAttributeId: requestNumberAttr?.id,
          tagAttributeValue: inputValue,
          uom: null,
          uomId: null,
          category: requestNumberAttrName,
          attributeClass: requestNumberAttrName,
          createdOn: new Date(),
          createdBy: userStore.currentUserAccount?.name,
          modifiedOn: new Date(),
          modifiedBy: userStore.currentUserAccount?.name
        });
      }
    }
};

  const updateRemarks = async (inputValue: string) => {
    const remarksAttrName = "Remarks";
    setRemarks(inputValue);
    if (inputValue) {
      const attrTag = masterAreaCardViewStore.masterTagAttributes.filter((aTag) => aTag.attributeClass?.toLowerCase() === remarksAttrName.toLowerCase())[0];
      if (attrTag) {
        attrTag.tagAttributeValue = inputValue;
      }
      else {
        await dictionaryStore.fetchEclTagAttributesOptions(remarksAttrName);
        const remarksAttr = dictionaryStore.eclTagAttributesOptions[1];
        masterAreaCardViewStore.masterTagAttributes.push({
          id: masterAreaTagAttributesStore.idCounter++,
          subClassName: null,
          tagAttributeId: remarksAttr?.id,
          tagAttributeValue: inputValue,
          uom: null,
          uomId: null,
          category: remarksAttrName,
          attributeClass: remarksAttrName,
          createdOn: new Date(),
          createdBy: userStore.currentUserAccount?.name,
          modifiedOn: new Date(),
          modifiedBy: userStore.currentUserAccount?.name
        });
      }
    }
  };

  const updateSequenceCounter = () => {
    const uCounter: UpdateCounterRequest = {
      masterEquipmentId: tagId,
      categoryCode: tagCategoryOption?.code,
      categoryId: tagCategoryOption?.id,
      sequenceNumber: sequenceNumber,
      areaId: areaOption?.id,
      unitId: unitOption?.id,
      tagTypeId: equipmentTypeOption?.id,
      buildingId: buildingOption?.id,
      locationId: locationOption?.id,
    };
    setIsRefreshInProgress(true);
    updateCounter(masterAssetsStore, notificationStore, uCounter);
    setIsRefreshInProgress(false);
  };

  const handleSaveClick = async () => {
    if (sequenceNumber) {
      await masterAssetsStore.validateSequenceNumber({
        sequenceNumber: sequenceNumber,
        categoryCode: tagCategoryOption?.code,
        primaryTagId: primaryTagOption?.id,
      });
      if (
        !masterAssetsStore.validateSequenceNumberMessage.isValid &&
        isControlAutonaming
      ) {
        masterAreaCardViewStore.setShowValidateSequenceNumberModal(true);
      }
    }
    if (!masterAreaCardViewStore.showValidateSequenceNumberModal) {
      const editTag = buildBBTag();
      await editBBTagNoClose(masterAssetsStore, notificationStore, editTag);

      initTag();
      setIsFormChanged(false);
    }
  };

  const handleSaveAndCloseClick = async () => {
    if (sequenceNumber) {
      await masterAssetsStore.validateSequenceNumber({
        sequenceNumber: sequenceNumber,
        categoryCode: tagCategoryOption?.code,
        primaryTagId: primaryTagOption?.id,
      });
      if (
        !masterAssetsStore.validateSequenceNumberMessage.isValid &&
        isControlAutonaming
      ) {
        masterAreaCardViewStore.setShowValidateSequenceNumberModal(true);
      }
    }
    if (!masterAreaCardViewStore.showValidateSequenceNumberModal) {
      const editTag = buildBBTag();
      editBBTag(masterAssetsStore, notificationStore, editTag);

      navigate(ROUTES.masterAreaGrid);
    }
  };

  const handleSaveOnLeaveClick = async () => {
    if (isFormValid) {
      const editTag = buildBBTag();
      editBBTag(masterAssetsStore, notificationStore, editTag);

      blocker?.proceed?.();
    } else {
      blocker?.reset?.();
    }
  };

  const handleLeaveWithoutSavingClick = async () => {
    blocker?.proceed?.();
  };

  const validateForm = () => {
    const areSimpleFieldsValid =
      validateField(tagCategoryOption?.label) &&
      validateField(equipmentTypeOption?.label) &&
      validateField(facilityOption?.label) &&
      validateField(areaOption?.label) &&
      validateField(unitOption?.label);
    const isCircuitNumberValid = isCircuitNumberRequired(
      tagCategoryOption?.code
    )
      ? validateFieldWithControlAutonaming(circuitNumber, isControlAutonaming)
      : true;
    const isFittingNumberValid = isFittingNumberRequired(
      tagCategoryOption?.code
    )
      ? validateFieldWithControlAutonaming(fittingNumber, isControlAutonaming)
      : true;
    const isFluidValid = isFluidRequired(tagCategoryOption?.code)
      ? validateFieldWithControlAutonaming(
          fluidOption?.label,
          isControlAutonaming
        )
      : true;
    const isLocationValid = isLocationRequired(tagCategoryOption?.code)
      ? validateFieldWithControlAutonaming(
          locationOption?.label,
          isControlAutonaming
        )
      : true;
    const isDisciplineTagNumberValid = validateDisciplineTagNumber(
      isControlAutonaming,
      disciplineTagNumber
    );
    const isBuildingValid = isBuildingRequired(tagCategoryOption?.code)
      ? validateBuilding(
          buildingOption?.label,
          tagCategoryOption?.code,
          isControlAutonaming
        )
      : true;
    const isPrimaryTagValidValid = isPrimaryTagRequired(tagCategoryOption?.code)
      ? validatePrimaryTag(
          primaryTagOption?.label,
          tagCategoryOption?.code,
          isControlAutonaming
        )
      : true;
    const isSequenceNumberValid = validateSequenceNumber(
      isControlAutonaming,
      sequenceNumber,
      tagCategoryOption?.code
    );

    const isValid =
      areSimpleFieldsValid &&
      isCircuitNumberValid &&
      isFittingNumberValid &&
      isFluidValid &&
      isDisciplineTagNumberValid &&
      isLocationValid &&
      isBuildingValid &&
      isPrimaryTagValidValid &&
      isSequenceNumberValid;
    setIsFormValid(isValid);
  };

  return tagNumber ? (
    <form onChange={() => setIsFormChanged(true)}>
      <Box sx={areaboxStyle}>
        
      {/** Author: Kanat Bektursyn
       *
       *  Layout of this page was changed, due to owners request
       *   ________
       *  | Note:
       *  |   The new layout is closer related to ARS v1
       *  |________
       *
       */}
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "#fff",
              display: { xs: "block", md: "flex", width: "100%" },
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ flex: 1, display: 'flex', justifyContent: "start" }}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h6" gutterBottom>
                  {tagNumber}
                </Typography>
                <Typography variant="caption">
                  {tagCategoryOption?.label}
                </Typography>
              </Box>

              
              {/** Author: Kanat Bektursyn
               *
               *  @param isFormChanged
               *
               *  This param is essentally a flag, that denominates,
               *  whether Form is changed, and if so, have the changes
               *  been saved. Flag *isFormChanged is more than enough
               *   ________
               *  | Note:
               *  |   If the *isFormChanged set to @boolean true, then
               *  |   data was never saved, i.e. @param Unsaved
               *  |   Contrary, if *isFormChanged set to @boolean false,
               *  |   data was either saved, or there is nothing to save,
               *  |   i.e. @param Saved
               *  |________
               *
               */}
              <Box sx={{ alignSelf: { md: "center" } }}>
                {isFormChanged ? (
                  <Chip
                    sx={{ ml: 2 }}
                    label="Unsaved"
                    color="info"
                    variant="outlined"
                  />
                ) : (
                  <Chip
                    sx={{ ml: 2 }}
                    label="Saved"
                    color="primary"
                    variant="outlined"
                  />
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "block", md: "flex" },
                justifyContent: "flex-end",
                flex: 1,
              }}
            >
              <div
                style={{
                  fontSize: "12px",
                  margin: "0 10px 0 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                Created On:&nbsp;<b>{createdOn}</b>, Created By:&nbsp;
                <b>{createdBy}</b>, Status:&nbsp;
                <Autocomplete
                  disablePortal
                  size="small"
                  id="status"
                  options={dictionaryStore.equipmentStatusOptions}
                  value={statusOption}
                  getOptionLabel={(option) => option.label}
                  sx={{
                    mr: 1,
                    mb: 2,
                    width: "30%",
                    margin: "0px",
                    "& .MuiInputBase-input": { height: "15px" },
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Status" />
                  )}
                  onInputChange={(event, newInputValue) => {
                    handleStatusInputChange(newInputValue);
                  }}
                  onChange={(event, selectedValue) => {
                    setIsFormChanged(true);
                    setStatusOption(selectedValue);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              </div>
            </Box>
          </Box>
        </Stack>

        <Divider aria-hidden="true" sx={{ height: "1rem", border: "none" }} />
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "#fff",
              display: { xs: "block", md: "flex", width: "50%" },
            }}
          >
            <Button variant="outlined" onClick={handleCloseClick}>
              <ArrowBackOutlinedIcon />
            </Button>
            <Button
              variant="contained"
              disabled={!isFormValid}
              sx={{ ml: 2 }}
              onClick={() => confirmSave()}
            >
              <span>Save</span>
            </Button>
            <Button
              variant="contained"
              disabled={!isFormValid}
              sx={{ ml: 2 }}
              onClick={() => confirmSaveAndClose()}
            >
              <span>Save & Close</span>
            </Button>
            <Button
              variant="contained"
              size="small"
              startIcon={<RefreshIcon />}
              sx={{ ml: 2 }}
              onClick={() => initTag()}
            >
              Refresh
            </Button>
            <Button
              variant="contained"
              disabled={
                !masterAssetsStore.validateSequenceNumberMessage.isValid ||
                !isControlAutonaming
              }
              sx={{ ml: 2 }}
              onClick={() => updateSequenceCounter()}
            >
              <span>Update Counter</span>
            </Button>
          </Box>
        </Stack>
      </Box>
      {isRefreshInProgress ? (
        <div
          style={{
            margin: "0 10px 0 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <div style={areaboxStyleFlex}>
            <Tabs
              sx={{ width: "100%" }}
              value={tabValue}
              variant="scrollable"
              aria-label="New-Tag-Tabs"
              onChange={handleTabChange}
            >
              <Tab label="General" {...a11yProps(0)} />
              <Tab label="Child Equipment Tags" {...a11yProps(1)} />
              <Tab label="Related Tags" {...a11yProps(2)} />
              <Tab label="Attributes" {...a11yProps(3)} />
              <Tab label="Documents" {...a11yProps(4)} />
              <Tab label="History" {...a11yProps(5)} />
            </Tabs>
          </div>
          <Box sx={{ "overflow-y": "scroll", height: "70vh" }}>
            <CustomTabPanel value={tabValue} index={0}>
              <Box sx={areaboxStyle}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0 0 0 10px",
                  }}
                >
                  <Typography
                    id="add-tag-title"
                    variant="h6"
                    component="h2"
                    sx={{ mb: 2 }}
                  >
                    Edit Tag
                  </Typography>
                </div>
                <Stack direction="row">
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Tag Number"
                    value={tagNumberInput + ""}
                    sx={{
                      mr: 1,
                      mb: 2,
                      width: "50%",
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    disabled
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    value={sequenceNumber ? sequenceNumber + "" : ""}
                    label="Sequence Number"
                    sx={{
                      mr: 1,
                      mb: 2,
                      width: "50%",
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                      "background-color": sequenceNumberColor(
                        isControlAutonaming,
                        sequenceNumber,
                        tagCategoryOption?.code
                      ),
                    }}
                    onChange={(e) => setSequenceNumber(e.target.value)}
                    disabled={
                      primaryTagOption?.id !== null && isControlAutonaming
                    }
                  />
                </Stack>
                <Stack direction="row">
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Discipline Tag Number"
                    value={disciplineTagNumber}
                    sx={{
                      mr: 1,
                      mb: 2,
                      width: "50%",
                      "background-color": disciplineTagNumberColor(
                        isControlAutonaming,
                        disciplineTagNumber
                      ),
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    onChange={(e) => setDisciplineTagNumber(e.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Suffix"
                    value={suffix}
                    sx={{ mr: 1, mb: 2, width: "50%" }}
                    onChange={(e) => setSuffix(e.target.value)}
                  />
                </Stack>
                <Stack direction="row">
                  {isPrimaryTagRequired(tagCategoryOption?.code) ? (
                    <Autocomplete
                      disablePortal
                      size="small"
                      id="primaryTag"
                      options={dictionaryStore.primaryTagOptions}
                      value={primaryTagOption}
                      getOptionLabel={(option) => option.label}
                      sx={{
                        mr: 1,
                        mb: 2,
                        width: "50%",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={getPrimaryTagLabel(tagCategoryOption?.code)}
                          sx={{
                            "background-color": validationPrimaryTagColor(
                              primaryTagOption?.label,
                              tagCategoryOption?.code,
                              isControlAutonaming
                            )
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment:
                              primaryTagOption?.id !== null ? (
                                <a
                                  href="javascript:void(0);"
                                  rel="noreferrer"
                                  onClick={(event) => {
                                    navigate(
                                      ROUTES.masterAreaEditTag +
                                        "?id=" +
                                        primaryTagOption?.id,
                                      { replace: true }
                                    );
                                  }}
                                >
                                  View
                                </a>
                              ) : null,
                          }}
                        />
                      )}
                      onInputChange={(event, newInputValue) => {
                        handlePrimaryTagInputChange(
                          newInputValue,
                          tagCategoryOption?.code
                        );
                      }}
                      onChange={(event, selectedValue) => {
                        setIsFormChanged(true);
                        handlePrimaryTagChange(selectedValue);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                    />
                  ) : (
                    <div style={{ width: "51%" }}></div>
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={isControlAutonaming}
                        onChange={(e) =>
                          setIsControlAutonaming(e.target.checked)
                        }
                      />
                    }
                    label="Control Autonaming"
                    sx={{ width: "50%"}}
                  />
                </Stack>
                <Stack direction="row">
        {          
          isControlAutonaming ? 
          <TextField
            variant="outlined"
            size="small"
            label="Generate Multiple Tags"
            type="number"
            value={multipleTags}
            sx={{ mr: 1, mb: 2, width: "50%"}}
            onChange={(e) => setMultipleTags(e.target.value)}
          /> : null 
        }
        {          
          isControlAutonaming && multipleTags && !hideTagLimit(tagCategoryOption?.code) ? 
            <Autocomplete
              disablePortal
              size="small"
              id="tagLimit"
              options={TagLimitOptions}
              value={tagLimitOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%"}}
              renderInput={(params) => <TextField {...params} label="Tag Limit" />}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, selectedValue) => {
                setIsFormChanged(true);
                setTagLimitOption(selectedValue);
              }}
            />  : null
          }
        </Stack>
              </Box>
              <Box sx={areaboxStyle}>
                <Typography
                  id="classification-title"
                  variant="h6"
                  component="h2"
                  sx={{ mb: 2 }}
                >
                  Classification
                </Typography>
                <Stack direction="row">
                  <Autocomplete
                    disablePortal
                    size="small"
                    id="tagCategory"
                    options={dictionaryStore.tagCategoryOptions}
                    value={tagCategoryOption}
                    getOptionLabel={(option) => option.label}
                    sx={{
                      mr: 1,
                      mb: 2,
                      width: "50%",
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tag Category"
                        sx={{
                          "background-color": validationColor(
                            tagCategoryOption?.label
                          ),
                        }}
                      />
                    )}
                    onInputChange={(event, newInputValue) => {
                      handleTagCategoryInputChange(newInputValue);
                    }}
                    onChange={(event, selectedValue) => {
                      setIsFormChanged(true);
                      updateTagCategoryOption(selectedValue);
                      handleEquipmentTypeAndPrimaryTagChange(
                        "",
                        selectedValue?.id,
                        selectedValue?.code
                      );
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                  {isInsulationTypeRequired(tagCategoryOption?.code) ? (
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Insulation Type"
                      value={insulationType}
                      sx={{
                        mr: 1,
                        mb: 2,
                        width: "50%",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                      }}
                      onChange={(e) => setInsulationType(e.target.value)}
                    />
                  ) : null}
                  {isCircuitNumberRequired(tagCategoryOption?.code) ? (
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Circuit Number"
                      value={circuitNumber}
                      sx={{
                        mr: 1,
                        mb: 2,
                        width: "50%",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                        "background-color":
                          validationColorWithControlAutonaming(
                            circuitNumber,
                            isControlAutonaming
                          ),
                      }}
                      onChange={(e) => setCircuitNumber(e.target.value)}
                    />
                  ) : null}
                </Stack>
                <Stack direction="row">
                  <Autocomplete
                    disablePortal
                    size="small"
                    id="eqiupmentType"
                    options={dictionaryStore.eqTypeOptions}
                    value={equipmentTypeOption}
                    getOptionLabel={(option) => option.label}
                    sx={{
                      mr: 1,
                      mb: 2,
                      width: "50%",
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Type"
                        sx={{
                          "background-color": validationColor(
                            equipmentTypeOption?.label
                          ),
                        }}
                      />
                    )}
                    onInputChange={(event, newInputValue) => {
                      handleEquipmentTypeInputChange(
                        newInputValue,
                        tagCategoryOption?.id
                      );
                    }}
                    onChange={(event, selectedValue) => {
                      setIsFormChanged(true);
                      setEquipmentTypeOption(selectedValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                  {isFittingNumberRequired(tagCategoryOption?.code) ? (
                    <TextField
                      variant="outlined"
                      size="small"
                      type="number"
                      label="Fitting Number"
                      value={fittingNumber}
                      sx={{
                        mr: 1,
                        mb: 2,
                        width: "50%",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                        "background-color":
                          validationColorWithControlAutonaming(
                            fittingNumber,
                            isControlAutonaming
                          ),
                      }}
                      inputProps={{ maxLength: 3 }}
                      onChange={(e) => handleFittingNumberChange(e.target.value)}
                    />
                  ) : null}
                  {isNominalDiameterRequired(tagCategoryOption?.code) ? (
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Nominal Diameter"
                      value={nominalDiameter}
                      sx={{
                        mr: 1,
                        mb: 2,
                        width: "50%",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                      }}
                      onChange={(e) => setNominalDiameter(e.target.value)}
                    />
                  ) : null}
                </Stack>
                <Stack direction="row">
                  {isFluidRequired(tagCategoryOption?.code) ? (
                    <Autocomplete
                      disablePortal
                      size="small"
                      id="fluid"
                      options={dictionaryStore.fluidOptions}
                      value={fluidOption}
                      getOptionLabel={(option) => option.label}
                      sx={{ mr: 1, mb: 2, width: "50%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Fluid"
                          sx={{
                            "background-color":
                              validationColorWithControlAutonaming(
                                fluidOption?.label,
                                isControlAutonaming
                              ),
                          }}
                        />
                      )}
                      onInputChange={(event, newInputValue) => {
                        handleFluidInputChange(newInputValue);
                      }}
                      onChange={(event, selectedValue) => {
                        setIsFormChanged(true);
                        setFluidOption(selectedValue);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                    />
                  ) : null}
                </Stack>
                <Stack direction="row">
                  {isPipeClassRequired(tagCategoryOption?.code) ? (
                    <Autocomplete
                      disablePortal
                      size="small"
                      id="pipeClass"
                      options={dictionaryStore.pipeClassesOptions}
                      value={pipeClassOption}
                      getOptionLabel={(option) => option.label}
                      sx={{ mr: 1, mb: 2, width: "50%" }}
                      renderInput={(params) => (
                        <TextField {...params} label="Pipe Class" />
                      )}
                      onInputChange={(event, newInputValue) => {
                        handlePipeClassInputChange(newInputValue);
                      }}
                      onChange={(event, selectedValue) => {
                        setIsFormChanged(true);
                        setPipeClassOption(selectedValue);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                    />
                  ) : null}
                </Stack>
              </Box>
              <Box sx={areaboxStyle}>
                <Typography
                  id="classification-title"
                  variant="h6"
                  component="h2"
                  sx={{ mb: 2 }}
                >
                  Location
                </Typography>
                <Stack direction="row">
                  <Autocomplete
                    disablePortal
                    size="small"
                    id="facility"
                    options={dictionaryStore.facilityOptions}
                    value={facilityOption}
                    getOptionLabel={(option) => option.label}
                    sx={{
                      mr: 1,
                      mb: 2,
                      width: "50%",
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Facility"
                        sx={{
                          "background-color": validationColor(
                            facilityOption?.label
                          ),
                        }}
                      />
                    )}
                    onInputChange={(event, newInputValue) => {
                      handleFacilityInputChange(newInputValue);
                    }}
                    onChange={(event, selectedValue) => {
                      setIsFormChanged(true);
                      updateFacilityOption(selectedValue);
                      handleAreaInputChange(
                        "",
                        selectedValue?.id,
                        tagCategoryOption?.id
                      );
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    disabled={primaryTagOption?.id != null}
                  />
                  <Autocomplete
                    disablePortal
                    size="small"
                    id="well"
                    options={dictionaryStore.wellOptions}
                    value={wellOption}
                    getOptionLabel={(option) => option.label}
                    sx={{
                      mr: 1,
                      mb: 2,
                      width: "50%",
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Well" />
                    )}
                    onInputChange={(event, newInputValue) => {
                      handleWellInputChange(newInputValue);
                    }}
                    onChange={(event, selectedValue) => {
                      setIsFormChanged(true);
                      setWellOption(selectedValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    disabled={primaryTagOption?.id != null}
                  />
                </Stack>
                <Stack direction="row">
                  <Autocomplete
                    disablePortal
                    size="small"
                    id="area"
                    options={dictionaryStore.areaOptions}
                    value={areaOption}
                    getOptionLabel={(option) => option.label}
                    sx={{
                      mr: 1,
                      mb: 2,
                      width: "50%",
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Area"
                        sx={{
                          "background-color": validationColor(
                            areaOption?.label
                          ),
                        }}
                      />
                    )} 
                    onInputChange={(event, newInputValue) => {
                      handleAreaInputChange(newInputValue, facilityOption?.id, tagCategoryOption?.id);
                    }}
                    onChange={(event, selectedValue) => {
                      setIsFormChanged(true);
                      updateAreaOption(selectedValue);
                      handleUnitInputChange("", selectedValue?.id, tagCategoryOption?.id);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    disabled={primaryTagOption?.id != null || !facilityOption}
                  />
                  {isBuildingRequired(tagCategoryOption?.code) ? (
                    <Autocomplete
                      disablePortal
                      size="small"
                      id="building"
                      options={dictionaryStore.buildingOptions}
                      value={buildingOption}
                      getOptionLabel={(option) => option.label}
                      sx={{
                        mr: 1,
                        mb: 2,
                        width: "50%",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Building"
                          sx={{
                            "background-color": buildingColor(
                              buildingOption?.label,
                              tagCategoryOption?.code,
                              isControlAutonaming
                            ),
                          }}
                        />
                      )}
                      onInputChange={(event, newInputValue) => {
                        handleBuildingInputChange(newInputValue);
                      }}
                      onChange={(event, selectedValue) => {
                        setIsFormChanged(true);
                        setBuildingOption(selectedValue);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                    />
                  ) : null}
                  {isLocationRequired(tagCategoryOption?.code) ? (
                    <Autocomplete
                      disablePortal
                      size="small"
                      id="location"
                      options={dictionaryStore.locationOptions}
                      value={locationOption}
                      getOptionLabel={(option) => option.label}
                      sx={{
                        mr: 1,
                        mb: 2,
                        width: "50%",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Location"
                          sx={{
                            "background-color":
                              validationColorWithControlAutonaming(
                                locationOption?.label,
                                isControlAutonaming
                              ),
                          }}
                        />
                      )}
                      onInputChange={(event, newInputValue) => {
                        handleLocationInputChange(newInputValue);
                      }}
                      onChange={(event, selectedValue) => {
                        setIsFormChanged(true);
                        setLocationOption(selectedValue);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                    />
                  ) : null}
                  {isSubstationRequired(tagCategoryOption?.code) ? (
                    <Autocomplete
                      disablePortal
                      size="small"
                      id="substation"
                      options={dictionaryStore.substationOptions}
                      value={substationOption}
                      getOptionLabel={(option) => option.label}
                      sx={{ mr: 1, mb: 2, width: "50%" }}
                      renderInput={(params) => (
                        <TextField {...params} label="Substation" />
                      )}
                      onInputChange={(event, newInputValue) => {
                        handleSubstationInputChange(newInputValue);
                      }}
                      onChange={(event, selectedValue) => {
                        setIsFormChanged(true);
                        setSubstationOption(selectedValue);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                    />
                  ) : null}
                </Stack>
                <Stack direction="row">
                  <Autocomplete
                    disablePortal
                    size="small"
                    id="unit"
                    options={dictionaryStore.unitOptions}
                    value={unitOption}
                    getOptionLabel={(option) => option.label}
                    sx={{
                      mr: 1,
                      mb: 2,
                      width: "50%",
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Unit"
                        sx={{
                          "background-color": validationColor(
                            unitOption?.label
                          ),
                        }}
                      />
                    )}
                    onInputChange={(event, newInputValue) => {
                      handleUnitInputChange(newInputValue, areaOption?.id, tagCategoryOption?.id);
                    }}
                    onChange={(event, selectedValue) => {
                      setIsFormChanged(true);
                      updateUnitOption(selectedValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    disabled={primaryTagOption?.id != null || !areaOption}
                  />
                </Stack>
              </Box>
              <Box sx={areaboxStyleNoMargin}>
                <Typography
                  id="classification-title"
                  variant="h6"
                  component="h2"
                  sx={{ mb: 2 }}
                >
                  Details
                </Typography>
                <Stack direction="row">
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Request Number"
                    value={requestNumber}
                    sx={{ mr: 1, mb: 2, width: "50%" }}
                    onChange={(e) => updateRequestNumber(e.target.value)}
                  />
                  <Autocomplete
                    disablePortal
                    size="small"
                    id="parentTag"
                    options={dictionaryStore.parentTagNumberByCategoryOptions}
                    value={parentTagOption}
                    getOptionLabel={(option) => option.label}
                    sx={{ mr: 1, mb: 2, width: "50%" }}
                    renderInput={(params) => (
                      <TextField {...params} label="Parent Tag" />
                    )}
                    onInputChange={(event, newInputValue) => {
                      handleParentTagInputChange(newInputValue);
                    }}
                    onChange={(event, selectedValue) => {
                      setIsFormChanged(true);
                      updateParentTag(selectedValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </Stack>
                <Stack direction="row">
                  <Autocomplete
                    disablePortal
                    size="small"
                    id="project"
                    options={dictionaryStore.projectOptions}
                    value={projectOption}
                    getOptionLabel={(option) => option.label}
                    sx={{ mr: 1, mb: 2, width: "50%" }}
                    renderInput={(params) => (
                      <TextField {...params} label="Project Number" />
                    )}
                    onInputChange={(event, newInputValue) => {
                      handleProjectInputChange(newInputValue);
                    }}
                    onChange={(event, selectedValue) => {
                      setIsFormChanged(true);
                      setProjectsOption(selectedValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </Stack>
                <Stack direction="row">
                  {isServiceDescriptionRequired(tagCategoryOption?.code) ? (
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Service Description"
                      value={serviceDescription}
                      sx={{ mr: 1, mb: 2, width: "50%" }}
                      onChange={(e) => updateServiceDescription(e.target.value)}
                    />
                  ) : null}
                </Stack>
                <Stack direction="row">
                <Autocomplete
                  size="small"
                  id="Documents"
                  options={dictionaryStore.masterTagDocumentsOptions}
                  value={documentsOptions}
                  getOptionLabel={(option) => option.label}
                  multiple
                  sx={{ mr: 1, mb: 2, width: "50%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Documents" />
                  )}
                  onInputChange={(event, newInputValue) => {
                    handleDocumentsInputChange(newInputValue);
                  }}
                  onChange={(event, selectedValue) => {
                    handleDocumentsChange(selectedValue);
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
                </Stack>
                <Stack direction="row">
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Remarks"
                    value={remarks}
                    multiline
                    rows={3}
                    sx={{ mr: 1, mb: 2, width: "98%" }}
                    onChange={(e) => updateRemarks(e.target.value)}
                  />
                </Stack>
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <MasterRelatedTagsTab
                Toolbar={true}
                setIsFormChanged={setIsFormChanged}
                displayChildsOnly={true}
                handleParentTagChange={handleParentTagChange}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={2}>
              <MasterRelatedTagsTab
                Toolbar={true}
                setIsFormChanged={setIsFormChanged}
                displayChildsOnly={false}
                handleParentTagChange={handleParentTagChange}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={3}>
              <MasterTagDetailAttributesTab
                Toolbar={true}
                setIsFormChanged={setIsFormChanged}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={4}>
              <MasterTagDetailDocumentsTab
                Toolbar={true}
                setIsFormChanged={setIsFormChanged}
                handleGeneralDocumentsChange={handleGeneralDocumentsChange}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={5}>
              <MasterTagDetailHistoryTab />
            </CustomTabPanel>
          </Box>
        </>
      )}
      {blocker ? (
        <ConfirmationDialogModal
          handleOkClick={handleSaveOnLeaveClick}
          handleCancelClick={handleLeaveWithoutSavingClick}
          showConfirmationDialog={blocker.state === "blocked"}
          headerText="Unsaved Changes"
          confirmationText="Do you want to save your changes before leaving this page?"
          okButtonText="Save and Continue"
          cancelButtonText="Discard Changes"
          blocker={blocker}
        />
      ) : null}
      <DuplicateTagWarningModal />
      <ValidateSequenceNumberWarningModal />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={masterAssetsStore.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </form>
  ) : null;
});

export default MasterAreaEditTag;
