import { Grid } from "@mui/material";
import { DataGridPro, GridColumnVisibilityModel } from "@mui/x-data-grid-pro";
import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import usePageTitle from "../../../hooks/usePageTitle";
import useMasterGridColumns from "./hooks/useMasterGridColumns";
import { DataGridBox } from "../../../styles/dataGridBox";

const MasterAreaProjectsPage = observer(() => {
  usePageTitle("Master Area - Projects");

  const columns = useMasterGridColumns();

  useEffect(() => {    
    masterAreaProjectsStore.fetchProjects();
  }, []);

  const { masterAreaProjectsStore } = useStores();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>();

  return (
    <>
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item xs={12} md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <DataGridBox>
            <DataGridPro
              //disableColumnFilter
              paginationMode="server"
              rows={masterAreaProjectsStore.projectsResponse.items ?? []}
              getRowId={(row) => row.projectId} 
              columns={columns}
              rowCount={masterAreaProjectsStore.projectsResponse.totalRecords}
              loading={masterAreaProjectsStore.isLoading}
              pagination
              paginationModel={masterAreaProjectsStore.paginationModel}
              pageSizeOptions={[masterAreaProjectsStore.paginationModel.pageSize]}
              onPaginationModelChange={masterAreaProjectsStore.setPaginationModel}
              density="compact"
              sx={{ fontSize: "0.75rem" }}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
              checkboxSelection
            />
          </DataGridBox>
        </Grid>
      </Grid>
    </>
  );
});

export default MasterAreaProjectsPage;
