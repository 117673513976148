import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import {
  Box,
  Button,
  TextField,
  Stack,
  Typography,
  Backdrop,
  CircularProgress
} from "@mui/material";
import { useState, useEffect } from "react";
import usePageTitle from "../../../hooks/usePageTitle";
import { areaboxStyle} from "../../../constants/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { NOTIFICATION_TYPES } from "../../../constants/notificationTypes";
import { NullableString } from "../../../models/common/response/types";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { UpdateBuildingRequest } from "../../../models/Dictionaries/request/updateBuildingRequest";
import { validationColor } from "../../../utils/validation";

const BuildingItem = observer(() => {
  usePageTitle("References - Building");

  const navigate = useNavigate();

  const [urlParams] = useSearchParams()

  const { referencesStore, notificationStore } = useStores();
  
  const [buildingId, setBuildingId] = useState<number>(0);
  const [building, setBuilding] = useState<NullableString>("");
  const [buildingNumber, setBuildingNumber] = useState<NullableString>("");
  const [buildingCode, setBuildingCode] = useState<NullableString>("");
  const [buildingDescription, setBuildingDescription] = useState<NullableString>("");
  const [buildingRemarks, setBuildingRemarks] = useState<NullableString>("");

 
  useEffect(() => {
    (async () => {   
      await initBuilding();
    })()
  }, []);

  const initBuilding = async () => {

    const idParam = parseInt(urlParams.get("id") ?? "0");
    await referencesStore.fetchBuildingById(idParam);

    setBuildingId(idParam);
    setBuilding(referencesStore.building?.building ?? "");
    setBuildingNumber(referencesStore.building?.buildingNumber ?? "");
    setBuildingCode(referencesStore.building?.buildingCode ?? "");
    setBuildingDescription(referencesStore.building?.buildingDescription ?? "");
    setBuildingRemarks(referencesStore.building?.buildingRemarks ?? "");
  }
  
  const handleCloseClick = () => {
    navigate(-1);
  };

  const handleSaveClick = async () => {
    try {
      const buildingItem: UpdateBuildingRequest = {
        id: buildingId,
        building: building,
        buildingNumber: buildingNumber,
        buildingCode: buildingCode,
        buildingDescription: buildingDescription,
        buildingRemarks: buildingRemarks
      }
      await referencesStore.updateBuilding(buildingItem);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Building edited successfully");
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error editing building: " + error);
    }
    finally {
      notificationStore.setShowNotification(true);
      navigate(ROUTES.referencesBuildings);
    }
  }

  return (
    referencesStore.building ?
    <form>
    <Box
          sx={ areaboxStyle }
        > 
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "#fff",
              display: { xs: "block", md: "flex", width: "50%" }
            }}
          >
            <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
              <ArrowBackOutlinedIcon />
            </Button>
            <Button 
              variant="contained" 
              sx={{ ml: 2 }} 
              disabled={!building || !buildingCode || !buildingNumber || !buildingDescription}
              onClick={() => handleSaveClick()}>
              <span>Save</span>
            </Button> 
          </Box>
        </Stack>
      </Box>
    {    
    <Box sx={{ "overflow-y": "scroll", height: "80vh" }}> 
      <Box
          sx={ areaboxStyle }
        >  
        <Typography
            id="add-tag-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
          Building
        </Typography>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Building"
            value={building}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(building) }}
            onChange={(e) => setBuilding(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Building Number"
            value={buildingNumber}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(buildingNumber) }}
            onChange={(e) => setBuildingNumber(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Building Code"
            value={buildingCode}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(buildingCode) }}
            onChange={(e) => setBuildingCode(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Building Description"
            value={buildingDescription}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(buildingDescription) }}
            onChange={(e) => setBuildingDescription(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Building Remarks"
            value={buildingRemarks}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            onChange={(e) => setBuildingRemarks(e.target.value)}
          />
        </Stack>
        </Box> 
      </Box>
      }
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={referencesStore.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </form>
      :
      null
  );
});

export default BuildingItem;
