import { observer } from "mobx-react";
import { useMemo, useState } from "react";
import { useStores } from "../../../../hooks/useStores";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { formatDateWithTime } from "../../../../utils/formatDate";
import { DataGridBox } from "../../../../styles/dataGridBox";
import TagDetailDocumentToolBar from "../components/documents/TagDetailDocumentToolBar"
import { MasterDocuments } from "../../../../models/MasterArea/response/getAssetsDetailResponse";
import { ROUTES } from "../../../../constants/routes";
import { useNavigate } from "react-router-dom";

const useMasterGridColumns = () => {

  const navigate = useNavigate();

  const columns: GridColDef[] = useMemo(
    () => [
    {
      field: "documentCode",
      headerName: "Document Number",
      flex: 1,
      minWidth: 300,
      renderCell: (params) => {
        return (
          <>
            <a href="javascript:void(0);" rel="noreferrer" onClick={() => navigate(ROUTES.masterAreaEditDocument + "?id=" + params.row.documentId)}>
              {params.value}
            </a>
          </>
        );
      }
    },
    {
      field: "documentId",
      headerName: "Document Code",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "documentType",
      headerName: "Document Type",
      flex: 1,
      minWidth: 150,
    },
    { field: "documentUrl", headerName: "Document Url", flex: 1, minWidth: 300,
      renderCell: (params) => {
        return (
          <>
            <a href={params.value} rel="noreferrer" target="_blank">
              {params.value}
            </a>
          </>
        );
      } },
    {
      field: "createdOn",
      headerName: "Created On",
      flex: 1,
      minWidth: 120,
      valueFormatter: formatDateWithTime,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "modifiedOn",
      headerName: "Modified On",
      flex: 1,
      minWidth: 120,
      valueFormatter: formatDateWithTime,
    },
    {
      field: "modifiedBy",
      headerName: "Modified By",
      flex: 1,
      minWidth: 200,
    }
  ],
    []
  );
  return columns;
}

interface MasterTagDetailDocumentsTabProps {
  Toolbar: boolean;
  setIsFormChanged: any;
  handleGeneralDocumentsChange: any;
}

const MasterTagDetailDocumentsTab = observer((props: MasterTagDetailDocumentsTabProps) => {
  const columns = useMasterGridColumns();
  
  const {
    masterAreaCardViewDocumentStore,
    masterAreaCardViewStore: { masterDocuments, setMasterDocuments },
  } = useStores();

  const [documents, setDocuments] = useState<MasterDocuments[]>(masterDocuments);

  const handleAddDocuments = (masterDocuments: MasterDocuments[]) => {
    const docs = Object.assign([], documents);
    for (const masterDocument of masterDocuments) {
      if (documents?.filter(d => masterDocument.documentId === d.documentId).length === 0 ) {      
        docs.push(masterDocument);      
      }
    }

    setDocuments([...docs]);
    setMasterDocuments(docs);
    props.handleGeneralDocumentsChange(docs);

    props.setIsFormChanged(true);
  };

  const handleDeleteDocument = () => {
    const docs = documents?.filter(d => masterAreaCardViewDocumentStore.selectedIds.indexOf(d.documentId) < 0 );

    setDocuments([...docs]);
    setMasterDocuments(docs);
    props.handleGeneralDocumentsChange(docs);

    props.setIsFormChanged(true);
  };

  return (
    <DataGridBox>
      <DataGrid
        columns={columns}
        rows={documents}
        getRowId={(row) => row.documentId}
        checkboxSelection
        density="comfortable"
        onRowSelectionModelChange={(ids) => {
          const selectedIds = new Set(ids);
          masterAreaCardViewDocumentStore.setSelectedIds(
            Array.from(selectedIds).map((id) => parseInt(id as string))
          );  
        }}
        slots={{
          toolbar: props.Toolbar ? TagDetailDocumentToolBar : null,
        }}
        slotProps={{ toolbar: { Documents: documents, handleAddDocuments: handleAddDocuments, handleDeleteDocument: handleDeleteDocument } }}
      />
    </DataGridBox>
  );
});

export default MasterTagDetailDocumentsTab;
