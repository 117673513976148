import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";

const EditAttribute = observer(() => {
  const { masterAreaTagAttributesListStore } = useStores();

  const navigate = useNavigate();

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<EditIcon />}
        onClick={() => navigate(ROUTES.masterAreaEditTagAttribute + "?id=" + masterAreaTagAttributesListStore.selectedIds[0])}
        disabled={masterAreaTagAttributesListStore.selectedIds.length !== 1}
        >
        Edit
      </Button>
    </>
  );
});

export default EditAttribute;
