import { useEffect } from "react";
import DictionaryStore from "../../../../stores/Dictionary/DictionaryStore";

export const useFetchOptionsData = (
  dictionaryStore: DictionaryStore
) => {
  useEffect(() => {
    dictionaryStore.fetchEqTypeOptions("");
    dictionaryStore.fetchFacilityOptions("");
    dictionaryStore.fetchTagCategoryOptions("");
    dictionaryStore.fetchAreaOptions("");
    dictionaryStore.fetchUnitOptions("");
    dictionaryStore.fetchBuildingOptions("");
    dictionaryStore.fetchWellOptions("");  
    dictionaryStore.fetchPipeClassesOptions("");
    dictionaryStore.fetchFluidsOptions("");
    dictionaryStore.fetchLocationOptions("");
    dictionaryStore.fetchSubstationOptions("");
  }, [dictionaryStore]);
};

export const useCreateTagFetchOptionsData = (
  dictionaryStore: DictionaryStore
) => {
  useEffect(() => {
    dictionaryStore.fetchTagCategoryOptions("");
    dictionaryStore.fetchProjectOptions("");
    dictionaryStore.fetchAreaOptions("");
    dictionaryStore.fetchUnitOptions("");
    dictionaryStore.fetchBuildingOptions("");
    dictionaryStore.fetchWellOptions("");
    dictionaryStore.fetchParentTagNumberByCategoryOptions("");
    dictionaryStore.fetchFacilityOptions("");
    dictionaryStore.fetchMasterDocumentsByNumber("");
    dictionaryStore.fetchPrimaryTagNumberByCategoryOptions("");
    dictionaryStore.fetchPipeClassesOptions("");
    dictionaryStore.fetchFluidsOptions("");
    dictionaryStore.fetchLocationOptions("");
    dictionaryStore.fetchSubstationOptions("");
  }, [dictionaryStore]);
};
