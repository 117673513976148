import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteAttributeTagsByIds } from "../hooks/useAttributesFetchRequests";

const DeleteAttribute = observer(() => {
  const { masterAreaTagAttributesListStore, notificationStore } = useStores();

  const handleDeleteClick = async () => {
    deleteAttributeTagsByIds(masterAreaTagAttributesListStore, notificationStore);
  };

  return (
    <Button
      variant="outlined"
      size="small"
      startIcon={<DeleteIcon />}
      disabled={masterAreaTagAttributesListStore.selectedIds.length < 1}
      onClick={handleDeleteClick}
    >
      Delete
    </Button>
  );
});

export default DeleteAttribute;
