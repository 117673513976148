import { Grid } from "@mui/material";
import { DataGridPro, GridColumnVisibilityModel, GridFilterModel } from "@mui/x-data-grid-pro";
import React, { useEffect, useState, useMemo } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import usePageTitle from "../../../hooks/usePageTitle";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { DataGridBox } from "../../../styles/dataGridBox";
import { useNavigate } from "react-router-dom";
import { formatDateWithTime } from "../../../utils/formatDate";
import { ROUTES } from "../../../constants/routes";
import ReferenceToolBar from "../components/toolbar/ReferenceToolBar";

const WellPage = observer(() => {
  usePageTitle("References - Wells");

  const navigate = useNavigate();

  const { referencesStore } =
    useStores();

  useEffect(() => {    
    referencesStore.fetchWells();
  }, []);

  const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    referencesStore.setFilterModel(filterModel);
    referencesStore.fetchAreas();
  }, []);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>();
  const columns: GridColDef[] = useMemo(
    () => [
      { field: "wellShort", headerName: "Well", flex: 1, minWidth: 250,
        renderCell: (params) => {
        return (
          <>
            <a href="javascript:void(0);" rel="noreferrer" onClick={() => navigate(ROUTES.referencesWell + "?id=" + params.row.id)}>
              {params.value}
            </a>
          </>
        );
      }  },
      { field: "wellCode", headerName: "Well Code", flex: 1, minWidth: 150},
      { field: "wellRemarks", headerName: "Well Remarks", flex: 1, minWidth: 200},
      { field: "createdDate", headerName: "Created On", flex: 1, minWidth: 150, valueFormatter: formatDateWithTime },
      { field: "createdBy", headerName: "Created By", flex: 1, minWidth: 200 },
      { field: "updatedDate", headerName: "Modified On", flex: 1, minWidth: 150, valueFormatter: formatDateWithTime },
      { field: "updatedBy", headerName: "Modified By", flex: 1, minWidth: 200 }
    ],
    []
  );

  return (
    <>
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item xs={12} md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <DataGridBox>
            <DataGridPro
              //disableColumnFilter
              pagination
              paginationModel={referencesStore.paginationModel}
              pageSizeOptions={[referencesStore.paginationModel.pageSize]}
              onPaginationModelChange={referencesStore.setPaginationModel}
              paginationMode="server"
              rows={referencesStore.wells?.items ?? []}
              getRowId={(row) => row.id} 
              columns={columns}
              rowCount={referencesStore.wells?.totalRecords}
              loading={referencesStore.isLoading}
              density="compact"
              sx={{ fontSize: "0.75rem" }}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
              slots={{ toolbar: ReferenceToolBar }}
              slotProps={{ toolbar: { createReferenceItemUrl: ROUTES.referencesCreateWell } }}
              filterMode="server"
              onFilterModelChange={onFilterChange}
            />
          </DataGridBox>
        </Grid>
      </Grid>
    </>
  );
});

export default WellPage;
