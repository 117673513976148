import {
  GridToolbarContainer
} from "@mui/x-data-grid";

import { observer } from "mobx-react";
import AddReferenceItem from "./AddReferenceItem";

interface ReferenceToolBarProps {
  createReferenceItemUrl: string; 
}

const ReferenceToolBar = observer((props: ReferenceToolBarProps) => {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", margin: "0 0 0 0px" }}>
        <GridToolbarContainer>
          <AddReferenceItem createReferenceItemUrl={props.createReferenceItemUrl} />     
        </GridToolbarContainer>
        </div>
    </div>
  );
});

export default ReferenceToolBar;
