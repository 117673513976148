export const FILTERS = { 
    projectNumberId: 'projectNumberFilterId',
    projectNumberValue: 'projectNumberFilterValue',
    tagNumberId: 'tagNumberFilterId',
    tagNumberValue: 'tagNumberFilterValue',
    statusId: 'statusFilterId',
    statusValue: 'statusFilterValue',
    facilityId: 'facilityFilterId',
    facilityValue: 'facilityFilterValue',
    areaId: 'areaFilterId',
    areaValue: 'areaFilterValue',
    unitId: 'unitFilterId',
    unitValue: 'unitFilterValue',
    categoryId: 'categoryFilterId',
    categoryValue: 'categoryFilterValue',
    serviceDescriptionValue: 'serviceDescriptionFilterValue',
    typeId: 'typeFilterId',
    typeValue: 'typeFilterValue',
    parentTagNumberId: 'parentTagNumberFilterId',
    parentTagNumberValue: 'parentTagNumberFilterValue',
    sequenceNumberValue: 'sequenceNumberFilterValue',
    remarksValue: 'remarksFilterValue',
    requestNumberValue: 'requestNumberFilterValue',
    suffixValue: 'suffixFilterValue'
}