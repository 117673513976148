import { observer } from "mobx-react";
import DocumentsDeleteEquipmentTag from "./DocumentsDeleteEquipmentTag";
import DocumentsAddEquipmentTag from "./DocumentsAddEquipmentTag";
import { GridToolbarContainer } from "@mui/x-data-grid";

interface DocumentEquipmentTagsToolBarProps {
  handleAddEquipmentTag: any;
  handleDeleteEquipmentTag: any;
}

const DocumentEquipmentTagsToolBar = observer((props: DocumentEquipmentTagsToolBarProps) => {
  return (
    <GridToolbarContainer>
      {<DocumentsAddEquipmentTag handleAddEquipmentTag={props.handleAddEquipmentTag} />}
      {<DocumentsDeleteEquipmentTag handleDeleteEquipmentTag={props.handleDeleteEquipmentTag}/>}
    </GridToolbarContainer>
  );
});

export default DocumentEquipmentTagsToolBar;
