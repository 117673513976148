import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import { Box, Button, TextField, Stack, Typography, CircularProgress, FormControlLabel, Checkbox, Backdrop } from "@mui/material";
import { useState, useEffect } from "react";
import usePageTitle from "../../../hooks/usePageTitle";
import { areaboxStyle} from "../../../constants/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { NOTIFICATION_TYPES } from "../../../constants/notificationTypes";
import { NullableString } from "../../../models/common/response/types";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { UpdateAreaRequest } from "../../../models/Dictionaries/request/updateAreaRequest";
import { validationColor } from "../../../utils/validation";

const AreaItem = observer(() => {
  usePageTitle("References - Area");

  const navigate = useNavigate();

  const [urlParams] = useSearchParams()

  const { referencesStore, notificationStore } = useStores();
  
  const [areaId, setAreaId] = useState<number>(0);
  const [area, setArea] = useState<NullableString>("");
  const [areaCode, setAreaCode] = useState<NullableString>("");
  const [areaDescription, setAreaDescription] = useState<NullableString>("");
  const [areaRemarks, setAreaRemarks] = useState<NullableString>("");
  const [areaNotForInstrumentation, setAreaNotForInstrumentation] = useState<boolean>(false);
  const [areaForInstrumentation, setAreaForInstrumentation] = useState<boolean>(false);
  const [areaRelatedCode, setAreaRelatedCode] = useState<NullableString>("");
 
  useEffect(() => {
    (async () => {   
      await initArea();
    })()
  }, []);

  const initArea = async () => {

    const idParam = parseInt(urlParams.get("id") ?? "0");
    await referencesStore.fetchAreaById(idParam);

    setAreaId(idParam);
    setArea(referencesStore.area?.area ?? "");
    setAreaCode(referencesStore.area?.areaCode ?? "");
    setAreaDescription(referencesStore.area?.areaDescription ?? "");
    setAreaRemarks(referencesStore.area?.areaRemarks ?? "");
    setAreaNotForInstrumentation(referencesStore.area?.areaNotForInstrumentation ?? false);
    setAreaForInstrumentation(referencesStore.area?.areaForInstrumentation ?? false);
    setAreaRelatedCode(referencesStore.area?.relatedArea ?? "");
  }
  
  const handleCloseClick = () => {
    navigate(-1);
  };

  const handleSaveClick = async () => {
    try {
      const areaItem: UpdateAreaRequest = {
        id: areaId,
        area: area,
        areaCode: areaCode,
        areaDescription: areaDescription,
        areaForInstrumentation: areaForInstrumentation,
        areaNotForInstrumentation: areaNotForInstrumentation,
        relatedAreaCode: areaRelatedCode,
        areaRemarks: areaRemarks
      }
      await referencesStore.updateArea(areaItem);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Area edited successfully");
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error editing area: " + error);
    }
    finally {
      notificationStore.setShowNotification(true);
      navigate(ROUTES.referencesAreas);
    }
  }

  return (
    referencesStore.area ?
    <form>
    <Box
          sx={ areaboxStyle }
        > 
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "#fff",
              display: { xs: "block", md: "flex", width: "50%" }
            }}
          >
            <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
              <ArrowBackOutlinedIcon />
            </Button>
            <Button 
              variant="contained" 
              sx={{ ml: 2 }} 
              disabled={!area || !areaCode || !areaDescription}
              onClick={() => handleSaveClick()}>
              <span>Save</span>
            </Button> 
          </Box>
        </Stack>
      </Box>
    {    
    <Box sx={{ "overflow-y": "scroll", height: "80vh" }}> 
      <Box
          sx={ areaboxStyle }
        >  
        <Typography
            id="add-tag-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
          Area
        </Typography>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Area"
            value={area}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(area) }}
            onChange={(e) => setArea(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Area Code"
            value={areaCode}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(areaCode) }}
            onChange={(e) => setAreaCode(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Area Description"
            value={areaDescription}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(areaDescription) }}
            onChange={(e) => setAreaDescription(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Area Remarks"
            value={areaRemarks}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            onChange={(e) => setAreaRemarks(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Related Area Code"
            value={areaRelatedCode}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            onChange={(e) => setAreaRelatedCode(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
        <FormControlLabel control={
              <Checkbox defaultChecked={false} onChange={(e) => setAreaForInstrumentation(e.target.checked) } />
            } label="Area For Instrumentation"  sx={{ width: "50%" }} 
          /> 
        </Stack>
        <Stack direction="row">
        <FormControlLabel control={
              <Checkbox defaultChecked={false} onChange={(e) => setAreaNotForInstrumentation(e.target.checked) } />
            } label="Area Not For Instrumentation"  sx={{ width: "50%" }} 
          /> 
        </Stack>
        </Box> 
      </Box>
      }
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={referencesStore.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </form>
      :
      null
  );
});

export default AreaItem;
