import { Button } from "@mui/material";
import { observer } from "mobx-react";
import AddIcon from "@mui/icons-material/Add";
import { useStores } from "../../../../../hooks/useStores";
import CreateUserCategoryMapButtonModal from "./CreateUserCategoryMapButtonModal";

const CreateUserCategoryMapButton = observer(() => {
  const { userCategoryMapStore } = useStores();

  const handleCreateUserCategoryMapClick = async () => {
    userCategoryMapStore.setShowCreateModal(true);
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<AddIcon />}
        onClick={handleCreateUserCategoryMapClick}
      >
        <span>Add</span>
      </Button>
      {userCategoryMapStore.showCreateModal ? <CreateUserCategoryMapButtonModal /> : null}
    </>
  );
});

export default CreateUserCategoryMapButton;
