import { NOTIFICATION_TYPES } from "../../../../constants/notificationTypes";
import NotificationStore from "../../../../stores/Common/NotificationStore";
import MasterAreaTagAttributesListStore from "../../../../stores/MasterArea/MasterAreaTagAttributesListStore";

/** @author Kanat Bektursyn
 * 
 * @param masterAreaTagAttributesListStore 
 * @param notificationStore 
 * 
 *  @method deleteMasterTagAttributesByIds | Function uses MasterAreaTagAttributesListStore's method
 * 
 * which has trycatch error handling for trycatchfinally
 * to leverage NotificationStore for user feedback.
 * 
 * Core logic of Attributes Tag Deletion is encapsulated
 * inside methods and stores
 * 
 * @memberof DeleteAttribute
 */
export const deleteAttributeTagsByIds = async (masterAreaTagAttributesListStore: MasterAreaTagAttributesListStore, notificationStore: NotificationStore) => {
    try {
        await masterAreaTagAttributesListStore.deleteMasterTagAttributesByIds();
        notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
        notificationStore.setNotificationMessage("Tag Attribute(-s) deleted successfully");  
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Tag Attribute(-s) cannot be deleted");
    }
    finally {
      notificationStore.setShowNotification(true);
    }
  }