import { Button } from "@mui/material";
import { observer } from "mobx-react";
import EditIcon from "@mui/icons-material/Edit";
import { useStores } from "../../../../../hooks/useStores";
import EditUserCategoryMapButtonModal from "./EditUserCategoryMapButtonModal";

const EditUserCategoryMapButton = observer(() => {
  const { userCategoryMapStore } = useStores();

  const handleEditClick = async () => {
    const userId = userCategoryMapStore.selectedIds[0];
    console.log(userId);

    await userCategoryMapStore.getUserCategoryMapById(userId);

    userCategoryMapStore.setShowEditModal(true);
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<EditIcon />}
        onClick={handleEditClick}
        disabled={
          userCategoryMapStore.selectedIds.length > 1 ||
          userCategoryMapStore.selectedIds.length === 0
        }
      >
        <span>Edit</span>
      </Button>
      {userCategoryMapStore.showEditModal ? <EditUserCategoryMapButtonModal /> : null}
    </>
  );
});

export default EditUserCategoryMapButton;
