import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import {
  Box,
  Button,
  TextField,
  Stack,
  Typography,
  Backdrop,
  CircularProgress,
  Autocomplete
} from "@mui/material";
import { useEffect, useState } from "react";
import usePageTitle from "../../../hooks/usePageTitle";
import { areaboxStyle} from "../../../constants/styles";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { NOTIFICATION_TYPES } from "../../../constants/notificationTypes";
import { NullableString } from "../../../models/common/response/types";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { validationColor } from "../../../utils/validation";
import { CreateParallelTrainRequest } from "../../../models/Dictionaries/request/createParallelTrainRequest";
import { SelectOption } from "../../../models/common/selectOption";
import { useDebounce } from "../../../hooks/useDebounce";

const ParallelTrainItemCreate = observer(() => {
  usePageTitle("References - New Parallel Train");

  const navigate = useNavigate();

  const { referencesStore, notificationStore, dictionaryStore } = useStores();
  
  const [parallelTrain, setParallelTrain] = useState<NullableString>("");
  const [unitOption, setUnitOption] = useState<SelectOption | null>(null);
  const [relatedUnit, setRelatedUnit] = useState<NullableString>("");

  useEffect(() => {
    dictionaryStore.fetchUnitOptions("");
  }, []);

  const debouncedHandleInputChange = useDebounce(500);

  const handleUnitInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchUnitOptions(inputValue);
    });
  };
  
  const handleCloseClick = () => {
    navigate(ROUTES.referencesParallelTrains);
  };

  const handleSaveClick = async () => {
    try {
      const parallelTrainItem: CreateParallelTrainRequest = {
        parallelTrainName: parallelTrain,
        unitId: unitOption?.id,
        relatedUnit: relatedUnit
      }
      await referencesStore.createParallelTrain(parallelTrainItem);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Parallel Train created successfully");
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error creating parallelTrain: " + error);
    }
    finally {
      notificationStore.setShowNotification(true);
      navigate(ROUTES.referencesParallelTrains);
    }
  }

  return (
    <form>
    <Box
          sx={ areaboxStyle }
        > 
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "#fff",
              display: { xs: "block", md: "flex", width: "50%" }
            }}
          >
            <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
              <ArrowBackOutlinedIcon />
            </Button>
            <Button 
              variant="contained" 
              sx={{ ml: 2 }} 
              disabled={!parallelTrain}
              onClick={() => handleSaveClick()}>
              <span>Save</span>
            </Button> 
          </Box>
        </Stack>
      </Box>
    {    
    <Box sx={{ "overflow-y": "scroll", height: "80vh" }}> 
      <Box
          sx={ areaboxStyle }
        >  
        <Typography
            id="add-tag-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
          New Parallel Train
        </Typography>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Parallel Train Name"
            value={parallelTrain}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(parallelTrain) }}
            onChange={(e) => setParallelTrain(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <Autocomplete
              disablePortal
              size="small"
              id="unit"
              options={dictionaryStore.unitOptions}
              value={unitOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%" }}
              renderInput={(params) => <TextField {...params} label="Unit" />}
              onInputChange={(event, newInputValue) => {
                handleUnitInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setUnitOption(selectedValue);
                handleUnitInputChange("");
              }}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
            />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Related Unit"
            value={relatedUnit}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            onChange={(e) => setRelatedUnit(e.target.value)}
          />
        </Stack>
        </Box> 
      </Box>
      }
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={referencesStore.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </form>
  );
});

export default ParallelTrainItemCreate;
