import { observer } from "mobx-react";
import usePageTitle from "../../hooks/usePageTitle";
import { useStores } from "../../hooks/useStores";
import { useEffect } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { commonStyle } from "../../styles/autoComplete";
import { useDebounce } from "../../hooks/useDebounce";
import ExRegisterDetails from "./components/ExRegisterDetails";
import formatDate from "../../utils/formatDate";

const columns: GridColDef[] = [
  {
    field: "tagNumber",
    headerName: "Tag Number",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "area",
    headerName: "Area",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "unit",
    headerName: "Unit",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "updatedBy",
    headerName: "Modified By",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "updatedDate",
    headerName: "Modified On",
    flex: 1,
    minWidth: 150,
    valueFormatter: formatDate,
  },
];

const ExRegisterPage = observer(() => {
  usePageTitle("Ex Register");

  const { exRegisterStore, dictionaryStore, masterAreaDocumentsStore } = useStores();

  useEffect(() => {
    dictionaryStore.fetchMasterSystemnames("");
    dictionaryStore.fetchMasterSubsystems("");
    dictionaryStore.fetchIpRatings("");


    const fetchData = async () => {
      await exRegisterStore.fetchExRegisterTags();
    };

    fetchData();
  }, [
    exRegisterStore.selectedTagNumberOption,
    exRegisterStore.selectedSystemnameOption,
    exRegisterStore.selectedSubsystemOption,
  ]);

  const debouncedHandleInputChange = useDebounce(500);

  const handleTagNumberInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchMasterTagNumberOptions(inputValue);
    });
  };

  const handleMasterSystemnameInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchMasterSystemnames(inputValue);
    });
  };

  const handleMasterSubsystemInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchMasterSubsystems(inputValue);
    });
  };

  const handleOnRowClick = async (id: number) => {
    exRegisterStore.setShowDetailLoading(true);
    await exRegisterStore.fetchExRegisterAttributesById(id);
    await masterAreaDocumentsStore.fetchDocumentsById(id);
    exRegisterStore.setShowDetailLoading(false);
  };

  return (
    <Grid container spacing={2} sx={{ height: "100%" }}>
      <Grid item xs={12} md={5} sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            p: 2,
            backgroundColor: "#fff",
          }}
        >
          <Stack direction="row" alignItems="center" gap={1} sx={{ mb: 2 }}>
            <FilterAltIcon color="primary" fontSize="small" />
            <Typography variant="h6" sx={{ fontWeight: 700 }}>
              Filters
            </Typography>
          </Stack>
          <div style={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
            <Autocomplete
              id="tagNumber"
              size="small"
              freeSolo
              sx={commonStyle}
              options={dictionaryStore.masterTagNumberOptions}
              value={exRegisterStore.selectedTagNumberOption}
              renderInput={(params) => <TextField {...params} label="Tag Number" />}
              onInputChange={(event, newInputValue, reason) => {
                handleTagNumberInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                exRegisterStore.setSelectedTagNumberOption(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            <Autocomplete
              size="small"
              id="systemname"
              options={dictionaryStore.masterSystemnameOptions}
              value={exRegisterStore.selectedSystemnameOption ?? ""}
              getOptionLabel={(option) => (option ? option.label : "")}
              sx={{
                width: 170,
                mr: 1,
                mb: 1,
              }}
              renderInput={(params) => <TextField {...params} label="System" />}
              onInputChange={(event, newInputValue) => {
                handleMasterSystemnameInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                exRegisterStore.setSelectedSystemnameOption(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            <Autocomplete
              size="small"
              id="subsystem"
              options={dictionaryStore.masterSubsystemOptions}
              value={exRegisterStore.selectedSubsystemOption ?? ""}
              getOptionLabel={(option) => (option ? option.label : "")}
              sx={{
                width: 170,
                mr: 1,
                mb: 1,
              }}
              renderInput={(params) => <TextField {...params} label="Subsystem" />}
              onInputChange={(event, newInputValue) => {
                handleMasterSubsystemInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                exRegisterStore.setSelectedSystemnameOption(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </div>
        </Box>
        <Box
          component="div"
          sx={{
            p: 2,
            backgroundColor: "#fff",
            flexGrow: 1,
            mt: 2,
            height: "500px",
            overflowY: "hidden",
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
              fontSize: "0.9rem",
            },
            "& .MuiDataGrid-row": {
              borderBottom: "solid",
              borderBottomColor: "#f0f0f0",
              maxHeight: "80px !important",
            },
            "& .MuiDataGrid-cellContent": {
              whiteSpace: "normal",
            },
            "& .MuiDataGrid-cell": {
              maxHeight: "80px !important",
            },
          }}
        >
          <DataGridPro
            // disableColumnFilter
            paginationMode="server"
            columns={columns}
            rows={exRegisterStore.exRegisterResponse.items}
            rowCount={exRegisterStore.exRegisterResponse.totalRecords}
            loading={exRegisterStore.isLoading}
            pagination
            paginationModel={exRegisterStore.paginationModel}
            pageSizeOptions={[exRegisterStore.paginationModel.pageSize]}
            onPaginationModelChange={exRegisterStore.setPaginationModel}
            // sortingMode="server"
            // sortModel={projectAreaCardViewStore.sortModel}
            // onSortModelChange={projectAreaCardViewStore.setSortModel}
            onRowClick={(params) => {
              handleOnRowClick(params.row.id);
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={7}>
        <Box
          sx={{
            p: 2,
            backgroundColor: exRegisterStore.showDetailLoading ? "#FBFBFB" : "#FFF",
            height: "100%",
            position: "relative",
          }}
        >
          {exRegisterStore.showDetailLoading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <CircularProgress size={100} />
            </div>
          )}
          <ExRegisterDetails />
        </Box>
      </Grid>
    </Grid>
  );
});

export default ExRegisterPage;
