import App from './App';
import { RouteObject } from 'react-router-dom';
import SupportPage from './pages/support';
import AboutPage from './pages/about';
import ProjectAreaPage from './pages/projectArea/cardView';
import MasterAreaGridPage from './pages/MasterArea/gridView';
import MasterAreaTagAttributesPage from './pages/MasterArea/attributes';
import MasterAreaDocumentsPage from './pages/MasterArea/documents';
import MasterAreaProjectsPage from './pages/MasterArea/projects';
import Error404 from './pages/Errors/Error404/Error404';
import Settings from './pages/settings/Settings';
import TagRequestForms from './pages/TagRequestForms';
import MasterAreaAddTag from './pages/MasterArea/gridView/newtag';
import MasterAreaEditTag from './pages/MasterArea/gridView/edittag';
import MasterAreaCopyTag from './pages/MasterArea/gridView/copytag';
import MasterAreaEditDocument from './pages/MasterArea/documents/edit';
import MasterAreaAddTagAttribute from './pages/MasterArea/attributes/create';
import MasterAreaEditTagAttribute from './pages/MasterArea/attributes/edit';
import MasterAreaEditProject from './pages/MasterArea/projects/edit';
import TagAuditGridPage from './pages/TagAudit';
import TagAuditPackagesGridPage from './pages/TagAudit/features/handoverPackages/TagAuditPackagesGridPage';
import UserProjectsManagementGridPage from './pages/AdminArea/userProjectsManagement';
import AreaPage from './pages/References/areas/index';
import AreaItem from './pages/References/areas/item';
import AreaItemCreate from './pages/References/areas/create';
import BuildingPage from './pages/References/buildings/index';
import BuildingItem from './pages/References/buildings/item';
import BuildingItemCreate from './pages/References/buildings/create';
import FacilityPage from './pages/References/facilities/index';
import FacilityItem from './pages/References/facilities/item';
import FacilityItemCreate from './pages/References/facilities/create';
import FluidPage from './pages/References/fluids/index';
import FluidItem from './pages/References/fluids/item';
import FluidItemCreate from './pages/References/fluids/create';
import LocationPage from './pages/References/locations/index';
import LocationItem from './pages/References/locations/item';
import LocationItemCreate from './pages/References/locations/create';
import PipeClassPage from './pages/References/pipeclasses/index';
import PipeClassItem from './pages/References/pipeclasses/item';
import PipeClassItemCreate from './pages/References/pipeclasses/create';
import ParallelTrainPage from './pages/References/paralleltrains/index';
import ParallelTrainItem from './pages/References/paralleltrains/item';
import ParallelTrainItemCreate from './pages/References/paralleltrains/create';
import SubstationPage from './pages/References/substations/index';
import SubstationItem from './pages/References/substations/item';
import SubstationItemCreate from './pages/References/substations/create';
import SequencePage from './pages/References/sequences/index';
import SequenceItem from './pages/References/sequences/item';
import SequenceItemCreate from './pages/References/sequences/create';
import UnitPage from './pages/References/units/index';
import UnitItem from './pages/References/units/item';
import UnitItemCreate from './pages/References/units/create';
import WellPage from './pages/References/wells/index';
import WellItem from './pages/References/wells/item';
import WellItemCreate from './pages/References/wells/create';
import ExRegisterPage from './pages/ExRegister';
import UserCategoriesManagementGridPage from './pages/AdminArea/userCategoryManagement';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    children: [
        {
            index: true,
            path: 'project-area-card',
            element: <ProjectAreaPage />
          },
          {
            path: 'master-area-grid',
            element: <MasterAreaGridPage />
          },
          {
            path: 'master-area-grid/tags',
            element: <MasterAreaGridPage />
          },
          {
            path: 'master-area-grid/attributes',
            element: <MasterAreaTagAttributesPage />
          },
          {
            path: 'master-area-add-tagattribute',
            element: <MasterAreaAddTagAttribute />
          },
          {
            path: 'master-area-edit-tagattribute',
            element: <MasterAreaEditTagAttribute />
          },
          {
            path: 'master-area-grid/documents',
            element: <MasterAreaDocumentsPage />
          },
          {
            path: 'master-area-edit-document',
            element: <MasterAreaEditDocument />
          },
          {
            path: 'master-area-grid/projects',
            element: <MasterAreaProjectsPage />
          },
          {
            path: 'master-area-edit-project',
            element: <MasterAreaEditProject />
          },
          {
            path: 'master-area-new-tag',
            element: <MasterAreaAddTag />
          },
          {
            path: 'master-area-edit-tag',
            element: <MasterAreaEditTag />
          },
          {
            path: 'master-area-copy-tag',
            element: <MasterAreaCopyTag />
          },
          {
            path: 'tag-request-forms',
            element: <TagRequestForms />
          },
          {
            path: 'support',
            element: <SupportPage />
          },
          {
            path: 'about',
            element: <AboutPage />
          },
          {
            path: 'admin-area',
            element: <Settings />
          },
          {
            path: '/admin-area/user-projects-management',
            element: <UserProjectsManagementGridPage />
          },
          {
            path: '/admin-area/user-category-management',
            element: <UserCategoriesManagementGridPage />
          },
          {
            path: 'tag-audit-handover',
            element: <TagAuditGridPage />
          },
          {
            path: 'tag-handover-packages',
            element: <TagAuditPackagesGridPage />
          },
          {
            path: 'references/areas',
            element: <AreaPage />
          },
          {
            path: 'references/area',
            element: <AreaItem />
          },
          {
            path: 'references/create-area',
            element: <AreaItemCreate />
          },
          {
            path: 'references/buildings',
            element: <BuildingPage />
          },
          {
            path: 'references/building',
            element: <BuildingItem />
          },
          {
            path: 'references/create-building',
            element: <BuildingItemCreate />
          },
          {
            path: 'references/facilities',
            element: <FacilityPage />
          },
          {
            path: 'references/facility',
            element: <FacilityItem />
          },
          {
            path: 'references/create-facility',
            element: <FacilityItemCreate />
          },
          {
            path: 'references/fluids',
            element: <FluidPage />
          },
          {
            path: 'references/fluid',
            element: <FluidItem />
          },
          {
            path: 'references/create-fluid',
            element: <FluidItemCreate />
          },
          {
            path: 'references/locations',
            element: <LocationPage />
          },
          {
            path: 'references/location',
            element: <LocationItem />
          },
          {
            path: 'references/create-location',
            element: <LocationItemCreate />
          },
          {
            path: 'references/paralleltrains',
            element: <ParallelTrainPage />
          },
          {
            path: 'references/paralleltrain',
            element: <ParallelTrainItem />
          },
          {
            path: 'references/create-paralleltrain',
            element: <ParallelTrainItemCreate />
          },
          {
            path: 'references/pipeclasses',
            element: <PipeClassPage />
          },
          {
            path: 'references/pipeclass',
            element: <PipeClassItem />
          },
          {
            path: 'references/create-pipeclass',
            element: <PipeClassItemCreate />
          },
          {
            path: 'references/sequences',
            element: <SequencePage />
          },
          {
            path: 'references/sequence',
            element: <SequenceItem />
          },
          {
            path: 'references/create-sequence',
            element: <SequenceItemCreate />
          },
          {
            path: 'references/substations',
            element: <SubstationPage />
          },
          {
            path: 'references/substation',
            element: <SubstationItem />
          },
          {
            path: 'references/create-substation',
            element: <SubstationItemCreate />
          },
          {
            path: 'references/units',
            element: <UnitPage />
          },
          {
            path: 'references/unit',
            element: <UnitItem />
          },
          {
            path: 'references/create-unit',
            element: <UnitItemCreate />
          },
          {
            path: 'references/wells',
            element: <WellPage />
          },
          {
            path: 'references/well',
            element: <WellItem />
          },
          {
            path: 'references/create-well',
            element: <WellItemCreate />
          },
          {
            path: 'ex-register',
            element: <ExRegisterPage />
          },
          {
            path: '*',
            element: <Error404 />
          }
    ]
  }
];
