import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import { Box, Button, TextField, Typography, Stack, Backdrop, CircularProgress } from "@mui/material";
import { useState } from "react";
import usePageTitle from "../../../hooks/usePageTitle";
import { areaboxStyle} from "../../../constants/styles";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { NOTIFICATION_TYPES } from "../../../constants/notificationTypes";
import { NullableString } from "../../../models/common/response/types";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { CreateFacilityRequest } from "../../../models/Dictionaries/request/createFacilityRequest";
import { validationColor } from "../../../utils/validation";

const FacilityItemCreate = observer(() => {
  usePageTitle("References - New Facility");

  const navigate = useNavigate();

  const { referencesStore, notificationStore } = useStores();
  
  const [facility, setFacility] = useState<NullableString>("");
  const [facilityCode, setFacilityCode] = useState<NullableString>("");
  const [facilityDescription, setFacilityDescription] = useState<NullableString>("");
  const [facilityRemarks, setFacilityRemarks] = useState<NullableString>("");

  const handleCloseClick = () => {
    navigate(ROUTES.referencesFacilities);
  };

  const handleSaveClick = async () => {
    try {
      const facilityItem: CreateFacilityRequest = {
        facility: facility,
        facilityCode: facilityCode,
        facilityDescription: facilityDescription,
        facilityRemarks: facilityRemarks
      }
      await referencesStore.createFacility(facilityItem);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Facility created successfully");
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error creating facility: " + error);
    }
    finally {
      notificationStore.setShowNotification(true);
      navigate(ROUTES.referencesFacilities);
    }
  }

  return (
    <form>
    <Box
          sx={ areaboxStyle }
        > 
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "#fff",
              display: { xs: "block", md: "flex", width: "50%" }
            }}
          >
            <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
              <ArrowBackOutlinedIcon />
            </Button>
            <Button 
              variant="contained" 
              sx={{ ml: 2 }} 
              disabled={!facility || !facilityCode || !facilityDescription}
              onClick={() => handleSaveClick()}>
              <span>Save</span>
            </Button> 
          </Box>
        </Stack>
      </Box>
    {    
    <Box sx={{ "overflow-y": "scroll", height: "80vh" }}> 
      <Box
          sx={ areaboxStyle }
        >  
         <Typography
            id="add-tag-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
          New Facility
          </Typography> 
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Facility"
            value={facility}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(facility) }}
            onChange={(e) => setFacility(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Facility Code"
            value={facilityCode}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(facilityCode) }}
            onChange={(e) => setFacilityCode(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Facility Description"
            value={facilityDescription}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(facilityDescription) }}
            onChange={(e) => setFacilityDescription(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Facility Remarks"
            value={facilityRemarks}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            onChange={(e) => setFacilityRemarks(e.target.value)}
          />
        </Stack>
        </Box> 
      </Box>
      }
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={referencesStore.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </form>
  );
});

export default FacilityItemCreate;
