import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import DocumentsAddEquipmentTagModal from "./DocumentsAddEquipmentTagModal";

interface DocumentsAddEquipmentTagProps {
  handleAddEquipmentTag: any;
}

const DocumentsAddEquipmentTag = observer((props: DocumentsAddEquipmentTagProps) => {
  const {
    masterAreaDocumentsStore, dictionaryStore
  } = useStores();

  const handleAddClick = () => {
    masterAreaDocumentsStore.setShowAddEquipmentTagModal(true);

    dictionaryStore.fetchMasterTagNumberOptions("");
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<AddIcon />}
        onClick={handleAddClick}
      >
        <span>Add</span>
      </Button>
      <DocumentsAddEquipmentTagModal handleAddEquipmentTag={props.handleAddEquipmentTag}/>
    </>
  );
});

export default DocumentsAddEquipmentTag;
