export const ROUTES = { 
    masterAreaGrid: "/master-area-grid", 
    masterAreaGridAttributes: "/master-area-grid/attributes", 
    masterAreaGridProjects: "/master-area-grid/projects", 
    masterAreaGridDocuments: "/master-area-grid/documents", 
    masterAreaNewTag: "/master-area-new-tag", 
    masterAreaEditTag: "/master-area-edit-tag", 
    masterAreaCopyTag: "/master-area-copy-tag",
    masterAreaEditDocument: "/master-area-edit-document", 
    masterAreaAddTagAttribute: "/master-area-add-tagattribute",
    masterAreaEditTagAttribute: "/master-area-edit-tagattribute",
    masterAreaEditProject: "/master-area-edit-project", 
    referencesFacilities: "/references/facilities",
    referencesFacility: "/references/facility",
    referencesCreateFacility: "/references/create-facility",
    referencesAreas: "/references/areas",
    referencesArea: "/references/area",
    referencesCreateArea: "/references/create-area",
    referencesUnits: "/references/units",
    referencesUnit: "/references/unit",
    referencesCreateUnit: "/references/create-unit",
    referencesBuildings: "/references/buildings",
    referencesBuilding: "/references/building",
    referencesCreateBuilding: "/references/create-building",
    referencesWells: "/references/wells",
    referencesWell: "/references/well",
    referencesCreateWell: "/references/create-well",
    referencesSubstations: "/references/substations",
    referencesSubstation: "/references/substation",
    referencesCreateSubstation: "/references/create-substation",
    referencesPipeClasses: "/references/pipeclasses",
    referencesPipeClass: "/references/pipeclass",
    referencesCreatePipeClass: "/references/create-pipeclass",
    referencesFluids: "/references/fluids",
    referencesFluid: "/references/fluid",
    referencesCreateFluid: "/references/create-fluid",
    referencesLocations: "/references/locations",
    referencesLocation: "/references/location",
    referencesCreateLocation: "/references/create-location",
    referencesSequences: "/references/sequences",
    referencesSequence: "/references/sequence",
    referencesCreateSequence: "/references/create-sequence",
    referencesParallelTrains: "/references/paralleltrains",
    referencesParallelTrain: "/references/paralleltrain",
    referencesCreateParallelTrain: "/references/create-paralleltrain",
}