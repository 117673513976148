import MasterAssetsStore from "./MasterArea/MasterAssetsStore";
import MasterAreaProjectsStore from "./MasterArea/MasterAreaProjectsStore";
import MasterAreaTagAttributesListStore from "./MasterArea/MasterAreaTagAttributesListStore";
import MasterAreaDocumentsStore from "./MasterArea/MasterAreaDocumentsStore";
import DictionaryStore from "./Dictionary/DictionaryStore";
import ProjectAreaGridViewExportStore from "./ProjectAreaStore/ProjectAreaGridViewExportStore";
import ProjectAreaStore from "./ProjectAreaStore/ProjectAreaStore";
import ProjectAreaCardViewStore from "./ProjectAreaStore/ProjectAreaCardViewStore";
import ProjectAreaPopupCardViewStore from "./ProjectAreaPopupStore/ProjectAreaPopupCardViewStore";
import NotificationStore from "./Common/NotificationStore";
import ProjectAreaUpdateStore from "./ProjectAreaStore/ProjectAreaUpdateStore";
import ProjectColumnStore from "./Configuration/ProjectColumnStore";
import ProjectAreaGridBulkUpdateStore from "./ProjectAreaStore/ProjectAreaGridBulkUpdateStore";
import MasterAreaCardViewStore from "./MasterArea/MasterAreaCardViewStore";
import MasterAreaUpdateStore from "./MasterArea/MasterAreaUpdateStore";
import MasterAreaGridViewExportStore from "./MasterArea/MasterAreaGridViewExportStore";
import MasterAreaGridBulkUpdateStore from "./MasterArea/MasterAreaGridBulkUpdateStore";
import MasterAreaTagAttributesStore from "./MasterArea/MasterAreaTagAttributesStore";
import MasterAreaCardViewRelatedTagStore from "./MasterArea/MasterAreaCardViewRelatedTagStore";
import MasterAreaCardViewDocumentStore from "./MasterArea/MasterAreaCardViewDocumentStore";
import ProjectAreaCardViewDocumentStore from "./ProjectAreaStore/ProjectAreaCardViewDocumentStore";
import ProjectAreaCardViewTagAttributesStore from "./ProjectAreaStore/ProjectAreaCardViewTagAttributesStore";
import ProjectAreaCardViewTagValidationStore from "./ProjectAreaStore/ProjectAreaCardViewTagValidationStore";
import ProjectAreaCardViewRelatedTagStore from "./ProjectAreaStore/ProjectAreaCardViewRelatedTagStore";
import ProjectAreaPopupCardViewDocumentStore from "./ProjectAreaPopupStore/ProjectAreaPopupCardViewDocumentStore";
import ProjectAreaPopupCardViewTagAttributesStore from "./ProjectAreaPopupStore/ProjectAreaPopupCardViewTagAttributesStore";
import ProjectAreaPopupCardViewRelatedTagStore from "./ProjectAreaPopupStore/ProjectAreaPopupCardViewRelatedTagStore";
import TagRequestFormStore from "./TagRequest/TagRequestFormStore";
import UserStore from "./UserStore/UserStore";
import TagAuditStore from "./TagAuditStore/TagAuditStore";
import MasterAreaImportFromExcelStore from "./MasterArea/MasterAreaImportFromExcelStore";
import UserProjectMapStore from "./AdminArea/UserProjectMapStore";
import ReferencesStore from "./AdminArea/References/ReferencesStore";
import ProjectAreaCardViewInfoStore from "./ProjectAreaStore/ProjectAreaCardViewInfoStore";
import ExRegisterStore from "./ExRegister/ExRegisterStore";
import UserCategoryMapStore from "./AdminArea/UserCategoryMapStore";

class RootStore {
    projectAreaStore = new ProjectAreaStore();
    dictionaryStore = new DictionaryStore();
    projectAreaGridViewExportStore = new ProjectAreaGridViewExportStore();
    masterAssetsStore = new MasterAssetsStore();
    tagRequestFormStore = new TagRequestFormStore();
    projectAreaCardViewStore = new ProjectAreaCardViewStore();
    projectAreaPopupCardViewStore = new ProjectAreaPopupCardViewStore();
    notificationStore = new NotificationStore();
    projectAreaUpdateStore = new ProjectAreaUpdateStore();
    projectColumnStore = new ProjectColumnStore();
    projectAreaGridBulkUpdateStore = new ProjectAreaGridBulkUpdateStore();
    masterAreaCardViewStore = new MasterAreaCardViewStore();
    masterAreaUpdateStore = new MasterAreaUpdateStore();
    masterAreaGridViewExportStore = new MasterAreaGridViewExportStore();
    masterAreaGridBulkUpdateStore = new MasterAreaGridBulkUpdateStore();
    projectAreaCardViewDocumentStore = new ProjectAreaCardViewDocumentStore();
    projectAreaCardViewTagAttributesStore = new ProjectAreaCardViewTagAttributesStore();
    projectAreaCardViewTagValidationStore = new ProjectAreaCardViewTagValidationStore();
    projectAreaCardViewRelatedTagStore = new ProjectAreaCardViewRelatedTagStore();
    projectAreaPopupCardViewDocumentStore = new ProjectAreaPopupCardViewDocumentStore();
    projectAreaPopupCardViewTagAttributesStore = new ProjectAreaPopupCardViewTagAttributesStore();
    projectAreaPopupCardViewRelatedTagStore = new ProjectAreaPopupCardViewRelatedTagStore();
    userStore = new UserStore();
    masterAreaTagAttributesStore = new MasterAreaTagAttributesStore();
    masterAreaCardViewRelatedTagStore = new MasterAreaCardViewRelatedTagStore();
    masterAreaCardViewDocumentStore = new MasterAreaCardViewDocumentStore();
    tagAuditStore = new TagAuditStore();
    masterAreaImportFromExcel = new MasterAreaImportFromExcelStore()
    userProjectMapStore = new UserProjectMapStore();
    masterAreaProjectsStore = new MasterAreaProjectsStore();
    masterAreaTagAttributesListStore = new MasterAreaTagAttributesListStore();
    masterAreaDocumentsStore = new MasterAreaDocumentsStore();
    referencesStore = new ReferencesStore();
    projectAreaCardViewInfoStore = new ProjectAreaCardViewInfoStore();
    exRegisterStore = new ExRegisterStore();
    userCategoryMapStore = new UserCategoryMapStore();
}

export default RootStore;