import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import {
  Box,
  Button,
  TextField,
  Stack,
  Tabs,
  Tab,
  Autocomplete,
  Backdrop,
  Typography
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useEffect } from "react";
import usePageTitle from "../../../hooks/usePageTitle";
import { areaboxStyle} from "../../../constants/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import CustomTabPanel from "../../../components/common/CustomTabPanel";
import { NullableString } from "../../../models/common/response/types";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { NOTIFICATION_TYPES } from "../../../constants/notificationTypes";
import { GetMasterAreaTagAttributesUpdateRequest } from "../../../models/MasterArea/request/getMasterAreaTagAttributesUpdateRequest";
import { SelectOption } from "../../../models/common/selectOption";
import { useDebounce } from "../../../hooks/useDebounce";
import { validationColor } from "../../../utils/validation";

const MasterAreaEditTagAttribute = observer(() => {
  usePageTitle("Master Area - Tag Attribute");

  const navigate = useNavigate();
  const [urlParams] = useSearchParams();
  const debouncedHandleInputChange = useDebounce(500);

  const { masterAreaTagAttributesListStore, notificationStore, dictionaryStore } = useStores();

  const [tabValue, setValue] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [tagAttibuteId, setTagAttibuteId] = useState<number>(0);
  const [masterTagOption, setMasterTagOption] = useState<SelectOption | null>(null);
  const [tagAttributeClassOption, setTagAttributeClassOption] = useState<SelectOption | null>(null);
  const [tagAttributeValue, setTagAttributeValue] = useState<NullableString>("");
  const [uomOption, setUomOption] = useState<SelectOption | null>(null);

  useEffect(() => {
    (async () => {   
      await initTagAttribute();
      dictionaryStore.fetchEclTagAttributesOptions("");
      dictionaryStore.fetchMasterTagNumberOptions("");
      dictionaryStore.fetchUnitOfMeasuresOptions("");
    })()
  }, []);

  const initTagAttribute = async () => {
    const idParam = parseInt(urlParams.get("id") ?? "0");
    await masterAreaTagAttributesListStore.fetchTagAttributeById(idParam);

    setTagAttibuteId(idParam);
    setMasterTagOption({ id: masterAreaTagAttributesListStore.tagAttributeResponse?.masterEquipmentId!,  label: masterAreaTagAttributesListStore.tagAttributeResponse?.masterTagNumber ?? ""});
    setTagAttributeClassOption({ id: masterAreaTagAttributesListStore.tagAttributeResponse?.tagAttributeId!,  label: masterAreaTagAttributesListStore.tagAttributeResponse?.tagAttributeName ?? ""});
    setTagAttributeValue(masterAreaTagAttributesListStore.tagAttributeResponse?.tagAttributeValue ?? "");
    setUomOption({id: masterAreaTagAttributesListStore.tagAttributeResponse?.uomId!, label: masterAreaTagAttributesListStore.tagAttributeResponse?.uomName ?? ""});
  }

  const handleMasterTagChange = (selectedValue: SelectOption | null) => {
    setMasterTagOption(selectedValue);
  };

  const handleMasterTagInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchMasterTagNumberOptions(inputValue);
    });
  };

  const handleAttributeClassInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchEclTagAttributesOptions(inputValue);
    });
  };

  const handleAttributeClassChange = (selectedValue: SelectOption | null) => {
    setTagAttributeClassOption(selectedValue);
  };

  const handleAttributeUOMInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchUnitOfMeasuresOptions(inputValue);
    });
  };

  const handleAttributeUOMChange = (selectedValue: SelectOption | null) => {
    setUomOption(selectedValue);
  };

  const handleSaveClick = async () => {
    try {
      const tagAttribute: GetMasterAreaTagAttributesUpdateRequest = {
        id: tagAttibuteId,
        masterEquipmentId: masterTagOption?.id,
        tagAttributeId: tagAttributeClassOption?.id,
        tagAttributeValue: tagAttributeValue,
        uomId: uomOption?.id
      }
      await masterAreaTagAttributesListStore.updateTagAttributeById(tagAttribute);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Tag Attribute edited successfully");
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error editing Tag Attribute: " + error);
    }
    finally {
      notificationStore.setShowNotification(true);
      navigate(ROUTES.masterAreaGridAttributes);
    }
  }
  
  const handleCloseClick = () => {
    navigate(-1);
  };

  return (
    masterAreaTagAttributesListStore.tagAttributeResponse?.id ?
    <form>
    <Box
          sx={ areaboxStyle }
        > 
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "#fff",
              display: { xs: "block", md: "flex", width: "50%" }
            }}
          >
            <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
              <ArrowBackOutlinedIcon />
            </Button>
            <Button 
              variant="contained" 
              disabled={!masterTagOption || !tagAttributeClassOption || !tagAttributeValue} 
              sx={{ ml: 2 }} 
              onClick={() => handleSaveClick()}>
              <span>Save</span>
            </Button> 
          </Box>
        </Stack>
      </Box>
    {    
    <Box sx={{ "overflow-y": "scroll", height: "80vh" }}> 
    <div style={ areaboxStyle }>
      <Tabs
        value={tabValue}
        variant="scrollable"
        aria-label="New-Tag-Tabs"
        onChange={handleTabChange}
      >
        <Tab label="General" />
      </Tabs>    
    </div>
    <CustomTabPanel value={tabValue} index={0}>
        <Box
          sx={ areaboxStyle }
        >  
        <Typography
            id="add-tag-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
          Edit Tag Attribute
        </Typography>
        <Stack direction="row">
          <Autocomplete
            size="small"
            id="EquipmentTag"         
            options={dictionaryStore.masterTagNumberOptions ?? []}
            value={masterTagOption}
            getOptionLabel={(option) => option.label}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(masterTagOption?.label) }}
            renderInput={(params) => (
              <TextField {...params} label="Equipment Tag" />
            )}
            onInputChange={(event, newInputValue) => {
              handleMasterTagInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              handleMasterTagChange(selectedValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Stack>
        <Stack direction="row">
        <Autocomplete
            size="small"
            id="AttributeClass"
            options={dictionaryStore.eclTagAttributesOptions}
            value={tagAttributeClassOption}
            getOptionLabel={(option) => option.label}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(tagAttributeClassOption?.label) }}
            renderInput={(params) => (
              <TextField {...params} label="Tag Attribute Class" />
            )}
            onInputChange={(event, newInputValue) => {
              handleAttributeClassInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              handleAttributeClassChange(selectedValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Tag Attribute Value"
            value={tagAttributeValue}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(tagAttributeValue) }}
            onChange={(e) => setTagAttributeValue(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
        <Autocomplete
            size="small"
            id="UOM"
            options={dictionaryStore.uomOptions}
            value={uomOption}
            getOptionLabel={(option) => option.label}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            renderInput={(params) => (
              <TextField {...params} label="UOM" />
            )}
            onInputChange={(event, newInputValue) => {
              handleAttributeUOMInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              handleAttributeUOMChange(selectedValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />

        </Stack>
        </Box>
      </CustomTabPanel>
      </Box>
      }
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={masterAreaTagAttributesListStore.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </form>
      :
      null
  );
});

export default MasterAreaEditTagAttribute;
