import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { useEffect, useMemo, useState } from "react";
import { formatDateWithTime } from "../../../../utils/formatDate";
import formatBoolean from "../../../../utils/formatBoolean";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";
import { useStores } from "../../../../hooks/useStores";
import { NullableString } from "../../../../models/common/response/types";

const useMasterGridColumns = () => {
  
  const navigate = useNavigate();

  const { dictionaryStore } = useStores();

  const [categoryFilter, setCategoryFilter] = useState<NullableString[]>([]);
  const [eqTypeFilter, setEqTypeFilter] = useState<NullableString[]>([]);
  const [facilityFilter, setFacilityFilter] = useState<NullableString[]>([]);
  const [areaFilter, setAreaFilter] = useState<NullableString[]>([]);
  const [unitFilter, setUnitFilter] = useState<NullableString[]>([]);
  const [buildingFilter, setBuildingFilter] = useState<NullableString[]>([]);
  const [wellFilter, setWellFilter] = useState<NullableString[]>([]);
  const [pipeClassFilter, setPipeClassFilter] = useState<NullableString[]>([]);
  const [fluidFilter, setFluidFilter] = useState<NullableString[]>([]);
  const [locationFilter, setLocationFilter] = useState<NullableString[]>([]);
  const [substationFilter, setSubstationFilter] = useState<NullableString[]>([]);
  const [controlAutonamingFilter, setControlAutonamingFilter] = useState<NullableString[]>([]);

  useEffect(() => {
    (async () => {  
      await dictionaryStore.fetchTagCategoryOptions("");
      await dictionaryStore.fetchEqTypeOptions("");
      await dictionaryStore.fetchFacilityOptions("");
      await dictionaryStore.fetchAreaOptions("");
      await dictionaryStore.fetchUnitOptions("");
      await dictionaryStore.fetchBuildingOptions("");
      await dictionaryStore.fetchWellOptions("");  
      await dictionaryStore.fetchPipeClassesOptions("");
      await dictionaryStore.fetchFluidsOptions("");
      await dictionaryStore.fetchLocationOptions("");
      await dictionaryStore.fetchSubstationOptions("");

      setCategoryFilter(dictionaryStore.tagCategoryOptions.map(o => o.label));
      setEqTypeFilter(dictionaryStore.eqTypeOptions.map(o => o.label));
      setFacilityFilter(dictionaryStore.facilityOptions.map(o => o.label));
      setAreaFilter(dictionaryStore.areaOptions.map(o => o.label));
      setUnitFilter(dictionaryStore.unitOptions.map(o => o.label));
      setBuildingFilter(dictionaryStore.buildingOptions.map(o => o.label));
      setWellFilter(dictionaryStore.wellOptions.map(o => o.label));
      setPipeClassFilter(dictionaryStore.pipeClassesOptions.map(o => o.label));
      setFluidFilter(dictionaryStore.fluidOptions.map(o => o.label));
      setLocationFilter(dictionaryStore.locationOptions.map(o => o.label));
      setSubstationFilter(dictionaryStore.substationOptions.map(o => o.label));
      setControlAutonamingFilter(["Yes", "No"]);
    })();
  }, []); 

  const columns: GridColDef[] = useMemo(
    () => [
      { field: "createdOn", headerName: "Created On", flex: 1, minWidth: 120, valueFormatter: formatDateWithTime },
      { field: "createdBy", headerName: "Created by", flex: 1, minWidth: 200 },
      { field: "category", headerName: "Category", flex: 1, minWidth: 170, type: "singleSelect", valueOptions: categoryFilter },
      { field: "status", headerName: "Status", flex: 1, minWidth: 150 },
      { field: "controlAutonaming", headerName: "Control Autonaming", flex: 1, minWidth: 150, valueFormatter: formatBoolean, type: "singleSelect", valueOptions: controlAutonamingFilter },
      {
        field: "tagNumber",
        headerName: "Tag Number",
        flex: 1,
        minWidth: 250,
        renderCell: (params) => {
          return (
            <>
              <a href="javascript:void(0);" rel="noreferrer" onClick={() => navigate(ROUTES.masterAreaEditTag + "?id=" + params.row.id)}>
                {params.value}
              </a>
            </>
          );
        }
      },
      { field: "disciplineTagNumber", headerName: "Discipline Tag Number", flex: 1, minWidth: 250,
        renderCell: (params) => {
          return (
            <>
              <a href="javascript:void(0);" rel="noreferrer" onClick={() => navigate(ROUTES.masterAreaEditTag + "?id=" + params.row.disciplineTagNumberId)}>
                {params.value}
              </a>
            </>
          );
        }
      },
      { field: "primaryTagNumber", headerName: "Primary Tag Number", flex: 1, minWidth: 250,
        renderCell: (params) => {
          return (
            <>
              <a href="javascript:void(0);" rel="noreferrer" onClick={() => navigate(ROUTES.masterAreaEditTag + "?id=" + params.row.primaryTagNumberId)}>
                {params.value}
              </a>
            </>
          );
        }
      },
      { field: "parentTagNumber", headerName: "Parent Tag Number", flex: 1, minWidth: 180,
        renderCell: (params) => {
          return (
            <>
              <a href="javascript:void(0);" rel="noreferrer" onClick={() => navigate(ROUTES.masterAreaEditTag + "?id=" + params.row.parentTagNumberId)}>
                {params.value}
              </a>
            </>
          );
        }
      },
      { field: "facility", headerName: "Facility", flex: 1, minWidth: 120, type: "singleSelect", valueOptions: facilityFilter,
        renderCell: (params) => {
          return (
            <>
              <a href="javascript:void(0);" rel="noreferrer" onClick={() => navigate(ROUTES.referencesFacility + "?id=" + params.row.facilityId)}>
                {params.value}
              </a>
            </>
          );
        } },
      { field: "area", headerName: "Area", flex: 1, minWidth: 80, type: "singleSelect", valueOptions: areaFilter,
        renderCell: (params) => {
          return (
            <>
              <a href="javascript:void(0);" rel="noreferrer" onClick={() => navigate(ROUTES.referencesArea + "?id=" + params.row.areaId)}>
                {params.value}
              </a>
            </>
          );
        } },
      { field: "unit", headerName: "Unit", flex: 1, minWidth: 80, type: "singleSelect", valueOptions: unitFilter,
        renderCell: (params) => {
          return (
            <>
              <a href="javascript:void(0);" rel="noreferrer" onClick={() => navigate(ROUTES.referencesUnit + "?id=" + params.row.unitId)}>
                {params.value}
              </a>
            </>
          );
        } 
      },
      { field: "equipmentType", headerName: "Equipment Type", flex: 1, minWidth: 200, type: "singleSelect", valueOptions: eqTypeFilter },
      { field: "sequenceNumber", headerName: "Sequence Number", flex: 1, minWidth: 150 },
      { field: "suffix", headerName: "Suffix", flex: 1, minWidth: 50 },
      { field: "well", headerName: "Well", flex: 1, minWidth: 100, type: "singleSelect", valueOptions: wellFilter,
        renderCell: (params) => {
          return (
            <>
              <a href="javascript:void(0);" rel="noreferrer" onClick={() => navigate(ROUTES.referencesWell + "?id=" + params.row.wellId)}>
                {params.value}
              </a>
            </>
          );
        } 
      },
      { field: "substation", headerName: "Substation", flex: 1, minWidth: 100, type: "singleSelect", valueOptions: substationFilter,
        renderCell: (params) => {
          return (
            <>
              <a href="javascript:void(0);" rel="noreferrer" onClick={() => navigate(ROUTES.referencesSubstation + "?id=" + params.row.substationId)}>
                {params.value}
              </a>
            </>
          );
        } 
      },
      { field: "nominalDiameter", headerName: "Nominal Diameter", flex: 1, minWidth: 150 },
      { field: "pipeClass", headerName: "Pipe Class", flex: 1, minWidth: 100, type: "singleSelect", valueOptions: pipeClassFilter,
        renderCell: (params) => {
          return (
            <>
              <a href="javascript:void(0);" rel="noreferrer" onClick={() => navigate(ROUTES.referencesPipeClass + "?id=" + params.row.pipeClassId)}>
                {params.value}
              </a>
            </>
          );
        }
      },
      { field: "fluid", headerName: "Fluid", flex: 1, minWidth: 100, type: "singleSelect", valueOptions: fluidFilter,
        renderCell: (params) => {
          return (
            <>
              <a href="javascript:void(0);" rel="noreferrer" onClick={() => navigate(ROUTES.referencesFluid + "?id=" + params.row.fluidId)}>
                {params.value}
              </a>
            </>
          );
        }
      },
      { field: "location", headerName: "Location", flex: 1, minWidth: 100, type: "singleSelect", valueOptions: locationFilter,
        renderCell: (params) => {
          return (
            <>
              <a href="javascript:void(0);" rel="noreferrer" onClick={() => navigate(ROUTES.referencesLocation + "?id=" + params.row.locationId)}>
                {params.value}
              </a>
            </>
          );
        } 
      },
      { field: "building", headerName: "Building", flex: 1, minWidth: 100, type: "singleSelect", valueOptions: buildingFilter,
        renderCell: (params) => {
          return (
            <>
              <a href="javascript:void(0);" rel="noreferrer" onClick={() => navigate(ROUTES.referencesBuilding + "?id=" + params.row.buildingId)}>
                {params.value}
              </a>
            </>
          );
        } 
      },
      { field: "insulationType", headerName: "Insulation Type", flex: 1, minWidth: 120 },
      { field: "circuitNumber", headerName: "Circuit Number", flex: 1, minWidth: 120, },
      { field: "fittingNumber", headerName: "Fitting Number", flex: 1, minWidth: 120 },
      { field: "serviceDescription", headerName: "Service Description", flex: 1, minWidth: 150 },
      { field: "projectNumber", headerName: "Project Number", flex: 1, minWidth: 150,
        renderCell: (params) => {
          return (
            <>
              <a href="javascript:void(0);" rel="noreferrer" onClick={() => navigate(ROUTES.masterAreaEditProject + "?id=" + params.row.projectNumberId)}>
                {params.value}
              </a>
            </>
          );
        } 
      },
      { field: "requestNumber", headerName: "Request Number", flex: 1, minWidth: 150 },
      { field: "parallelTagNumber", headerName: "Parallel Tag Number", flex: 1, minWidth: 150 },
      { field: "mocNumber", headerName: "MOC Number", flex: 1, minWidth: 120, },
      { field: "remarks", headerName: "Remarks", flex: 1, minWidth: 250 },
      { field: "exInspectionRequired", headerName: "Ex Inspection Required", flex: 1, minWidth: 180 },
      { field: "exRegister", headerName: "Ex Register", flex: 1, minWidth: 150 },
      { field: "modifiedOn", headerName: "Modified On", flex: 1, minWidth: 120, valueFormatter: formatDateWithTime },
      { field: "modifiedBy", headerName: "Modified by", flex: 1, minWidth: 200 }   
    ],
    [categoryFilter, facilityFilter, areaFilter, unitFilter, eqTypeFilter, wellFilter, substationFilter, pipeClassFilter, fluidFilter, locationFilter, buildingFilter]
  );

  return columns;
};

export default useMasterGridColumns;
