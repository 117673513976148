import {
  GridToolbarContainer
} from "@mui/x-data-grid";

import { observer } from "mobx-react";
import AddAttribute from "./AddAttribute";
import EditAttribute from "./EditAttribute";
import DeleteAttribute from "./DeleteAttribute";
import ImportFromExcelAttributesButton from "./importFromExcel/ImportFromExcelAttributesButton";

const CustomToolBarMasterAreaAttributes = observer(() => {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", margin: "0 0 0 10px" }}>
        <GridToolbarContainer>
          <AddAttribute />
          <EditAttribute />
          <DeleteAttribute />
          <ImportFromExcelAttributesButton />      
        </GridToolbarContainer>
        </div>
    </div>
  );
});

export default CustomToolBarMasterAreaAttributes;
