import { FILTERS } from "../constants/filters";

export default function clearLocalStorage() {
    localStorage.setItem(FILTERS.areaId, "");
    localStorage.setItem(FILTERS.areaValue, "");
    localStorage.setItem(FILTERS.categoryId, "");
    localStorage.setItem(FILTERS.categoryValue, "");
    localStorage.setItem(FILTERS.facilityId, "");
    localStorage.setItem(FILTERS.facilityValue, "");
    localStorage.setItem(FILTERS.parentTagNumberId, "");
    localStorage.setItem(FILTERS.parentTagNumberValue, "");
    localStorage.setItem(FILTERS.projectNumberId, "");
    localStorage.setItem(FILTERS.projectNumberValue, "");
    localStorage.setItem(FILTERS.remarksValue, "");
    localStorage.setItem(FILTERS.requestNumberValue, "");
    localStorage.setItem(FILTERS.sequenceNumberValue, "");
    localStorage.setItem(FILTERS.serviceDescriptionValue, "");
    localStorage.setItem(FILTERS.statusId, "");
    localStorage.setItem(FILTERS.statusValue, "");
    localStorage.setItem(FILTERS.suffixValue, "");
    localStorage.setItem(FILTERS.tagNumberId, "");
    localStorage.setItem(FILTERS.tagNumberValue, "");
    localStorage.setItem(FILTERS.typeId, "");
    localStorage.setItem(FILTERS.typeValue, "");
    localStorage.setItem(FILTERS.unitId, "");
    localStorage.setItem(FILTERS.unitValue, "");
  }