import { Grid } from "@mui/material";
import { DataGridPro, GridColumnVisibilityModel, GridFilterModel, GridRowSelectionModel } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import usePageTitle from "../../../hooks/usePageTitle";
import CustomToolBarMasterArea from "./components/CustomToolBarMasterArea";
import useMasterGridColumns from "./hooks/useMasterGridColumns";
import useFetchData from "./hooks/useFetchData";
import CustomBulkUpdateFormMasterArea from "./components/CustomBulkUpdateFormMasterArea";
import { DataGridBox } from "../../../styles/dataGridBox";

const MasterAreaGridPage = observer(() => {
  usePageTitle("Master Area - Grid View");

  const columns = useMasterGridColumns();

  const { masterAssetsStore, masterAreaGridViewExportStore, masterAreaGridBulkUpdateStore } =
    useStores();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>();
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>();
  const [applyFilter, setApplyFilter] = useState<boolean>(false);

  useFetchData(setColumnVisibilityModel);

  useEffect(() => {   
      masterAssetsStore.fetchMasterAssets().then(() => {
        setApplyFilter(true);
      })
      masterAreaGridViewExportStore.setSelectedIds([]);
      masterAreaGridBulkUpdateStore.setSelectedIds([]);
  }, [
    masterAssetsStore.selectedSearchTextOption,
    masterAssetsStore.paginationModel,
    masterAssetsStore.selectedCategoryOption,
    masterAssetsStore.selectedTagNumberOption,
    masterAssetsStore.selectedProjectOption,
    masterAssetsStore.selectedAreaOption,
    masterAssetsStore.selectedFacilityOption,
    masterAssetsStore.selectedUnitOption,
    masterAssetsStore.selectedEquipmentTypeOption,    
    masterAssetsStore.selectedParentTagNumberOption,
    masterAssetsStore.selectedEquipmentStatusOption,
    masterAssetsStore.selectedServiceDescriptionOption,
    masterAssetsStore.selectedRequestNumberOption,
    masterAssetsStore.selectedSequenceNumberOption,
    masterAssetsStore.selectedSuffixOption,
    masterAssetsStore.selectedRemarksOption,
    masterAssetsStore.selectedTagTypeOption,
    masterAssetsStore,
    masterAreaGridViewExportStore,
    masterAreaGridBulkUpdateStore,
  ]);

  const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    masterAssetsStore.setFilterModel(filterModel);
    masterAssetsStore.fetchMasterAssets();
  }, []);

  return (
    <>
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item xs={12} md={12} sx={{ display: "flex", flexDirection: "column" }}>
            <DataGridBox>
              <CustomBulkUpdateFormMasterArea />
              {
                applyFilter ? 
                  <DataGridPro
                    //disableColumnFilter
                    paginationMode="server"
                    rows={masterAssetsStore.assetsResponse.items}
                    columns={columns}
                    rowCount={masterAssetsStore.assetsResponse.totalRecords}
                    loading={masterAssetsStore.isLoading}
                    pagination
                    paginationModel={masterAssetsStore.paginationModel}
                    pageSizeOptions={[masterAssetsStore.paginationModel.pageSize]}
                    onPaginationModelChange={masterAssetsStore.setPaginationModel}
                    sortingMode="server"
                    sortModel={masterAssetsStore.sortModel}
                    onSortModelChange={model => {masterAssetsStore.setSortModel(model); masterAssetsStore.fetchMasterAssets();}}
                    checkboxSelection
                    //keepNonExistentRowsSelected
                    onRowSelectionModelChange={(ids) => {
                      const selectedIds = new Set(ids);
                      masterAreaGridViewExportStore.setSelectedIds(
                        Array.from(selectedIds).map((id) => parseInt(id as string))
                      );

                      masterAreaGridBulkUpdateStore.setSelectedIds(
                        Array.from(selectedIds).map((id) => parseInt(id as string))
                      );

                      setRowSelectionModel(ids);
                    }}
                    rowSelectionModel={rowSelectionModel}
                    density="compact"
                    sx={{ fontSize: "0.75rem" }}
                    slots={{
                      toolbar: CustomToolBarMasterArea,
                    }}
                    slotProps={{ toolbar: {setRowSelectionModel: setRowSelectionModel, applyFilter: applyFilter} }}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                    filterMode="server"
                    onFilterModelChange={onFilterChange}
                  />
                : null
              }
            </DataGridBox>
          </Grid>
        </Grid>   
    </>
  );
});

export default MasterAreaGridPage;
