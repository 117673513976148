import { GridFilterModel, GridSortModel } from "@mui/x-data-grid";
import { makeAutoObservable, runInAction } from "mobx";
import { GetMasterAssetResponse } from "../../models/MasterArea/response/getMasterAssetsResponse";
import { SortOrder } from "../../models/common/request/sorting";
import {
  Filter,
  GetMasterAssetsRequest,
  MasterAssetsFilterOptions,
  MasterAssetsSortOptions,
} from "../../models/MasterArea/request/getMasterAssetsRequest";
import apiClient from "../../api/clients";
import { SelectOption } from "../../models/common/selectOption";
import { getOptionId, getOptionLabel } from "../../utils/getOptionValue";
import { CreateBBTagRequest } from "../../models/TagRequest/request/createBBTagRequest";
import { CheckDuplicateBBTag } from "../../models/TagRequest/request/checkDuplicateBBTag";
import { EditBBTagRequest } from "../../models/TagRequest/request/editBBTagRequest";
import { WorkingCopyBBTagRequest } from "../../models/TagRequest/request/workingCopyBBTagRequest";
import { GetMasterAssetDetailResponse } from "../../models/MasterArea/response/getAssetsDetailResponse";
import { GetSequenceValidationRequest } from "../../models/MasterArea/request/getSequenceValidationRequest";
import { GetSequenceValidationResponse } from "../../models/MasterArea/response/getSequenceValidationResponse";
import { UpdateCounterRequest } from "../../models/MasterArea/request/updateCounterRequest";

class MasterAssetsStore {
  bbTag: GetMasterAssetDetailResponse = {} as GetMasterAssetDetailResponse;
  nonDeletableTags: number[] = [];

  selectedCategoryOption: SelectOption | null = null;
  selectedTagNumberOption: SelectOption | null = null;
  selectedSearchTextOption: string | undefined = undefined;
  selectedProjectOption: SelectOption | null = null;
  selectedAreaOption: SelectOption | null = null;
  selectedUnitOption: SelectOption | null = null;
  selectedFacilityOption: SelectOption | null = null;
  selectedEquipmentTypeOption: SelectOption | null = null;
  selectedParentTagNumberOption: SelectOption | null = null;
  selectedEquipmentStatusOption: SelectOption | null = null;
  selectedTagTypeOption: SelectOption | null = null;
  selectedServiceDescriptionOption: string | undefined = undefined;
  selectedRequestNumberOption: string | undefined = undefined;
  selectedSequenceNumberOption: string | undefined = undefined;
  selectedSuffixOption: string | undefined = undefined;
  selectedRemarksOption: string | undefined = undefined;
  selectedParentTagOption: SelectOption | null = null;
  validateSequenceNumberMessage: GetSequenceValidationResponse = { isValid: true, errorMessage: "" };

  assetsResponse: GetMasterAssetResponse = {
    items: [],
    pageIndex: 0,
    pageSize: 0,
    totalPages: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    totalRecords: 0,
  };

  paginationModel = {
    pageSize: 250,
    page: 0,
  };
  sortModel: GridSortModel = [{field: "createdOn", sort: "desc"}];
  filterModel: GridFilterModel = { items: [] } ;
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setPaginationModel = (paginationModel: any) => {
    this.paginationModel = paginationModel;
  };

  setSortModel = (sortModel: any) => {
    this.sortModel = sortModel;
  };

  setFilterModel = (filterModel: any) => {
    this.filterModel = filterModel;
  };

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  setSelectedCategoryOption = (option: SelectOption | null) => {
    this.selectedCategoryOption = option;
  };

  setSelectedTagNumberOption = (option: SelectOption | null) => {
    this.selectedTagNumberOption = option;
  };

  setSelectedSearchTextOption = (option: string | undefined) => {
    this.selectedSearchTextOption = option;
  };

  setSelectedProjectOption = (option: SelectOption | null) => {
    this.selectedProjectOption = option;
  };

  setSelectedAreaOption = (option: SelectOption | null) => {
    this.selectedAreaOption = option;
  };

  setSelectedUnitOption = (option: SelectOption | null) => {
    this.selectedUnitOption = option;
  };

  setSelectedFacilityOption = (option: SelectOption | null) => {
    this.selectedFacilityOption = option;
  };

  setSelectedEquipmentTypeOption = (option: SelectOption | null) => {
    this.selectedEquipmentTypeOption = option;
  };

  setSelectedParentTagNumberOption = (option: SelectOption | null) => {
    this.selectedParentTagNumberOption = option;
  };

  setSelectedRequestNumberOption = (option: string | undefined) => {
    this.selectedRequestNumberOption = option;
  };

  setSelectedSequenceNumberOption = (option: string | undefined) => {
    this.selectedSequenceNumberOption = option;
  };

  setSelectedRemarksOption = (option: string | undefined) => {
    this.selectedRemarksOption = option;
  };

  setSelectedSuffixOption = (option: string | undefined) => {
    this.selectedSuffixOption = option;
  };

  setSelectedServiceDescriptionOption = (option: string | undefined) => {
    this.selectedServiceDescriptionOption = option;
  };

  setSelectedEquipmentStatusOption = (option: SelectOption | null) => {
    this.selectedEquipmentStatusOption = option;
  };

  setSelectedTagTypeOption = (option: SelectOption | null) => {
    this.selectedTagTypeOption = option;
  };

  setSelectedParentTagOption = (option: SelectOption | null) => {
    this.selectedParentTagOption = option;
  };

  setValidateSequenceNumberMessage = (value: GetSequenceValidationResponse) => {
    this.validateSequenceNumberMessage = value;
  };

  fetchMasterAssets = async () => {    
    const { page, pageSize } = this.paginationModel;

    const sortOptions: MasterAssetsSortOptions = {
      projectNumber: "projectNumber",
      tagNumber: "tagNumber",
      area: "area",
      facility: "facility",
      unit: "unit",
      status: "status",
      category: "category",
      serviceDescription: "serviceDescription",
      type: "type",
      parentTag: "parentTag",
      remarks: "remarks",
      requestNumber: "requestNumber",
      suffix: "suffix",
      sequenceNumber: 'sequenceNumber',
      disciplineTagNumber: 'disciplineTagNumber',
      createdBy: "createdBy",
      createdOn: "createdDate"
    };

    const filterOptions: MasterAssetsFilterOptions = {
      categoryId: getOptionId(this.selectedCategoryOption),
      tagNumber: getOptionLabel(this.selectedTagNumberOption),
      searchText: this.selectedSearchTextOption,
      projectNumberId: getOptionId(this.selectedProjectOption),
      equipmentStatusId: getOptionId(this.selectedEquipmentStatusOption),
      areaId: getOptionId(this.selectedAreaOption),
      facilityId: getOptionId(this.selectedFacilityOption),
      unitId: getOptionId(this.selectedUnitOption),
      equipmentTypeId: getOptionId(this.selectedEquipmentTypeOption),
      tagTypeId: getOptionId(this.selectedTagTypeOption),
      parentTagNumber: getOptionLabel(this.selectedParentTagNumberOption),
      requestNumber: this.selectedRequestNumberOption,
      sequenceNumber: this.selectedSequenceNumberOption,
      remarks: this.selectedRemarksOption,
      suffix: this.selectedSuffixOption,
      serviceDescription: this.selectedServiceDescriptionOption,
    };
    const sortField = this.sortModel?.[0]?.field;
    const sortOrder =
      this.sortModel?.[0]?.sort === "asc" ? SortOrder.ASC : SortOrder.DESC;
    const sortProperty = sortField
      ? sortOptions[sortField as keyof MasterAssetsSortOptions]
      : undefined;

    const sort = sortProperty
      ? {
          [sortProperty]: { sortOrder },
        }
      : undefined;

    const filter: Filter = Object.keys(filterOptions).reduce((acc, key) => {
      const filterValue = filterOptions[key as keyof MasterAssetsFilterOptions];
      if (filterValue !== undefined) {
        acc[key as keyof Filter] = { filterValue: filterValue as never };
      }
      return acc;
    }, {} as Filter);

    const dataGridFilter = this.filterModel;

    const request: GetMasterAssetsRequest = {
      pagination: { pageIndex: page, pageSize },
      filter,
      sort,
      dataGridFilter
    };

    try {
      this.setIsLoading(true);
      const data = await apiClient.getMasterAssets(request);
      runInAction(() => {
        this.assetsResponse = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

  fetchBBTagById = async(id: number): Promise<void> => {
    if (!id) return;
 
    try {
      const response = await apiClient.getMasterAssetDetail(id);
      runInAction(() => {
        this.bbTag = response;
      });
    } catch (error) {
      console.error("Error while getting tag request details:", error);
    }
  } 

  async createBBTag(request: CreateBBTagRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.createBBTag(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error creating tag request: ", error);
      throw error;
    }

    this.fetchMasterAssets();
  }

  async createBBTagNoClose(request: CreateBBTagRequest) : Promise<string> {
    try {
      this.setIsLoading(true);
      const bbTagResponse = await apiClient.createBBTag(request);
      const bbTagId = await bbTagResponse.json();
      this.setIsLoading(false);
      return bbTagId;
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error creating tag request: ", error);
      throw error;
    }
  }

  async checkDuplicateBBTag(request: CheckDuplicateBBTag) {
    try {
      this.setIsLoading(true);
      const response = await apiClient.checkDuplicateBBTag(request);
      const isDuplicate = await response.json();
      this.setIsLoading(false);
      return isDuplicate;
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error checking tag for duplicate: ", error);
      throw error;
    }
  }

  async validateSequenceNumber(request: GetSequenceValidationRequest) {
    try {
      this.setIsLoading(true);
      const response = await apiClient.validateSequenceNumber(request);
      this.setValidateSequenceNumberMessage(response);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error validating sequence number: ", error);
      throw error;
    }
  }

  async editBBTag(request: EditBBTagRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.editBBTag(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error edititng tag: ", error);
      throw error;
    }
    await this.fetchMasterAssets();
  }

  async editBBTagNoClose(request: EditBBTagRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.editBBTag(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error edititng tag: ", error);
      throw error;
    }
  }

  getNonDeletablePrimaryTags = async (ids: number[])  => {
    try {
      this.setIsLoading(true);
      this.nonDeletableTags = await apiClient.getNonDeletablePrimaryTags(ids);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error(error);
      throw error;
    }
  }

  deleteBBTagById = async (ids: number[])  => {
    try {
      this.setIsLoading(true);
      await apiClient.deleteBBTag(ids);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error(error);
      throw error;
    }
    await this.fetchMasterAssets();
  }

  workingCopyBBTag = async (request: WorkingCopyBBTagRequest)  => {
    try {
      this.setIsLoading(true);
      await apiClient.workingCopyBBTag(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error(error);
      throw error;
    }
  }

  updateCounter = async (request: UpdateCounterRequest)  => {
    try {
      this.setIsLoading(true);
      await apiClient.updateCounter(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error(error);
      throw error;
    }
  }

}

export default MasterAssetsStore;
