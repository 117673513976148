import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import ProjectAddEquipmentTagModal from "./ProjectAddEquipmentTagModal";

interface ProjectAddEquipmentTagProps {
  handleAddEquipmentTag: any;
}

const ProjectAddEquipmentTag = observer((props: ProjectAddEquipmentTagProps) => {
  const {
    masterAreaProjectsStore, dictionaryStore
  } = useStores();

  const handleAddClick = () => {
    dictionaryStore.fetchMasterTagNumberOptions("");
    masterAreaProjectsStore.setShowAddEquipmentTagModal(true);  
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<AddIcon />}
        onClick={handleAddClick}
      >
        <span>Add</span>
      </Button>
      <ProjectAddEquipmentTagModal handleAddEquipmentTag={props.handleAddEquipmentTag}/>
    </>
  );
});

export default ProjectAddEquipmentTag;
