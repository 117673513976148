import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { SelectOption } from "../../../../../models/common/selectOption";
import { DocumentEquipmentTag } from "../../../../../stores/MasterArea/MasterAreaDocumentsStore";
import { useState } from "react";

interface DocumentsAddEquipmentTagModalProps {
  handleAddEquipmentTag: any;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const DocumentsAddEquipmentTagModal = observer((props: DocumentsAddEquipmentTagModalProps) => {
  const {
    masterAreaDocumentsStore, dictionaryStore
  } = useStores();

  const [selectedEquipmentTags, setSelectedEquipmentTags] = useState<SelectOption[]>([]);

  const handleCancelClick = () => {
    setSelectedEquipmentTags([]);
    masterAreaDocumentsStore.setShowAddEquipmentTagModal(false);
  };

  const handleAddClick = () => {
    const equipmentTags: DocumentEquipmentTag[] = [];
    if(selectedEquipmentTags.length > 0) {
      for (const selectedEquipmentTag of selectedEquipmentTags)
      {
        const eqTag: DocumentEquipmentTag = {
          status: "new",
          id: selectedEquipmentTag.id,
          tagNumber: selectedEquipmentTag.label,
          category: "",
          createdBy: "",
          createdOn: null,
          controlAutonaming: null
        };
        equipmentTags.push(eqTag);
      }

      props.handleAddEquipmentTag(equipmentTags);

      setSelectedEquipmentTags([]);
      masterAreaDocumentsStore.setShowAddEquipmentTagModal(false);
    }; 
  }

  const handleEquipmentTagChange = (selectedValue: SelectOption[]) => {
    setSelectedEquipmentTags(selectedValue);
  };

  const debouncedHandleInputChange = useDebounce(500);
  const handleEquipmentTagInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchMasterTagNumberOptions(inputValue);
    });
  };

  return (
    <Modal
      open={masterAreaDocumentsStore.showAddEquipmentTagModal}
      onClose={handleCancelClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="add-tag-attributes-title"
          variant="h6"
          component="h2"
          sx={{ mb: 4 }}
        >
          Add Equipment Tag
        </Typography>
        <Box
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <Autocomplete
          size="small"
          id="EquipmentTag" 
          multiple        
          options={dictionaryStore.masterTagNumberOptions ?? []}
          value={selectedEquipmentTags}
          getOptionLabel={(option) => option.label}
          sx={{ width: "100%", mr: 1, mb: 2, display: "inline-flex" }}
          renderInput={(params) => (
            <TextField {...params} label="Equipment Tag" />
          )}
          onInputChange={(event, newInputValue) => {
            handleEquipmentTagInputChange(newInputValue);
          }}
          onChange={(event, selectedValue) => {
            handleEquipmentTagChange(selectedValue);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
            justifyContent: "flex-end",
          }}
        >
          <Button 
            variant="contained" 
            disabled={selectedEquipmentTags.length < 1} 
            sx={{ ml: 2 }} 
            onClick={handleAddClick}>
            <span>Add</span>
          </Button>
          <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCancelClick}>
            <span>Cancel</span>
          </Button>
        </Box>
      </Box>
      
    </Modal>
  );
});

export default DocumentsAddEquipmentTagModal;
