import { observer } from "mobx-react";
import ProjectDeleteEquipmentTag from "./ProjectDeleteEquipmentTag";
import ProjectAddEquipmentTag from "./ProjectAddEquipmentTag";
import { GridToolbarContainer } from "@mui/x-data-grid";

interface ProjectEquipmentTagsToolBarProps {
  handleAddEquipmentTag: any;
  handleDeleteEquipmentTag: any;
}

const ProjectEquipmentTagsToolBar = observer((props: ProjectEquipmentTagsToolBarProps) => {
  return (
    <GridToolbarContainer>
      {<ProjectAddEquipmentTag handleAddEquipmentTag={props.handleAddEquipmentTag} />}
      {<ProjectDeleteEquipmentTag handleDeleteEquipmentTag={props.handleDeleteEquipmentTag}/>}
    </GridToolbarContainer>
  );
});

export default ProjectEquipmentTagsToolBar;
