import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import { Autocomplete, Box, Button, Modal, TextField, Typography } from "@mui/material";
import { useRef } from "react";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { CreateRelatedTagRequest } from "../../../../../models/ProjectArea/request/createRelatedTagRequest";
import { NOTIFICATION_TYPES } from "../../../../../constants/notificationTypes";
import { SelectOption } from "../../../../../models/common/selectOption";
import { RelationType } from "../../../../../models/common/response/relationType";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const TagDetailsAddRelatedTagModal = observer(() => {
  const {
    projectAreaCardViewRelatedTagStore: relatedTagStore,
    projectAreaCardViewStore: { fetchRelatedTagsById, assetDetail, fetchAssetById },
    notificationStore,
    dictionaryStore,
  } = useStores();

  const equipmentTagRef = useRef<HTMLInputElement>(null);

  const handleCancelClick = () => {
    relatedTagStore.setShowAddRelatedTagModal(false);
  };

  const handleSaveClick = () => {
    const projectEquipmentId = assetDetail?.id;
    const relatedTagId = relatedTagStore.selectedRelatedTag
      ? relatedTagStore.selectedRelatedTag.id
      : 0;

    const relationType = relatedTagStore.selectedRelationType
      ? relatedTagStore.selectedRelationType.id
      : 0;

    const subType =
      relationType === RelationType.Parent || relationType === RelationType.From ? 1 : 2;

    const data: CreateRelatedTagRequest = {
      projectEquipmentId: projectEquipmentId!,
      relatedTagId: relatedTagId,
      relationType: relatedTagStore.selectedRelationType
        ? relatedTagStore.selectedRelationType.id
        : 0,
      subType: subType,
      projectId: assetDetail?.projectId!
    };

    relatedTagStore.createRelatedTag(data).then(() => {
      if (relatedTagStore.validationErrors) {
        notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
        notificationStore.setNotificationMessage(relatedTagStore.validationErrors);
        notificationStore.setShowNotification(true);
      } else {
        notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
        notificationStore.setNotificationMessage("Related Tag added successfully");
        notificationStore.setShowNotification(true);
      }
      fetchRelatedTagsById(assetDetail?.id!, assetDetail?.projectId!);
      fetchAssetById(assetDetail?.id!);
    });
  };

  const handleRelationTypeChange = (selectedValue: SelectOption | null) => {
    relatedTagStore.setSelectedRelationType(selectedValue);
  };

  const handleRelatedTagChange = (selectedValue: SelectOption | null) => {
    relatedTagStore.setSelectedRelatedTag(selectedValue);
  };

  const debouncedHandleInputChange = useDebounce(500);
  const handleRelatedTagInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchProjectTagNumberCategoryOptions(inputValue);
    });
  };

  const relationTypeOptions: SelectOption[] = [];
  for (let relation in RelationType) {
    let isValueProperty =
      Number(relation) === 1 ||
      Number(relation) === 2 ||
      Number(relation) === 3 ||
      Number(relation) === 4 ||
      Number(relation) === 5 ||
      Number(relation) === 6 ||
      Number(relation) === 7;
    if (isValueProperty) {
      relationTypeOptions.push({
        id: Number(relation),
        label: RelationType[relation],
      });
    }
  }

  return (
    <Modal
      open={relatedTagStore.showAddRelatedTagModal}
      onClose={handleCancelClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="add-tag-attributes-title"
          variant="h6"
          component="h2"
          sx={{ mb: 4 }}
        >
          Add Related Tag
        </Typography>
        <Box
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <TextField
            variant="outlined"
            size="small"
            label="Equipment Tag"
            disabled
            value={assetDetail?.tagNumber}
            inputRef={equipmentTagRef}
            sx={{ mr: 1, mb: 2, width: "100%" }}
          />
          <Autocomplete
            size="small"
            id="RelatedTag"
            options={dictionaryStore.projectTagNumberCategoryOptions ?? []}
            value={relatedTagStore.selectedRelatedTag}
            getOptionLabel={(option) => option.label}
            sx={{ width: "100%", mr: 1, mb: 2, display: "inline-flex" }}
            renderInput={(params) => <TextField {...params} label="Related Tag" />}
            onInputChange={(event, newInputValue) => {
              handleRelatedTagInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              handleRelatedTagChange(selectedValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <Autocomplete
            size="small"
            id="RelationType"
            options={relationTypeOptions}
            value={relatedTagStore.selectedRelationType}
            getOptionLabel={(option) => option.label}
            sx={{ width: "100%", mr: 1, mb: 2 }}
            renderInput={(params) => <TextField {...params} label="Relation Type" />}
            onChange={(event, selectedValue) => {
              handleRelationTypeChange(selectedValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            disabled={
              !relatedTagStore.selectedRelationType || !relatedTagStore.selectedRelatedTag
            }
            sx={{ ml: 2 }}
            onClick={handleSaveClick}
          >
            <span>Save</span>
          </Button>
          <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCancelClick}>
            <span>Cancel</span>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});

export default TagDetailsAddRelatedTagModal;
