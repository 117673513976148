import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import {
  Box,
  Button,
  TextField,
  Stack,
  Typography,
  Backdrop,
  CircularProgress
} from "@mui/material";
import { useState, useEffect } from "react";
import usePageTitle from "../../../hooks/usePageTitle";
import { areaboxStyle} from "../../../constants/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { NOTIFICATION_TYPES } from "../../../constants/notificationTypes";
import { NullableString } from "../../../models/common/response/types";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { UpdateWellRequest } from "../../../models/Dictionaries/request/updateWellRequest";
import { validationColor } from "../../../utils/validation";

const WellItem = observer(() => {
  usePageTitle("References - Well");

  const navigate = useNavigate();

  const [urlParams] = useSearchParams()

  const { referencesStore, notificationStore } = useStores();
  
  const [wellId, setWellId] = useState<number>(0);
  const [wellShort, setWellShort] = useState<NullableString>("");
  const [wellCode, setWellCode] = useState<NullableString>("");
  const [wellRemarks, setWellRemarks] = useState<NullableString>("");

 
  useEffect(() => {
    (async () => {   
      await initWell();
    })()
  }, []);

  const initWell = async () => {

    const idParam = parseInt(urlParams.get("id") ?? "0");
    await referencesStore.fetchWellById(idParam);

    setWellId(idParam);
    setWellShort(referencesStore.well?.wellShort ?? "");
    setWellCode(referencesStore.well?.wellCode ?? "");
    setWellRemarks(referencesStore.well?.wellRemarks ?? "");
  }
  
  const handleCloseClick = () => {
    navigate(-1);
  };

  const handleSaveClick = async () => {
    try {
      const wellItem: UpdateWellRequest = {
        id: wellId,
        wellCode: wellCode,
        wellShort: wellShort,
        wellRemarks: wellRemarks
      }
      await referencesStore.updateWell(wellItem);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Well edited successfully");
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error editing well: " + error);
    }
    finally {
      notificationStore.setShowNotification(true);
      navigate(ROUTES.referencesWells);
    }
  }

  return (
    referencesStore.well ?
    <form>
    <Box
          sx={ areaboxStyle }
        > 
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "#fff",
              display: { xs: "block", md: "flex", width: "50%" }
            }}
          >
            <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
              <ArrowBackOutlinedIcon />
            </Button>
            <Button 
              variant="contained" 
              sx={{ ml: 2 }} 
              disabled={!wellShort || !wellCode}
              onClick={() => handleSaveClick()}>
              <span>Save</span>
            </Button> 
          </Box>
        </Stack>
      </Box>
    {    
    <Box sx={{ "overflow-y": "scroll", height: "80vh" }}> 
      <Box
          sx={ areaboxStyle }
        >  
        <Typography
            id="add-tag-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
          Well
        </Typography>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Well"
            value={wellShort}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(wellShort) }}
            onChange={(e) => setWellShort(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Well Code"
            value={wellCode}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(wellCode) }}
            onChange={(e) => setWellCode(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Well Remarks"
            value={wellRemarks}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            onChange={(e) => setWellRemarks(e.target.value)}
          />
        </Stack>
        </Box> 
      </Box>
      }
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={referencesStore.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </form>
      :
      null
  );
});

export default WellItem;
