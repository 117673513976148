export default function formatDate(params: any) {
  if (!params.value) return "";
  const date = new Date(params.value);
  return date.toISOString().slice(0, 10);
}

export function formatDateWithTime(params: any) {
  if (!params.value) return "";
  const date = new Date(params.value);
  const timeZone = date.getTimezoneOffset() / -60;
  date.setHours(date.getHours() + timeZone);
  return date.toISOString().slice(0, 19).replace("T", " ");
}

export function formatDBDate(date: Date) {
  return date.toString().slice(0, 10);
}