import { observer } from "mobx-react";
import { useState } from "react";
import { useStores } from "../../../../../hooks/useStores";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DataGridBox } from "../../../../../styles/dataGridBox";
import DocumentEquipmentTagsToolBar from "./ProjectEquipmentTagsToolBar";
import { ProjectEquipmentTag } from "../../../../../stores/MasterArea/MasterAreaProjectsStore";
import { formatDateWithTime } from "../../../../../utils/formatDate";

const columns: GridColDef<ProjectEquipmentTag>[] = [
  { field: "tagNumber", headerName: "Tag Number", flex: 1, minWidth: 180 },
  { field: "createdOn", headerName: "Created On", flex: 1, minWidth: 200, valueFormatter: formatDateWithTime },
  { field: "createdBy", headerName: "Created By", flex: 1, minWidth: 200 },
  { field: "category", headerName: "Category", flex: 1, minWidth: 150 },
  { field: "status", headerName: "Status", flex: 1, minWidth: 150 },
  { field: "controlAutonaming", headerName: "Control Autonaming", flex: 1, minWidth: 150 }
];

interface EquipmentTagsTabProps {
  Toolbar: boolean;
}

const EquipmentTagsTab = observer((props: EquipmentTagsTabProps) => {
  const {
    masterAreaProjectsStore
  } = useStores();

  const [equipmentTags, setEquipmentTags] = useState<ProjectEquipmentTag[]>(masterAreaProjectsStore.equipmentTags);

  const handleAddEquipmentTag = (eTag: ProjectEquipmentTag) => {
    if (masterAreaProjectsStore.equipmentTags?.filter(e => eTag.id === e.id).length === 0 ) {
      const eqTags = Object.assign([], equipmentTags);
      eqTags.push(eTag);
      setEquipmentTags([...eqTags]);
      masterAreaProjectsStore.setEquipmentTags(eqTags);
    }
  };

  const handleDeleteEquipmentTag = () => {
    const eqTags = masterAreaProjectsStore.equipmentTags.filter(eq => masterAreaProjectsStore.equipmentTagsSelectedIds.indexOf(eq.id) < 0 );
    setEquipmentTags([...eqTags]);
    masterAreaProjectsStore.setEquipmentTags(eqTags);
  };

  return (
    <DataGridBox>
        <DataGrid 
          columns={columns} 
          rows={equipmentTags ?? []} 
          getRowId={(row) => row.id} 
          density="comfortable"
          checkboxSelection
          onRowSelectionModelChange={(ids) => {
            const selectedIds = new Set(ids);
            masterAreaProjectsStore.setEquipmentTagsSelectedIds(
              Array.from(selectedIds).map((id) => parseInt(id as string))
            );
          }}
          slots={{
            toolbar: props.Toolbar ? DocumentEquipmentTagsToolBar : null,
          }}
          slotProps={{ toolbar: { handleAddEquipmentTag: handleAddEquipmentTag, handleDeleteEquipmentTag: handleDeleteEquipmentTag } }}
          />
      </DataGridBox>
  );
});

export default EquipmentTagsTab;
