import { observer } from "mobx-react";
import { GridColDef } from "@mui/x-data-grid";
import { DataGridBox } from "../../../../styles/dataGridBox";
import { useStores } from "../../../../hooks/useStores";
import { formatDateWithTime } from "../../../../utils/formatDate";
import { AssetDetailHistory } from "../../../../models/ProjectArea/response/getAssetsDetailResponse";
import { DataGridPro } from "@mui/x-data-grid-pro";

const columns: GridColDef<AssetDetailHistory>[] = [
  {
    field: "changedOn",
    headerName: "Changed Date",
    flex: 1,
    minWidth: 180,
    valueFormatter: formatDateWithTime,
  },
  { field: "changedBy", headerName: "Changed By", flex: 1, minWidth: 180 },
  { field: "action", headerName: "Event", flex: 1, minWidth: 180 },
  { field: "changedField", headerName: "Changed Field", flex: 1, minWidth: 200 },
  { field: "oldValue", headerName: "Old Value", flex: 1, minWidth: 200 },
  { field: "newValue", headerName: "New Value", flex: 1, minWidth: 200 },
];

const TagDetailHistoryTab = observer(() => {
  let id = 0;

  const {
    projectAreaCardViewStore: { history },
  } = useStores();

  return (
    <DataGridBox>
      <DataGridPro
        columns={columns}
        rows={history ?? []}
        getRowId={(row) => id++}
        density="comfortable"
      />
    </DataGridBox>
  );
});

export default TagDetailHistoryTab;
