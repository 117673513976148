import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import { Box, Button, TextField, Stack, Typography, Backdrop, CircularProgress, FormControlLabel, Checkbox, Autocomplete } from "@mui/material";
import { useState, useEffect } from "react";
import { useDebounce } from "../../../hooks/useDebounce";
import usePageTitle from "../../../hooks/usePageTitle";
import { areaboxStyle} from "../../../constants/styles";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { NOTIFICATION_TYPES } from "../../../constants/notificationTypes";
import { NullableString } from "../../../models/common/response/types";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { CreateAreaRequest } from "../../../models/Dictionaries/request/createAreaRequest";
import { SelectOption } from "../../../models/common/selectOption";
import { validationColor } from "../../../utils/validation";

const AreaItemCreate = observer(() => {
  usePageTitle("References - New Area");

  const navigate = useNavigate();

  const { referencesStore, notificationStore, dictionaryStore } = useStores();
  
  const [area, setArea] = useState<NullableString>("");
  const [areaCode, setAreaCode] = useState<NullableString>("");
  const [areaDescription, setAreaDescription] = useState<NullableString>("");
  const [areaRemarks, setAreaRemarks] = useState<NullableString>("");
  const [areaNotForInstrumentation, setAreaNotForInstrumentation] = useState<boolean>(false);
  const [areaForInstrumentation, setAreaForInstrumentation] = useState<boolean>(false);
  const [areaRelatedCode, setAreaRelatedCode] = useState<NullableString>("");
  const [facilityOption, setFacilityOption] = useState<SelectOption | null>(null);

  useEffect(() => {
      dictionaryStore.fetchFacilityOptions("");
  }, []);
  
  const debouncedHandleInputChange = useDebounce(500);
  const handleFacilityInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchFacilityOptions(inputValue);
    });
  };

  const handleCloseClick = () => {
    navigate(ROUTES.referencesAreas);
  };

  const handleSaveClick = async () => {
    try {
      const areaItem: CreateAreaRequest = {
        area: area,
        areaCode: areaCode,
        areaDescription: areaDescription,
        areaForInstrumentation: areaForInstrumentation,
        areaNotForInstrumentation: areaNotForInstrumentation,
        relatedAreaCode: areaRelatedCode,
        areaRemarks: areaRemarks,
        facilityId: facilityOption?.id
      }
      await referencesStore.createArea(areaItem);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Area created successfully");
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error creating area: " + error);
    }
    finally {
      notificationStore.setShowNotification(true);
      navigate(ROUTES.referencesAreas);
    }
  }

  return (
    <form>
    <Box
          sx={ areaboxStyle }
        > 
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "#fff",
              display: { xs: "block", md: "flex", width: "50%" }
            }}
          >
            <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
              <ArrowBackOutlinedIcon />
            </Button>
            <Button 
              variant="contained" 
              sx={{ ml: 2 }} 
              onClick={() => handleSaveClick()}
              disabled={!area || !areaCode || !areaDescription || !facilityOption}
              >
              <span>Save</span>
            </Button> 
          </Box>
        </Stack>
      </Box>
    {    
    <Box sx={{ "overflow-y": "scroll", height: "80vh" }}> 
      <Box
          sx={ areaboxStyle }
        >  
        <Typography
            id="add-tag-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
          New Area
        </Typography>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Area"
            value={area}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(area) }}
            onChange={(e) => setArea(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Area Code"
            value={areaCode}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(areaCode) }}
            onChange={(e) => setAreaCode(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
        <Autocomplete
              disablePortal
              size="small"
              id="facility"
              options={dictionaryStore.facilityOptions}
              value={facilityOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%" }}
              renderInput={(params) => <TextField {...params} label="Facility" sx={{ "background-color": validationColor(facilityOption?.label) }} />}
              onInputChange={(event, newInputValue) => {
                handleFacilityInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setFacilityOption(selectedValue);
                handleFacilityInputChange("");
              }}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
            />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Area Description"
            value={areaDescription}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(areaDescription) }}
            onChange={(e) => setAreaDescription(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Area Remarks"
            value={areaRemarks}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            onChange={(e) => setAreaRemarks(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Related Area Code"
            value={areaRelatedCode}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            onChange={(e) => setAreaRelatedCode(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
        <FormControlLabel control={
              <Checkbox defaultChecked={false} onChange={(e) => setAreaForInstrumentation(e.target.checked) } />
            } label="Area For Instrumentation"  sx={{ width: "50%" }} 
          /> 
        </Stack>
        <Stack direction="row">
        <FormControlLabel control={
              <Checkbox defaultChecked={false} onChange={(e) => setAreaNotForInstrumentation(e.target.checked) } />
            } label="Area Not For Instrumentation"  sx={{ width: "50%" }} 
          /> 
        </Stack>
        </Box> 
      </Box>
      }
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={referencesStore.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </form>
  );
});

export default AreaItemCreate;
