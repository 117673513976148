export const areaboxStyle = {
    margin: "0 0 20px 0", 
    padding: "10px", 
    background: "#fff", 
    borderRadius: "10px"
  }

  export const areaboxStyleNoMargin = {
    padding: "10px", 
    background: "#fff", 
    borderRadius: "10px"
  }

export const areaboxStyleFlex = {
    margin: "0 0 20px 0", 
    padding: "10px", 
    background: "#fff", 
    borderRadius: "10px",
    display: "flex"
  }