import React, { useEffect, useState } from 'react'
import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import { useDebounce } from "../../../../hooks/useDebounce";
import {  Autocomplete, TextField } from "@mui/material";
import { SelectOption } from '../../../../models/common/selectOption';
import { FILTERS } from '../../../../constants/filters';

interface MasterAreaCategoryFilterProps {
  applyFilter: boolean;
}

const MasterAreaCategoryFilter = observer((props: MasterAreaCategoryFilterProps) => {
  const { masterAssetsStore, dictionaryStore, masterAreaGridViewExportStore } = useStores();

  useEffect(() => {
    const selectedOptionId = localStorage.getItem(FILTERS.categoryId) || 0;
    const selectedOptionValue = localStorage.getItem(FILTERS.categoryValue) || "";
    if(selectedOptionId && selectedOptionValue && selectedOptionId != "undefined" && selectedOptionValue != "undefined") {
      const selectedOption: SelectOption = {id: parseInt(selectedOptionId), label: selectedOptionValue};
      masterAssetsStore.setSelectedCategoryOption(selectedOption);
      masterAreaGridViewExportStore.setSelectedTagCategoryOption(selectedOption);
    }
  }, [props.applyFilter]);

  const debouncedHandleInputChange = useDebounce(500);

  const handleTagCategoryInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchTagCategoryOptions(inputValue);
    });
  };

  return (
    <>
      <Autocomplete
            size="small"
            id="category"
            options={dictionaryStore.tagCategoryOptions}
            value={masterAssetsStore.selectedCategoryOption}
            getOptionLabel={(option) => option.label}
            sx={{ width: 300, mr: 1, mb: 1 }}
            renderInput={(params) => <TextField {...params} label="All Equipment Tags" variant="standard" />}
            onInputChange={(event, newInputValue) => {
              handleTagCategoryInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              masterAssetsStore.setSelectedCategoryOption(selectedValue);
              masterAreaGridViewExportStore.setSelectedTagCategoryOption(selectedValue);
              localStorage.setItem(FILTERS.categoryId, selectedValue?.id);
              localStorage.setItem(FILTERS.categoryValue, selectedValue?.label);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
    </>
  );
});

export default MasterAreaCategoryFilter;
