import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import {
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  Backdrop
} from "@mui/material";
import { useState, useEffect } from "react";
import usePageTitle from "../../../hooks/usePageTitle";
import { areaboxStyle} from "../../../constants/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { NOTIFICATION_TYPES } from "../../../constants/notificationTypes";
import { NullableString } from "../../../models/common/response/types";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { UpdateUnitRequest } from "../../../models/Dictionaries/request/updateUnitRequest";
import { CircleOutlined } from "@mui/icons-material";
import { validationColor } from "../../../utils/validation";

const UnitItem = observer(() => {
  usePageTitle("References - Unit");

  const navigate = useNavigate();

  const [urlParams] = useSearchParams()

  const { referencesStore, notificationStore } = useStores();
  
  const [unitId, setUnitId] = useState<number>(0);
  const [unit, setUnit] = useState<NullableString>("");
  const [unitCode, setUnitCode] = useState<NullableString>("");
  const [unitDescription, setUnitDescription] = useState<NullableString>("");
  const [unitRemarks, setUnitRemarks] = useState<NullableString>("");
  const [relatedUnit, setRelatedUnit] = useState<NullableString>("");
  const [unitShortCode, setUnitShortCode] = useState<NullableString>("");
  const [unitNotForInstrumentationDiscipline, setUnitNotForInstrumentationDiscipline] = useState<boolean>(false);
  const [unitNotForElectricalDiscipline, setUnitNotForElectricalDiscipline] = useState<boolean>(false);

 
  useEffect(() => {
    (async () => {   
      await initUnit();
    })()
  }, []);

  const initUnit = async () => {

    const idParam = parseInt(urlParams.get("id") ?? "0");
    await referencesStore.fetchUnitById(idParam);

    setUnitId(idParam);
    setUnit(referencesStore.unit?.unit ?? "");
    setUnitCode(referencesStore.unit?.unitCode ?? "");
    setUnitDescription(referencesStore.unit?.unitDescription ?? "");
    setUnitRemarks(referencesStore.unit?.unitRemarks ?? "");
    setRelatedUnit(referencesStore.unit?.relatedUnit ?? "");
    setUnitShortCode(referencesStore.unit?.unitShortCode ?? "");
    setUnitNotForInstrumentationDiscipline(referencesStore.unit?.unitNotForInstrumentationDiscipline ?? false);
    setUnitNotForElectricalDiscipline(referencesStore.unit?.unitNotForElectricalDiscipline ?? false);
  }
  
  const handleCloseClick = () => {
    navigate(-1);
  };

  const handleSaveClick = async () => {
    try {
      const unitItem: UpdateUnitRequest = {
        id: unitId,
        unit: unit,
        unitCode: unitCode,
        unitDescription: unitDescription,
        unitRemarks: unitRemarks,
        relatedUnit: relatedUnit,
        unitShortCode: unitShortCode,
        unitNotForInstrumentationDiscipline: unitNotForInstrumentationDiscipline,
        unitNotForElectricalDiscipline: unitNotForElectricalDiscipline
      }
      await referencesStore.updateUnit(unitItem);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Unit edited successfully");
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error editing unit: " + error);
    }
    finally {
      notificationStore.setShowNotification(true);
      navigate(ROUTES.referencesUnits);
    }
  }

  return (
    referencesStore.unit ?
    <form>
    <Box
          sx={ areaboxStyle }
        > 
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "#fff",
              display: { xs: "block", md: "flex", width: "50%" }
            }}
          >
            <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
              <ArrowBackOutlinedIcon />
            </Button>
            <Button 
              variant="contained" 
              sx={{ ml: 2 }} 
              disabled={!unit || !unitCode || !unitShortCode || !unitDescription}
              onClick={() => handleSaveClick()}>
              <span>Save</span>
            </Button> 
          </Box>
        </Stack>
      </Box>
    {    
    <Box sx={{ "overflow-y": "scroll", height: "80vh" }}> 
      <Box
          sx={ areaboxStyle }
        >  
         <Typography
            id="add-tag-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
          Unit
          </Typography> 
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Unit"
            value={unit}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(unit) }}
            onChange={(e) => setUnit(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Unit Code"
            value={unitCode}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(unitCode) }}
            onChange={(e) => setUnitCode(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Unit Short Code"
            value={unitShortCode}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(unitShortCode) }}
            onChange={(e) => setUnitShortCode(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Unit Description"
            value={unitDescription}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(unitDescription) }}
            onChange={(e) => setUnitDescription(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Unit Remarks"
            value={unitRemarks}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            onChange={(e) => setUnitRemarks(e.target.value)}
          />
        </Stack>
        </Box> 
      </Box>
      }
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={referencesStore.isLoading}>
        <CircleOutlined color="inherit" />
      </Backdrop>
      </form>
      :
      null
  );
});

export default UnitItem;
