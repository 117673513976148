import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";

interface DocumentsDeleteEquipmentTagProps {
  handleDeleteEquipmentTag: any;
}

const DocumentsDeleteEquipmentTag = observer((props: DocumentsDeleteEquipmentTagProps) => {
  const {
    masterAreaDocumentsStore
  } = useStores();

  const handleDeleteClick = async () => {
    props.handleDeleteEquipmentTag();
  };

  return (
    <Button
      variant="outlined"
      size="small"
      startIcon={<DeleteIcon />}
      disabled={masterAreaDocumentsStore.equipmentTagsSelectedIds.length < 1}
      onClick={handleDeleteClick}
    >
      Delete
    </Button>
  );
});

export default DocumentsDeleteEquipmentTag;
