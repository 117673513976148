import { makeAutoObservable, runInAction } from "mobx";
import { GetMasterAreaTagAttributesResponse } from "../../models/MasterArea/response/getMasterAreaTagAttributesResponse";
import { GetMasterAreaTagAttributeByIdResponse } from "../../models/MasterArea/response/getMasterAreaTagAttributeByIdResponse";
import { GetMasterAreaTagAttributesRequest } from "../../models/MasterArea/request/getMasterAreaTagAttributesRequest";
import { GetMasterAreaTagAttributesUpdateRequest } from "../../models/MasterArea/request/getMasterAreaTagAttributesUpdateRequest";
import apiClient from "../../api/clients";
import { GetMasterAreaTagAttributesCreateRequest } from "../../models/MasterArea/request/getMasterAreaTagAttributesCreateRequest";

class MasterAreaTagAttributesListStore {
  tagAttributesResponse: GetMasterAreaTagAttributesResponse = {
    items: [],
    pageIndex: 0,
    pageSize: 0,
    totalPages: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    totalRecords: 0,
  };

  tagAttributeResponse: GetMasterAreaTagAttributeByIdResponse | null = null;

  selectedIds: number[] = [];

  paginationModel = {
    pageSize: 250,
    page: 0,
  };
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedIds = (ids: number[]) => {
    this.selectedIds = ids;
  };

  setPaginationModel = (paginationModel: any) => {
    this.paginationModel = paginationModel;
  };

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  fetchTagAttributes = async () => {
    const { page, pageSize } = this.paginationModel;

    const request: GetMasterAreaTagAttributesRequest = {
      pagination: { pageIndex: page, pageSize },
    };

    try {
      this.setIsLoading(true);
      const data = await apiClient.getMasterAreaTagAttributes(request);
      runInAction(() => {
        this.tagAttributesResponse = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  };

  fetchTagAttributeById = async (id: number) => {
    try {
      this.setIsLoading(true);
      const data = await apiClient.getMasterAreaTagAttributeById(id);
      runInAction(() => {
        this.tagAttributeResponse = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  };

  async createTagAttribute(
    request: GetMasterAreaTagAttributesCreateRequest
  ) {
    try {
      this.setIsLoading(true);
      await apiClient.createMasterAreaTagAttribute(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error creating tag attribute: ", error);
      throw error;
    }
  }

  async updateTagAttributeById(
    request: GetMasterAreaTagAttributesUpdateRequest
  ) {
    try {
      this.setIsLoading(true);
      await apiClient.updateMasterAreaTagAttributeById(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error editing tag attribute: ", error);
      throw error;
    }
  }

  /** @author Kanat Bektursyn
   *
   *  @description This method deletes values in selectedIds.
   *  @description After deletion it refetches new list of Tag Attributes.
   *
   *  On error it throws the received error.
   *  This is needed for useAttributesFetchRequests,
   *  as later Error is sent to NotificationsStore,
   *  same does Success is sent to NotificationsStore
   *   _______
   *  | Note:
   *  | Resetting *this.selectedIds* is extremely important
   *  | otherwise that can lead to side-effects
   *  |_______
   */
  async deleteMasterTagAttributesByIds() {
    try {
      const response = await apiClient.deleteMasterTagAttributesByIds(
        this.selectedIds
      );

      if (response.ok) {
        await this.fetchTagAttributes();
        this.setSelectedIds([]);
      } else {
        throw Error("Failed deleting given attributes");
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default MasterAreaTagAttributesListStore;
