import {
  Autocomplete,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import PermissionButton from "../../../../../components/common/PermissionButton";
import { useStores } from "../../../../../hooks/useStores";
import { SelectOption } from "../../../../../models/common/selectOption";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { observer } from "mobx-react";
import { MasterDocuments } from "../../../../../models/MasterArea/response/getAssetsDetailResponse";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

interface TagDetailAddDocumentModalProps {
  Documents: MasterDocuments[];
  handleAddDocuments: any;
}

const TagDetailAddDocumentModal = observer((props: TagDetailAddDocumentModalProps) => {
  const {
    masterAreaCardViewDocumentStore
  } = useStores();

  const handleCancelClick = () => {
    masterAreaCardViewDocumentStore.setShowAddDocumentModal(false);
    masterAreaCardViewDocumentStore.setShowCreateDocumentAlert(false);
  };

  const handleAddClick = async () => {
    const masterDocuments: MasterDocuments[] = [];
    for (const selectedMasterDocument of masterAreaCardViewDocumentStore.selectedMasterDocOption)
    {
      const masterDocument = await masterAreaCardViewDocumentStore.getMasterDocDetails(selectedMasterDocument?.id);
      if(masterDocument != null) {
        const document: MasterDocuments = {
          documentId: masterDocument.id,
          documentCode: masterDocument.documentNumber,
          documentType: masterDocument.documentType,
          documentUrl: masterDocument.documentUrl,
          createdOn: new Date(masterDocument.createdOn),
          createdBy: masterDocument.createdBy,
          modifiedOn: new Date(masterDocument.modifiedOn),
          modifiedBy: masterDocument.modifiedBy
        };
        masterDocuments.push(document);
      }
    }
    props.handleAddDocuments(masterDocuments);
    
    masterAreaCardViewDocumentStore.setShowAddDocumentModal(false);
    masterAreaCardViewDocumentStore.setShowCreateDocumentAlert(false);
  };

  const handleDocumentNumberChange = (selectedValue: SelectOption[]) => {
    masterAreaCardViewDocumentStore.setSelectedMasterDocOption(selectedValue);
  };

  const debouncedHandleInputChange = useDebounce(500);

  const handleDocumentNumberInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      masterAreaCardViewDocumentStore.getMasterDocOptions(inputValue);
    });
  };

  return (
    <Modal
      open={masterAreaCardViewDocumentStore.showAddDocumentModal}
      onClose={handleCancelClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="add-doc-title"
          variant="h6"
          component="h2"
          sx={{ mb: 4 }}
        >
          Add Document to Tag
        </Typography>
        <Box
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <Autocomplete
            size="small"
            id="DocumentNumber"
            options={masterAreaCardViewDocumentStore.masterDocsOption}
            value={masterAreaCardViewDocumentStore.selectedMasterDocOption}
            getOptionLabel={(option) => option.label}
            multiple
            sx={{
              width: "100%",
              mr: 1,
              mb: 1,
            }}
            renderInput={(params) => (
              <TextField {...params} label="Document Number" />
            )}
            onInputChange={(event, newInputValue) => {
              handleDocumentNumberInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              handleDocumentNumberChange(selectedValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Box>

        <Box
          sx={{
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
            justifyContent: "flex-end",
          }}
        >
          <PermissionButton
            buttonText="Add"
            buttonVariant="contained"
            buttonPermission={masterAreaCardViewDocumentStore.selectedMasterDocOption?.length > 0} //userStore.hasCurrentUserRole(ROLES.arsProjectUserRole)
            handleButtonClick={handleAddClick}
          />
          <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCancelClick}>
            <span>Cancel</span>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});

export default TagDetailAddDocumentModal;
