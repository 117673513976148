import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Modal,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { useState } from "react";
import { ExtendedSelectOption } from "../../../../../models/common/extendedSelectOption";
import { SelectOption } from "../../../../../models/common/selectOption";
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import { NOTIFICATION_TYPES } from "../../../../../constants/notificationTypes";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const CreateUserProjectMapButtonModal = observer(() => {
  const { userProjectMapStore, dictionaryStore, notificationStore } = useStores();

  // State to store the selected user's email
  const [selectedEmail, setSelectedEmail] = useState("");
  // State to store the selected projects
  const [selectedProjects, setSelectedProjects] = useState<SelectOption[]>([]);

  const debouncedHandleInputChange = useDebounce(500);

  const handleUserInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchUsersOptions(inputValue);
    });
  };

  const handleProjectInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchProjectOptions(inputValue);
    });
  };

  const handleCloseClick = () => {
    userProjectMapStore.setShowCreateModal(false);
  };

  const handleSaveClick = async () => {
    if (userProjectMapStore.selectedUserOption && selectedProjects.length > 0) {
      const userId = userProjectMapStore.selectedUserOption.id;
      const projectIds = selectedProjects.map((project) => project.id);

      try {
        await userProjectMapStore.createUserProjectMap({
          userId,
          projectIds,
        });
        notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
        notificationStore.setNotificationMessage(
          "User project map created successfully!"
        );
        notificationStore.setShowNotification(true);
        userProjectMapStore.setShowCreateModal(false);
      } catch (error) {
        notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
        notificationStore.setNotificationMessage("Failed to create user project map");
        notificationStore.setShowNotification(true);
      }
    }
  };

  const handleUserSelection = (
    event: React.SyntheticEvent<Element, Event>,
    selectedValue: ExtendedSelectOption | null
  ) => {
    // When a user is selected
    if (selectedValue) {
      userProjectMapStore.setSelectedUserOption(selectedValue);
      setSelectedEmail(selectedValue.additionalLabel);
    } else {
      // When a user is deselected
      userProjectMapStore.setSelectedUserOption(null);
      setSelectedEmail("");
    }
  };

  const handleProjectsChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: SelectOption[]
  ) => {
    setSelectedProjects(newValue);
  };

  return (
    <Modal
      open={userProjectMapStore.showCreateModal}
      onClose={handleCloseClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="create-user-project-map-title"
          variant="h6"
          component="h2"
          sx={{ mb: 4 }}
        >
          Create user projects map
        </Typography>
        <Box
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <Stack direction="row" mb={2}>
            <Autocomplete
              size="small"
              id="users"
              options={dictionaryStore.userOptions}
              value={userProjectMapStore.selectedUserOption}
              getOptionLabel={(option) => option.label}
              sx={{
                width: "100%",
                mr: 1,
                mb: 1,
              }}
              renderInput={(params) => <TextField {...params} label="User" />}
              onInputChange={(event, newInputValue) => {
                handleUserInputChange(newInputValue);
              }}
              onChange={handleUserSelection}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            {selectedEmail && (
              <Typography>
                Email: <a href={`mailto:${selectedEmail}`}>{selectedEmail}</a>
              </Typography>
            )}
          </Stack>
          <Stack direction="row" mb={2}>
            <Autocomplete
              multiple
              id="projects-autocomplete"
              options={dictionaryStore.projectOptions}
              disableCloseOnSelect
              getOptionLabel={(option) => option.label}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Select Projects" placeholder="Projects" />
              )}
              value={selectedProjects}
              onInputChange={(event, newInputValue) => {
                handleProjectInputChange(newInputValue);
              }}
              onChange={handleProjectsChange}
            />
          </Stack>
        </Box>
        <Tooltip
          title="Please select at least one project"
          disableHoverListener={selectedProjects.length > 0}
        >
          <span>
            <Button
              variant="contained"
              sx={{ mr: 2, mt: 2 }}
              onClick={handleSaveClick}
              disabled={selectedProjects.length < 1}
            >
              <span>Create</span>
            </Button>
          </span>
        </Tooltip>
        <Button variant="outlined" sx={{ mr: 2, mt: 2 }} onClick={handleCloseClick}>
          <span>Cancel</span>
        </Button>
      </Box>
    </Modal>
  );
});

export default CreateUserProjectMapButtonModal;
