import { observer } from "mobx-react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DataGridBox } from "../../../../styles/dataGridBox";
import { useStores } from "../../../../hooks/useStores";
import { formatDateWithTime } from "../../../../utils/formatDate";
import { MasterAssetDetailHistory } from "../../../../models/MasterArea/response/getAssetsDetailResponse";


const columns: GridColDef<MasterAssetDetailHistory>[] = [
  { field: "changedOn", headerName: "Changed Date", flex: 1, minWidth: 180, valueFormatter: formatDateWithTime },
  { field: "changedBy", headerName: "Changed By", flex: 1, minWidth: 180 },
  { field: "action", headerName: "Event", flex: 1, minWidth: 180 },
  { field: "changedField", headerName: "Changed Field", flex: 1, minWidth: 200 },
  { field: "oldValue", headerName: "Old Value", flex: 1, minWidth: 200 },
  { field: "newValue", headerName: "New Value", flex: 1, minWidth: 200 }
];

const MasterTagDetailHistoryTab = observer(() => {
  let id = 0;

  const {
    masterAreaCardViewStore: { masterHistory }
  } = useStores();

  return (
    <DataGridBox>
      <DataGrid 
        columns={columns} 
        rows={masterHistory ?? []} 
        getRowId={(row) => id++} 
        density="comfortable" 
      />
    </DataGridBox>
  );
});

export default MasterTagDetailHistoryTab;
