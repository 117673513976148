import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import { DataGridBox } from "../../../styles/dataGridBox";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { formatDateWithTime } from "../../../utils/formatDate";
import { useState } from "react";

const columns: GridColDef[] = [
    { field: "type", headerName: "Type", flex: 1, minWidth: 150 },
    // {
    //   field: "documentCode",
    //   headerName: "Code",
    //   flex: 1,
    //   minWidth: 100,
    // },
    {
      field: "documentNumber",
      headerName: "Number",
      flex: 1,
      minWidth: 300,
      renderCell: (params) => {
        const href = params.row.documentUrl;
        return href ? (
          <a href={href} target="_blank" rel="noreferrer">
            {params.value}
          </a>
        ) : (
          <span>{params.value}</span>
        );
      },
    },
    {
      field: "createdOn",
      headerName: "Created On",
      flex: 1,
      minWidth: 120,
      valueFormatter: formatDateWithTime,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "modifiedOn",
      headerName: "Modified On",
      flex: 1,
      minWidth: 120,
      valueFormatter: formatDateWithTime,
    },
    {
      field: "modifiedBy",
      headerName: "Modified By",
      flex: 1,
      minWidth: 200,
    },
  ];

const ExRegisterDocumentationTab = observer(() => {
  const {
    masterAreaCardViewDocumentStore,
    masterAreaCardViewStore: { masterDocuments, setMasterDocuments },
    masterAreaDocumentsStore
  } = useStores();


  return (
    <DataGridBox>
      <DataGridPro
        columns={columns}
        rows={masterAreaDocumentsStore.documents ?? []}
        checkboxSelection
        density="comfortable"
       
      />
    </DataGridBox>
  );
});

export default ExRegisterDocumentationTab;
