import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteTagRequests } from "../hooks/useFetchRequests";
import ConfirmationDialogModal from "../../../../components/common/ConfirmationDialog";
import { useState } from "react";
import ErrorDialogModal from "../../../../components/common/ErrorDialog";

const MasterAreaDeleteTag = observer(() => {
  const { masterAssetsStore, masterAreaGridViewExportStore, notificationStore } = useStores();

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const handleDeleteClick = async () => {
    await masterAssetsStore.getNonDeletablePrimaryTags(masterAreaGridViewExportStore.selectedIds);
    if(masterAssetsStore.nonDeletableTags.length > 0) {
      setShowErrorModal(true);
    }
    else {
      setShowDeleteConfirmationModal(true);
    }
  };

  const deleteBBTags = async () => {
    deleteTagRequests(masterAreaGridViewExportStore.selectedIds, masterAssetsStore, notificationStore);
    setShowDeleteConfirmationModal(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<DeleteIcon />}
        disabled={masterAreaGridViewExportStore.selectedIds.length < 1}
        onClick={() => handleDeleteClick()}
      >
        Delete
      </Button>
      <ErrorDialogModal 
          handleOkClick={() => setShowErrorModal(false)} 
          showErrorDialog={showErrorModal} 
          headerText="Error"
          errorText={"Primary Tags cannot be deleted: " + masterAssetsStore.nonDeletableTags }
        />
      <ConfirmationDialogModal 
          handleOkClick={deleteBBTags} 
          handleCancelClick={() => setShowDeleteConfirmationModal(false)} 
          showConfirmationDialog={showDeleteConfirmationModal} 
          headerText="Confirm Deleting Tags"
          confirmationText="Are you sure you want to delete selected tags?"
          okButtonText="Ok"
          cancelButtonText="Cancel"
        />
    </>
  );
});

export default MasterAreaDeleteTag;
