import { observer } from "mobx-react";
import React, { useEffect } from "react";
import usePageTitle from "../../../hooks/usePageTitle";
import { useStores } from "../../../hooks/useStores";
import { Grid } from "@mui/material";
import { DataGridBox } from "../../../styles/dataGridBox";
import { DataGridPro } from "@mui/x-data-grid-pro";
import formatDate from "../../../utils/formatDate";
import CustomToolBarUserCategoriesManagement from "./components/CustomToolBarUserCategoriesManagement";

const UserCategoriesManagementGridPage = observer(() => {
  usePageTitle("Admin Area - User Categories Management");

  const columns = [
    {
      field: "userName",
      headerName: "User Name",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "categoryNames",
      headerName: "Categories",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      minWidth: 180,
      valueFormatter: formatDate,
    },
    {
      field: "updatedDate",
      headerName: "Updated Date",
      flex: 1,
      minWidth: 180,
      valueFormatter: formatDate,
    },
  ];

  const { userCategoryMapStore, dictionaryStore } = useStores();

  useEffect(() => {
    dictionaryStore.fetchUsersOptions("");
    dictionaryStore.fetchUserCategoryOptions("");

    const fetchData = async () => {
      await userCategoryMapStore.fetchUserCategoryMaps();
    };

    fetchData();
  }, [
    userCategoryMapStore,
    userCategoryMapStore.createUserCategoryMap,
    userCategoryMapStore.deleteUserCategoryMap,
    dictionaryStore,
  ]);

  return (
    <>
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item xs={12} md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <DataGridBox>
            <DataGridPro
              //disableColumnFilter
              paginationMode="server"
              rows={userCategoryMapStore.userCategoryMapsResponse.items}
              columns={columns}
              rowCount={userCategoryMapStore.userCategoryMapsResponse.totalRecords}
              loading={userCategoryMapStore.isLoading}
              pagination
              paginationModel={userCategoryMapStore.paginationModel}
              pageSizeOptions={[userCategoryMapStore.paginationModel.pageSize]}
              onPaginationModelChange={userCategoryMapStore.setPaginationModel}
              sortingMode="server"
              sortModel={userCategoryMapStore.sortModel}
              onSortModelChange={(model) => {
                userCategoryMapStore.setSortModel(model);
                userCategoryMapStore.fetchUserCategoryMaps();
              }}
              checkboxSelection
              onRowSelectionModelChange={(ids) => {
                const selectedUserIds = ids
                  .map((id) => {
                    const row = userCategoryMapStore.userCategoryMapsResponse.items.find(
                      (item) => item.id === id
                    );
                    return row ? row.userId : undefined;
                  })
                  .filter(
                    (userId): userId is number => userId !== null && userId !== undefined
                  );

                userCategoryMapStore.setSelectedIds(selectedUserIds);
              }}
              disableRowSelectionOnClick
              density="compact"
              sx={{ fontSize: "0.75rem" }}
              slots={{
                toolbar: CustomToolBarUserCategoriesManagement,
              }}
              // columnVisibilityModel={columnVisibilityModel}
              // onColumnVisibilityModelChange={(newModel) =>
              //   setColumnVisibilityModel(newModel)
              // }
            />
          </DataGridBox>
        </Grid>
      </Grid>
    </>
  );
});

export default UserCategoriesManagementGridPage;
