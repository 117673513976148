import {
  Box,
  Modal,
  Stack,
  Typography,
  Button
} from "@mui/material";
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import { observer } from "mobx-react";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

interface ErrorDialogModalProps {
  handleOkClick: any;
  showErrorDialog: boolean;
  headerText: string;
  errorText: string;
}

const ErrorDialogModal = observer((props: ErrorDialogModalProps) => {
  return (
    <Modal
      open={props.showErrorDialog}
      onClose={props.handleOkClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="add-tag-attributes-title"
          variant="h6"
          component="h2"
          sx={{ mb: 2 }}
        >
          <Stack direction="row">
            <Stack direction="row"><ReportOutlinedIcon style={{ color: 'red', marginTop: "3px" }} /></Stack> 
            <Stack direction="row">&nbsp;{props.headerText}</Stack>
          </Stack>
        </Typography>
        <Box sx={{ mb: 2, backgroundColor: "#fff" }}>
          {props.errorText}
        </Box>
        <Box
          sx={{
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
            justifyContent: "flex-end",
          }}
        >
          <Button 
            variant="contained" 
            disabled={false} 
            sx={{ ml: 2 }} 
            onClick={props.handleOkClick}>
            <span>OK</span>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});

export default ErrorDialogModal;
