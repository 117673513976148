import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import { Box, Button, TextField, Typography, Stack, Backdrop, Autocomplete, FormControlLabel, Checkbox } from "@mui/material";
import { useState, useEffect } from "react";
import { useDebounce } from "../../../hooks/useDebounce";
import usePageTitle from "../../../hooks/usePageTitle";
import { areaboxStyle} from "../../../constants/styles";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { NOTIFICATION_TYPES } from "../../../constants/notificationTypes";
import { NullableString } from "../../../models/common/response/types";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { CircleOutlined } from "@mui/icons-material";
import { SelectOption } from "../../../models/common/selectOption";
import { CreateUnitRequest } from "../../../models/Dictionaries/request/createUnitRequest";
import { validationColor } from "../../../utils/validation";

const UnitItemCreate = observer(() => {
  usePageTitle("References - New Unit");

  const navigate = useNavigate();

  const { referencesStore, notificationStore, dictionaryStore } = useStores();

  const [unit, setUnit] = useState<NullableString>("");
  const [unitCode, setUnitCode] = useState<NullableString>("");
  const [unitDescription, setUnitDescription] = useState<NullableString>("");
  const [unitRemarks, setUnitRemarks] = useState<NullableString>("");
  const [relatedUnit, setRelatedUnit] = useState<NullableString>("");
  const [unitShortCode, setUnitShortCode] = useState<NullableString>("");
  const [unitNotForInstrumentationDiscipline, setUnitNotForInstrumentationDiscipline] = useState<boolean>(false);
  const [unitNotForElectricalDiscipline, setUnitNotForElectricalDiscipline] = useState<boolean>(false);
  const [facilityOption, setFacilityOption] = useState<SelectOption | null>(null);
  const [areaOption, setAreaOption] = useState<SelectOption | null>(null);

  useEffect(() => {
    dictionaryStore.fetchFacilityOptions("");
  }, []);
  
  const debouncedHandleInputChange = useDebounce(500);

  const handleFacilityInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchFacilityOptions(inputValue);
    });
  };

  const handleAreaInputChange = async (inputValue: string, facilityId?: number) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchAreaOptions(inputValue, facilityId);
    });
  };

  const handleCloseClick = () => {
    navigate(ROUTES.referencesUnits);
  };

  const handleSaveClick = async () => {
    try {
      const unitItem: CreateUnitRequest = {
        unit: unit,
        unitCode: unitCode,
        unitDescription: unitDescription,
        unitRemarks: unitRemarks,
        relatedUnit: relatedUnit,
        unitShortCode: unitShortCode,
        unitNotForInstrumentationDiscipline: unitNotForInstrumentationDiscipline,
        unitNotForElectricalDiscipline: unitNotForElectricalDiscipline,
        facilityId: facilityOption?.id,
        areaId: areaOption?.id
      }
      await referencesStore.createUnit(unitItem);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Unit created successfully");
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error creating unit: " + error);
    }
    finally {
      notificationStore.setShowNotification(true);
      navigate(ROUTES.referencesUnits);
    }
  }

  return (
    <form>
    <Box
          sx={ areaboxStyle }
        > 
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "#fff",
              display: { xs: "block", md: "flex", width: "50%" }
            }}
          >
            <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
              <ArrowBackOutlinedIcon />
            </Button>
            <Button 
              variant="contained" 
              sx={{ ml: 2 }} 
              disabled={!unit || !unitCode || !unitShortCode || !unitDescription || !facilityOption || !areaOption}
              onClick={() => handleSaveClick()}>
              <span>Save</span>
            </Button> 
          </Box>
        </Stack>
      </Box>
    {    
    <Box sx={{ "overflow-y": "scroll", height: "80vh" }}> 
      <Box
          sx={ areaboxStyle }
        >  
         <Typography
            id="add-tag-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
          New Unit
          </Typography> 
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Unit"
            value={unit}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(unit) }}
            onChange={(e) => setUnit(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Unit Code"
            value={unitCode}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(unitCode) }}
            onChange={(e) => setUnitCode(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Unit Short Code"
            value={unitShortCode}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(unitShortCode) }}
            onChange={(e) => setUnitShortCode(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
        <Autocomplete
              disablePortal
              size="small"
              id="facility"
              options={dictionaryStore.facilityOptions}
              value={facilityOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%" }}
              renderInput={(params) => <TextField {...params} label="Facility" sx={{ "background-color": validationColor(facilityOption?.label) }} />}
              onInputChange={(event, newInputValue) => {
                handleFacilityInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setFacilityOption(selectedValue);
                setAreaOption(null);
                handleFacilityInputChange("");
                handleAreaInputChange("", selectedValue?.id);
              }}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
            />
        </Stack>
        <Stack direction="row">
            <Autocomplete
              disablePortal
              size="small"
              id="area"
              options={dictionaryStore.areaOptions}
              value={areaOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%" }}
              renderInput={(params) => <TextField {...params} label="Area" sx={{ "background-color": validationColor(areaOption?.label) }} />}
              onInputChange={(event, newInputValue) => {
                handleAreaInputChange(newInputValue, facilityOption?.id);
              }}
              onChange={(event, selectedValue) => {
                setAreaOption(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              disabled={!facilityOption}
            />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Unit Description"
            value={unitDescription}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(unitDescription) }}
            onChange={(e) => setUnitDescription(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Unit Remarks"
            value={unitRemarks}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            onChange={(e) => setUnitRemarks(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Related Unit Code"
            value={relatedUnit}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            onChange={(e) => setRelatedUnit(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
        <FormControlLabel control={
              <Checkbox defaultChecked={false} onChange={(e) => setUnitNotForElectricalDiscipline(e.target.checked) } />
            } label=" Not For Electrical Discipline"  sx={{ width: "50%" }} 
          /> 
        </Stack>
        <Stack direction="row">
        <FormControlLabel control={
              <Checkbox defaultChecked={false} onChange={(e) => setUnitNotForInstrumentationDiscipline(e.target.checked) } />
            } label="Not For Instrumentation Discipline"  sx={{ width: "50%" }} 
          /> 
        </Stack>
        </Box> 
      </Box>
      }
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={referencesStore.isLoading}>
        <CircleOutlined color="inherit" />
      </Backdrop>
      </form>
  );
});

export default UnitItemCreate;
