import { observer } from "mobx-react";
import { useState } from "react";
import { useStores } from "../../../../../hooks/useStores";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DataGridBox } from "../../../../../styles/dataGridBox";
import DocumentEquipmentTagsToolBar from "./DocumentEquipmentTagsToolBar";
import { DocumentEquipmentTag } from "../../../../../stores/MasterArea/MasterAreaDocumentsStore";
import { formatDateWithTime } from "../../../../../utils/formatDate";

const columns: GridColDef<DocumentEquipmentTag>[] = [
  { field: "tagNumber", headerName: "Tag Number", flex: 1, minWidth: 180 },
  { field: "createdOn", headerName: "Created On", flex: 1, minWidth: 200, valueFormatter: formatDateWithTime },
  { field: "createdBy", headerName: "Created By", flex: 1, minWidth: 200 },
  { field: "category", headerName: "Category", flex: 1, minWidth: 150 },
  { field: "status", headerName: "Status", flex: 1, minWidth: 150 },
  { field: "controlAutonaming", headerName: "Control Autonaming", flex: 1, minWidth: 150 }
];

interface EquipmentTagsTabProps {
  Toolbar: boolean;
}

const EquipmentTagsTab = observer((props: EquipmentTagsTabProps) => {
  const {
    masterAreaDocumentsStore
  } = useStores();

  const [equipmentTags, setEquipmentTags] = useState<DocumentEquipmentTag[]>(masterAreaDocumentsStore.equipmentTags);

  const handleAddEquipmentTag = (eTags: DocumentEquipmentTag[]) => {
      const eqTags = Object.assign([], equipmentTags);
      for (const eTag of eTags) {
        if (equipmentTags?.filter(d => eTag.id === d.id).length === 0 ) {      
          eqTags.push(eTag);      
        }
      }

      setEquipmentTags([...eqTags]);
      masterAreaDocumentsStore.setEquipmentTags(eqTags);
  };

  const handleDeleteEquipmentTag = () => {
    const eqTags = masterAreaDocumentsStore.equipmentTags.filter(eq => masterAreaDocumentsStore.equipmentTagsSelectedIds.indexOf(eq.id) < 0 );
    setEquipmentTags([...eqTags]);
    masterAreaDocumentsStore.setEquipmentTags(eqTags);
  };

  return (
    <DataGridBox>
        <DataGrid 
          columns={columns} 
          rows={equipmentTags ?? []} 
          getRowId={(row) => row.id} 
          density="comfortable"
          checkboxSelection
          onRowSelectionModelChange={(ids) => {
            const selectedIds = new Set(ids);
            masterAreaDocumentsStore.setEquipmentTagsSelectedIds(
              Array.from(selectedIds).map((id) => parseInt(id as string))
            );
          }}
          slots={{
            toolbar: props.Toolbar ? DocumentEquipmentTagsToolBar : null,
          }}
          slotProps={{ toolbar: { handleAddEquipmentTag: handleAddEquipmentTag, handleDeleteEquipmentTag: handleDeleteEquipmentTag } }}
          />
      </DataGridBox>
  );
});

export default EquipmentTagsTab;
