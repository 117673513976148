import { makeAutoObservable, runInAction } from "mobx";
import { ExRegisterAttributes } from "../../models/ExRegister/response/getExRegisterAttributesByIdResponse";
import {
  ExRegisterTag,
  GetExRegisterTagsResponse,
} from "../../models/ExRegister/response/getExRegisterTagsResponse";
import {
  ExRegisterTagsFilterOptions,
  Filter,
  GetExRegisterTagsRequest,
} from "../../models/ExRegister/request/getExRegisterTagsRequest";
import apiClient from "../../api/clients";
import { SelectOption } from "../../models/common/selectOption";
import { getOptionLabel } from "../../utils/getOptionValue";
import { UpdateExRegisterAttributesRequest } from "../../models/ExRegister/request/updateExRegisterAttributesRequest";

class ExRegisterStore {
  showDetailLoading = false;
  exRegisterTag?: ExRegisterTag;
  exRegisterAttributes?: ExRegisterAttributes;
  selectedTagNumberOption: SelectOption | null = null;
  selectedSystemnameOption: string | undefined;
  selectedSubsystemOption: string | undefined;

  exRegisterResponse: GetExRegisterTagsResponse = {
    items: [],
    pageIndex: 0,
    pageSize: 0,
    totalPages: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    totalRecords: 0,
  };

  paginationModel = {
    pageSize: 100,
    page: 0,
  };

  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setShowDetailLoading = (showDetailLoading: boolean) => {
    this.showDetailLoading = showDetailLoading;
  };

  setPaginationModel = (newModel: any) => {
    runInAction(() => {
      this.paginationModel = newModel;
      this.fetchExRegisterTags();
    });
  };

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  setSelectedTagNumberOption = (option: SelectOption | null) => {
    this.selectedTagNumberOption = option;
  };

  setSelectedSystemnameOption = (option: string) => {
    this.selectedSystemnameOption = option;
  };

  setSelectedSubsystemOption = (option: string) => {
    this.selectedSubsystemOption = option;
  };

  fetchExRegisterTags = async () => {
    try {
      const { page, pageSize } = this.paginationModel;

      const filterOptions: ExRegisterTagsFilterOptions = {
        tagNumber: getOptionLabel(this.selectedTagNumberOption),
        systemname: getOptionLabel(this.selectedSystemnameOption),
        subsystem: getOptionLabel(this.selectedSubsystemOption),
      };

      const filter = Object.keys(filterOptions).reduce((acc, key) => {
        const filterValue = filterOptions[key as keyof ExRegisterTagsFilterOptions];
        if (filterValue !== undefined) {
          acc[key as keyof Filter] = { filterValue: filterValue as never };
        }
        return acc;
      }, {} as Filter);

      const request: GetExRegisterTagsRequest = {
        pagination: { pageIndex: page, pageSize },
        filter,
      };
      this.setIsLoading(true);

      const data = await apiClient.getExRegisterTags(request);

      runInAction(() => {
        this.exRegisterResponse = data;
        this.isLoading = false;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  fetchExRegisterAttributesById = async (id: number) => {
    try {
      const exRegisterTagAttributes = await apiClient.getExRegisterAttributesById(id);
      runInAction(() => {
        this.exRegisterAttributes = exRegisterTagAttributes;
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  async updateExRegisterAttributes(request: UpdateExRegisterAttributesRequest) : Promise<Response> {
    try {    
      const response = await apiClient.updateExRegisterAttributes(request);
      return response;
    } catch (error) { 
      throw new Error("Failed to update. Please try again later.");
    }
  }
}

export default ExRegisterStore;
