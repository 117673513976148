import { observer } from "mobx-react";
import {
  Box,
  Button,
  Modal,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useStores } from "../../../../../hooks/useStores";
import { DataGridBox } from "../../../../../styles/dataGridBox";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { NOTIFICATION_TYPES } from "../../../../../constants/notificationTypes";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const columns: GridColDef[] = [
  {
    field: "categoryNames",
    headerName: "Categories",
    flex: 1,
    minWidth: 180,
  },
];

const EditUserCategoryMapButtonModal = observer(() => {
  const { userCategoryMapStore, notificationStore } = useStores();

  const userEmail = userCategoryMapStore.userCategoryMapDetails.userEmail;
  const userName = userCategoryMapStore.userCategoryMapDetails.userName;

  const handleCloseClick = () => {
    userCategoryMapStore.setShowEditModal(false);
  };

  const handleSaveClick = async () => {
    try {
      // Call deleteUserCategoryMap with the selected category IDs
      await userCategoryMapStore.deleteUserCategoryMap({
        usersIds: [userCategoryMapStore.userCategoryMapDetails.userId ?? null],
        categoryIds: userCategoryMapStore.selectedCategoryIds,
      });
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("User categories deleted successfully!");
      notificationStore.setShowNotification(true);
      userCategoryMapStore.fetchUserCategoryMaps();
      userCategoryMapStore.setShowEditModal(false);
    } catch (error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Failed to delete user category map");
      notificationStore.setShowNotification(true);
    }
  };

  // Create an array of rows, where each row represents a category number
  const categoryRows = userCategoryMapStore.userCategoryMapDetails.categoryNames.map(
    (categoryName, index) => ({
      id: userCategoryMapStore.userCategoryMapDetails.categoryIds[index], // Use categoryIds as unique identifiers
      categoryNames: categoryName,
    })
  );

  return (
    <Modal
      open={userCategoryMapStore.showEditModal}
      onClose={handleCloseClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="edit-user-category-map-title"
          variant="h6"
          component="h2"
          sx={{ mb: 4 }}
        >
          Edit user categorys
        </Typography>
        <Box
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <Stack direction="row" mb={2}>
            <Typography
              id="edit-user-category-map-title"
              variant="subtitle1"
              component="h6"
              sx={{ mr: 4 }}
            >
              User: {userName}
            </Typography>
            <Typography
              id="edit-user-category-map-email"
              variant="subtitle1"
              component="h6"
              sx={{ mr: 4 }}
            >
              Email: <a href={`mailto:${userEmail}`}>{userEmail}</a>
            </Typography>
          </Stack>
          <Stack direction="row" mb={2}>
            <DataGridBox>
              <DataGridPro
                checkboxSelection
                disableRowSelectionOnClick
                columns={columns}
                rows={categoryRows}
                onRowSelectionModelChange={(ids) => {
                  userCategoryMapStore.setSelectedCategoryIds(ids.map(Number));
                }}
              ></DataGridPro>
            </DataGridBox>
          </Stack>
        </Box>
        <Tooltip
          title="Please select at least one category"
          disableHoverListener={userCategoryMapStore.selectedCategoryIds.length > 0}
        >
          <span>
            <Button
              variant="contained"
              sx={{ ml: 2 }}
              onClick={handleSaveClick}
              disabled={userCategoryMapStore.selectedCategoryIds.length < 1}
            >
              <span>Delete</span>
            </Button>
          </span>
        </Tooltip>
        <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
          <span>Cancel</span>
        </Button>
      </Box>
    </Modal>
  );
});

export default EditUserCategoryMapButtonModal;
