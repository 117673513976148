import { makeAutoObservable, runInAction } from "mobx";
import { GridSortModel } from "@mui/x-data-grid-pro";
import { getOptionId } from "../../utils/getOptionValue";
import { SelectOption } from "../../models/common/selectOption";
import { SortOrder } from "../../models/common/request/sorting";
import apiClient from "../../api/clients";
import { GetUserCategoryMapsByIdResponse } from "../../models/AdminArea/response/getUserCategoryMapsByIdResponse";
import { GetUserCategoryMapsResponse } from "../../models/AdminArea/response/getUserCategoryMapsResponse";
import { Filter, GetUserCategoryMapsRequest, UserCategoryMapFilterOptions, UserCategoryMapSortOptions } from "../../models/AdminArea/request/getUserCategoryMapsRequest";
import { CreateUserCategoryMapRequest } from "../../models/AdminArea/request/createUserCategoryMapRequest";
import { DeleteUserCategoryMapRequest } from "../../models/AdminArea/request/deleteUserCategoryMapRequest";

class UserCategoryMapStore {
  selectedIds: number[] = [];
  selectedCategoryOption: SelectOption | null = null;
  selectedUserOption: SelectOption | null = null;
  showCreateModal = false;
  showEditModal = false;
  userCategoryMapDetails: GetUserCategoryMapsByIdResponse = {} as GetUserCategoryMapsByIdResponse;
  selectedCategoryIds: number[] = [];

  userCategoryMapsResponse: GetUserCategoryMapsResponse = {
    items: [],
    pageIndex: 0,
    pageSize: 0,
    totalPages: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    totalRecords: 0,
  };

  paginationModel = {
    pageSize: 100,
    page: 0,
  };
  sortModel: GridSortModel = [];
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setPaginationModel = (paginationModel: any) => {
    this.paginationModel = paginationModel;
  };

  setSortModel = (sortModel: any) => {
    this.sortModel = sortModel;
  };

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  setShowCreateModal = (value: boolean) => {
    this.showCreateModal = value;
  };

  setShowEditModal = (value: boolean) => {
    this.showEditModal = value;
  };

  setSelectedUserOption = (option: SelectOption | null) => {
    this.selectedUserOption = option;
  };

  setSelectedCategoryIds = (selectedIds: number[]) => {
    this.selectedCategoryIds = selectedIds;
  };

  setSelectedCategoryOption = (option: SelectOption | null) => {
    this.selectedCategoryOption = option;
  };

  setSelectedIds = (selectedIds: number[]) => {
    this.selectedIds = selectedIds;
  };

  fetchUserCategoryMaps = async () => {
    try {
      const { page, pageSize } = this.paginationModel;

      const filterOptions: UserCategoryMapFilterOptions = {
        categoryId: getOptionId(this.selectedCategoryOption),
        userId: getOptionId(this.selectedUserOption),
      };

      const sortOptions: UserCategoryMapSortOptions = {
        userName: "userName",
        createdDate: "createdDate",
      };

      const sortField = this.sortModel[0]?.field;
      const sortOrder =
        this.sortModel[0]?.sort === "asc" ? SortOrder.ASC : SortOrder.DESC;
      const sortProperty = sortField
        ? sortOptions[sortField as keyof UserCategoryMapSortOptions]
        : undefined;
      const filter = Object.keys(filterOptions).reduce((acc, key) => {
        const filterValue = filterOptions[key as keyof UserCategoryMapFilterOptions];
        if (filterValue !== undefined) {
          acc[key as keyof Filter] = {
            filterValue: filterValue as never,
          };
        }
        return acc;
      }, {} as Filter);
      const sort = sortProperty
        ? {
            [sortProperty]: { sortOrder },
          }
        : undefined;
      const request: GetUserCategoryMapsRequest = {
        pagination: { pageIndex: page, pageSize },
        filter,
        sort,
      };
      this.setIsLoading(true);

      const data = await apiClient.getUserCategoryMaps(request);

      runInAction(() => {
        this.userCategoryMapsResponse = data;
        this.isLoading = false;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  async createUserCategoryMap(data: CreateUserCategoryMapRequest): Promise<void> {
    try {
      await apiClient.createUserCategoryMap(data);
      this.setSelectedUserOption(null);
      this.setSelectedCategoryOption(null);
      await this.fetchUserCategoryMaps();
    } catch (error) {
      console.error("Error creating user category map:", error);
      throw error;
    }
  }

  async deleteUserCategoryMap(data: DeleteUserCategoryMapRequest) {
    try {
      await apiClient.deleteUserCategoryMap(data);
    } catch (error) {
      console.error(error);
    }
  }

  async getUserCategoryMapById(userId: number): Promise<void> {
    if (!userId) return;

    try {
      const response = await apiClient.getUserCategoryMapById(userId);
      console.log(response);

      runInAction(() => {
        this.userCategoryMapDetails = response;
      });
    } catch (error) {
      console.error("Error while getting details:", error);
    }
  }
}

export default UserCategoryMapStore;
