import { useState } from "react";
import {
  Container,
  Typography,
  Stack,
  TextField,
  Tabs,
  Tab,
  Box,
  Button,
  Tooltip,
} from "@mui/material";
import CustomTabPanel from "./CustomTabPanel";

import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import RelatedTagsTab from "../tabs/RelatedTagsTab";
import TagDetailDocumentationTab from "../tabs/TagDetailDocumentationTab";
import TagDetailExTab from "../tabs/TagDetailExTab";
import TagDetailCMMSTab from "../tabs/TagDetailCMMSTab";
import TagDetailAttributesTab from "../tabs/TagDetailAttributesTab";
import TagDetailValidationTab from "../tabs/TagDetailValidationTab";
import PermissionButton from "../../../../components/common/PermissionButton";
import { ROLES } from "../../../../constants/roles";
import { smallTextFieldStyle, textFieldStyle, photoStyle, remarksStyle, documentNumberFieldStyle, longTextFieldStyle } from "../styles/styles"
import { TagRequestStatus } from "../../../../models/common/response/tagRequestFormStatus";
import TagDetailInfoTab from "../tabs/TagDetailInfoTab";
import TagDetailHistoryTab from "../tabs/TagDetailHistoryTab";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TagDetail = observer(() => {
  const [value, setValue] = useState(0);
  const {
    projectAreaCardViewStore: { assetDetail },
    projectAreaUpdateStore,
    userStore
  } = useStores();

  const isCompleted = assetDetail?.status === TagRequestStatus.Completed;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeButtonClick = () => {
    projectAreaUpdateStore.setShowEditMode(true);
  };

  return assetDetail ? (
    <>
    <Box>
    <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            aria-label="Detail-Scrollable-Tags"
            sx ={{ "& .MuiTabScrollButton-horizontal": { width : "25px"}}}
          >
            <Tab label="GENERAL" {...a11yProps(0)} />
            <Tab label="ATTRIBUTES" {...a11yProps(1)} />
            <Tab label="RELATED TAGS" {...a11yProps(2)} />
            <Tab label="DOCUMENTATION" {...a11yProps(3)} />
            <Tab label="EX" {...a11yProps(4)} />
            <Tab label="VALIDATION" {...a11yProps(5)} />
            <Tab label="CMMS" {...a11yProps(6)} />
            <Tab label="INFO" {...a11yProps(7)} />
            <Tab label="HISTORY" {...a11yProps(8)} />
          </Tabs>   
    </Box>
    <Box sx={{ width: "100%", marginBottom: 4 }}>
    <CustomTabPanel value={value} index={0}>
      <Stack direction="row" alignItems="center" gap={1} sx={{ mb: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          {assetDetail.tagNumber}
        </Typography>
      </Stack>
      <Stack direction="row">
        <Stack direction="column">
          <Stack direction="row">
            <TextField
              variant="outlined"
              size="small"
              label="Facility"
              value={assetDetail.facilityCode ?? "-"}
              sx={smallTextFieldStyle}
            />
            <TextField
              variant="outlined"
              size="small"
              label="Area"
              value={assetDetail.areaCode ?? "-"}
              sx={smallTextFieldStyle}
            />  
          </Stack>
          <TextField
            variant="outlined"
            size="small"
            label="Service Description"
            value={assetDetail.serviceDescription ?? "-"}
            sx={longTextFieldStyle}
          />  
        </Stack>
        <Stack direction="column">
          <TextField
            variant="outlined"
            size="small"
            label="Unit"
            value={assetDetail.unitCode ?? "-"}
            sx={smallTextFieldStyle}
          />     
          <TextField
            variant="outlined"
            size="small"
            label="Equipment Status"
            value={assetDetail.equipmentStatus}
            sx={smallTextFieldStyle}
          />
        </Stack>
        <TextField
          variant="outlined"
          size="small"
          label="Placeholder for photo..."  
          multiline
          rows="3" 
          sx={photoStyle}
        />
      </Stack>
      <Stack direction="row">
        <TextField
          variant="outlined"
          size="small"
          label="Tag Category"
          value={assetDetail.tagCategoryName ?? "-"}
          sx={textFieldStyle}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Equipment Type"
          value={assetDetail.equipmentTypeCode ?? "-"}
          sx={textFieldStyle}
        />
        <TextField
          variant="outlined"
          size="small"
          label="System Name"
          value={assetDetail.systemName ?? "-"}
          sx={textFieldStyle}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Sub System"
          value={assetDetail.subSystem ?? "-"}
          sx={textFieldStyle}
        />
      </Stack>
      <Stack direction="row">
        <TextField
          variant="outlined"
          size="small"
          label="Parent Tag Number"
          value={assetDetail.parentTagNumber ?? "-"}
          sx={textFieldStyle}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Manufacturer"
          value={assetDetail.manufacturer ?? "-"}
          sx={textFieldStyle}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Model Number"
          value={assetDetail.modelNumber ?? "-"}
          sx={textFieldStyle}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Purchase Order Number"
          value={assetDetail.purchaseOrderNumber ?? "-"}
          sx={textFieldStyle}
        />
      </Stack>
      <Stack direction="row">
        <TextField
          variant="outlined"
          size="small"
          label="Serial Number"
          value={assetDetail.serialNumber ?? "-"}
          sx={textFieldStyle}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Is Maintainable"
          value={assetDetail.isMaintainable ?? "-"}
          sx={textFieldStyle}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Ex Register"
          value={assetDetail.exRegister ?? "-"}
          sx={textFieldStyle}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Ex Inspection Required"
          value={assetDetail.exInspectionRequired ?? "-"}
          sx={textFieldStyle}
        />
      </Stack>
      <Stack direction="row">
        <TextField
          variant="outlined"
          size="small"
          label="Remarks"
          multiline
          rows="3"
          value={assetDetail.remarks ?? "-"}
          sx={remarksStyle}
        />
        <Stack direction="column">
          <TextField
            variant="outlined"
            size="small"
            label="Datasheet"
            value={assetDetail.datasheet ?? "-"}
            sx={documentNumberFieldStyle}
          />
          <TextField
            variant="outlined"
            size="small"
            label="P&ID"
            value={assetDetail.pipingAndInstrumentationDiagram ?? "-"}
            sx={documentNumberFieldStyle}
          />
        </Stack>
      </Stack>
      </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <TagDetailAttributesTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <RelatedTagsTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <TagDetailDocumentationTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <TagDetailExTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <TagDetailValidationTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={6}>
          <TagDetailCMMSTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={7}>
          <TagDetailInfoTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={8}>
          <TagDetailHistoryTab />
        </CustomTabPanel>
      </Box>

      <Stack
        spacing={2}
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        position="absolute"
        bottom={0}
        right={0}
        width={"100%"}
        p={2}
        sx={{
          border: "solid",
          borderColor: "#f0f0f0",
          borderWidth: "1px 0px 0px 0px",
        }}
      >
        {isCompleted && (
          <Typography component={"span"} sx={{ textAlign: "center", color: "red" }}>
            Tag Handover is Complete. Tag is locked for data changes.
        </Typography>
        )}
        <PermissionButton
          buttonText="Change"
          buttonVariant="outlined"
          buttonPermission={userStore.hasCurrentUserRole(ROLES.arsProjectUserRole) && assetDetail.status != TagRequestStatus.Completed}
          handleButtonClick={handleChangeButtonClick}
        />
      </Stack>
    </>
  ) : (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Typography component={"span"} sx={{ textAlign: "center" }}>
        Please, select tag from gallery
      </Typography>
    </Container>
  );
});

export default TagDetail;
