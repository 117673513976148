import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import {
  Box,
  Button,
  TextField,
  Stack,
  Typography,
  Backdrop,
  CircularProgress
} from "@mui/material";
import { useState, useEffect } from "react";
import usePageTitle from "../../../hooks/usePageTitle";
import { areaboxStyle} from "../../../constants/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { NOTIFICATION_TYPES } from "../../../constants/notificationTypes";
import { NullableString } from "../../../models/common/response/types";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { UpdatePipeClassRequest } from "../../../models/Dictionaries/request/updatePipeClassRequest";
import { validationColor } from "../../../utils/validation";

const PipeClassItem = observer(() => {
  usePageTitle("References - Pipe Class");

  const navigate = useNavigate();

  const [urlParams] = useSearchParams()

  const { referencesStore, notificationStore } = useStores();
  
  const [pipeClassId, setPipeClassId] = useState<number>(0);
  const [pipeClass, setPipeClass] = useState<NullableString>("");
  const [pipeClassCode, setPipeClassCode] = useState<NullableString>("");
  const [pipeClassRemarks, setPipeClassRemarks] = useState<NullableString>("");

 
  useEffect(() => {
    (async () => {   
      await initPipeClass();
    })()
  }, []);

  const initPipeClass = async () => {

    const idParam = parseInt(urlParams.get("id") ?? "0");
    await referencesStore.fetchPipeClassById(idParam);

    setPipeClassId(idParam);
    setPipeClass(referencesStore.pipeClass?.pipeClass ?? "");
    setPipeClassCode(referencesStore.pipeClass?.pipeClassCode ?? "");
    setPipeClassRemarks(referencesStore.pipeClass?.pipeClassRemarks ?? "");
  }
  
  const handleCloseClick = () => {
    navigate(-1);
  };

  const handleSaveClick = async () => {
    try {
      const pipeClassItem: UpdatePipeClassRequest = {
        id: pipeClassId,
        pipeClass: pipeClass,
        pipeClassCode: pipeClassCode,
        pipeClassRemarks: pipeClassRemarks
      }
      await referencesStore.updatePipeClass(pipeClassItem);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Pipe Class edited successfully");
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error editing Pipe Class: " + error);
    }
    finally {
      notificationStore.setShowNotification(true);
      navigate(ROUTES.referencesPipeClasses);
    }
  }

  return (
    referencesStore.pipeClass ?
    <form>
    <Box
          sx={ areaboxStyle }
        > 
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "#fff",
              display: { xs: "block", md: "flex", width: "50%" }
            }}
          >
            <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
              <ArrowBackOutlinedIcon />
            </Button>
            <Button 
              variant="contained" 
              sx={{ ml: 2 }} 
              disabled={!pipeClass || !pipeClassCode}
              onClick={() => handleSaveClick()}>
              <span>Save</span>
            </Button> 
          </Box>
        </Stack>
      </Box>
    {    
    <Box sx={{ "overflow-y": "scroll", height: "80vh" }}> 
      <Box
          sx={ areaboxStyle }
        >  
        <Typography
            id="add-tag-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
          Pipe Class
        </Typography>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Pipe Class"
            value={pipeClass}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(pipeClass) }}
            onChange={(e) => setPipeClass(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Pipe Class Code"
            value={pipeClassCode}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(pipeClassCode) }}
            onChange={(e) => setPipeClassCode(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Pipe Class Remarks"
            value={pipeClassRemarks}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            onChange={(e) => setPipeClassRemarks(e.target.value)}
          />
        </Stack>
        </Box> 
      </Box>
      }
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={referencesStore.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </form>
      :
      null
  );
});

export default PipeClassItem;
