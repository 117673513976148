import MasterAssetsStore from "../../../../stores/MasterArea/MasterAssetsStore";
import NotificationStore from "../../../../stores/Common/NotificationStore";
import { CreateBBTagRequest } from "../../../../models/TagRequest/request/createBBTagRequest";
import { CheckDuplicateBBTag } from "../../../../models/TagRequest/request/checkDuplicateBBTag";
import { EditBBTagRequest } from "../../../../models/TagRequest/request/editBBTagRequest";
import { WorkingCopyBBTagRequest } from "../../../../models/TagRequest/request/workingCopyBBTagRequest";
import { UpdateCounterRequest } from "../../../../models/MasterArea/request/updateCounterRequest";
import { NOTIFICATION_TYPES } from "../../../../constants/notificationTypes";

export const workingCopyTagRequests = async (masterAssetsStore: MasterAssetsStore, notificationStore: NotificationStore, workingCopy: WorkingCopyBBTagRequest) => {
  try {
    await masterAssetsStore.workingCopyBBTag(workingCopy);
    notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
    notificationStore.setNotificationMessage("Working Copy created successfully");  
  }
  catch(error) {
    notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
    notificationStore.setNotificationMessage("Working Copy cannot be created");
  }
  finally {
    notificationStore.setShowNotification(true);
  }
}

export const deleteTagRequests = async (selectedIds: number[], masterAssetsStore: MasterAssetsStore, notificationStore: NotificationStore) => {
  try {
      await masterAssetsStore.deleteBBTagById(selectedIds);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Tag deleted successfully");  
  }
  catch(error) {
    notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
    notificationStore.setNotificationMessage("Tag(-s) cannot be deleted");
  }
  finally {
    notificationStore.setShowNotification(true);
  }
}

 export const checkDuplicateBBTag = async (masterAssetsStore: MasterAssetsStore, tag: CheckDuplicateBBTag) : Promise<boolean> => {
    try {
      const isDuplicated = await masterAssetsStore.checkDuplicateBBTag(tag);
      return isDuplicated;
    }
    catch(error) {
      console.log(error);
      return true; // if exception - duplicate = true. 
    }
    //return false;
  }

  export const createBBTag = async (masterAssetsStore: MasterAssetsStore, notificationStore: NotificationStore, createTag: CreateBBTagRequest | null) => {
    try {
      await masterAssetsStore.createBBTag(createTag!);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Tag created successfully");   
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error creating tag: " + error);
    }
    finally {
      notificationStore.setShowNotification(true);
    }
  }

  export const createBBTagNoClose = async (masterAssetsStore: MasterAssetsStore, notificationStore: NotificationStore, createTag: CreateBBTagRequest | null) => {
    try {
      const bbTagId = await masterAssetsStore.createBBTagNoClose(createTag!);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Tag created successfully");  
      return bbTagId; 
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error creating tag: " + error);
    }
    finally {
      notificationStore.setShowNotification(true);
    }
  }

  export const editBBTag = async (masterAssetsStore: MasterAssetsStore, notificationStore: NotificationStore, editTag: EditBBTagRequest) => {
    try {
      await masterAssetsStore.editBBTag(editTag);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Tag edited successfully");
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("" + error);
    }
    finally {
      notificationStore.setShowNotification(true);
    }
  }

  export const editBBTagNoClose = async (masterAssetsStore: MasterAssetsStore, notificationStore: NotificationStore, editTag: EditBBTagRequest) => {
    try {
      await masterAssetsStore.editBBTagNoClose(editTag);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Tag edited successfully");
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("" + error);
    }
    finally {
      notificationStore.setShowNotification(true);
    }
  }

  export const updateCounter = async (masterAssetsStore: MasterAssetsStore, notificationStore: NotificationStore, uCounter: UpdateCounterRequest) => {
    try {
      await masterAssetsStore.updateCounter(uCounter);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Counter updated");
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error updating counter: " + error);
    }
    finally {
      notificationStore.setShowNotification(true);
    }
  }