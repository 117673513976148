import { GridSortModel } from "@mui/x-data-grid";
import { GetAssetsMinResponse } from "../../models/ProjectArea/response/getAssetsMinResponse";
import { makeAutoObservable, runInAction } from "mobx";
import {
  AssetsMinFilterOptions,
  AssetsMinSortOptions,
  Filter,
  GetAssetsMinRequest,
} from "../../models/ProjectArea/request/getAssetsMinRequest";
import { SortOrder } from "../../models/common/request/sorting";
import apiClient from "../../api/clients";
import { AssetDetail, AssetDetailHistory, GetAssetDetailResponse } from "../../models/ProjectArea/response/getAssetsDetailResponse";
import { GetRelatedTagsByIdResponse } from "../../models/ProjectArea/response/getRelatedTagsById";
import { GetTagAttributesByIdResponse } from "../../models/ProjectArea/response/getTagAttributesByIdResponse";
import { GetDocumentsByIdResponse } from "../../models/ProjectArea/response/getDocumentsByIdResponse";
import { NullableString } from "../../models/common/response/types";

interface TagValidation {
  id: number;
  validation: NullableString;
  type: NullableString;
  name: NullableString;
}

class ProjectAreaCardViewStore {
  showCardModal = false;
  showDetailLoading = false;
  assetDetail?: AssetDetail;
  relatedTags?: GetRelatedTagsByIdResponse[];
  tagAttributes?: GetTagAttributesByIdResponse[];
  exTagAttributes?: GetTagAttributesByIdResponse[];
  cmmsTagAttributes?: GetTagAttributesByIdResponse[];
  documents?: GetDocumentsByIdResponse[];
  tagValidations: TagValidation[] = [];
  history: AssetDetailHistory[] = [];


  assetsMinResponse: GetAssetsMinResponse = {
    items: [],
    pageIndex: 0,
    pageSize: 0,
    totalPages: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    totalRecords: 0,
  };

  paginationModel = {
    pageSize: 100,
    page: 0,
  };

  sortModel: GridSortModel = [];
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setShowDetailLoading = (showDetailLoading: boolean) => {
    this.showDetailLoading = showDetailLoading;
  };

  setPaginationModel = (paginationModel: any) => {
    this.paginationModel = paginationModel;
  };

  setSortModel = (sortModel: any) => {
    this.sortModel = sortModel;
  };

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  setShowCardModal = (value: boolean) => {
    this.showCardModal = value;
  };

  setHistory = (value: AssetDetailHistory[]) => {
    this.history = value;
  };

  fetchAssetsMin = async (
    prjId?: number,
    tagNo?: string,
    areaId?: number,
    fcltId?: number,
    untId?: number,
    srchTxt?: string,
    isActiveProjects?: boolean
  ) => {
    try {
      const { page, pageSize } = this.paginationModel;

      const filterOptions: AssetsMinFilterOptions = {
        projectNumberId: prjId,
        tagNumber: tagNo,
        areaId: areaId,
        facilityId: fcltId,
        unitId: untId,
        searchText: srchTxt,
        isActiveProjects
      };

      const sortOptions: AssetsMinSortOptions = {
        projectNumber: "projectNumber",
        tagNumber: "tagNumber",
        status: "status",
      };

      const sortField = this.sortModel[0]?.field;
      const sortOrder =
        this.sortModel[0]?.sort === "asc" ? SortOrder.ASC : SortOrder.DESC;
      const sortProperty = sortField
        ? sortOptions[sortField as keyof AssetsMinSortOptions]
        : undefined;
      const filter = Object.keys(filterOptions).reduce((acc, key) => {
        const filterValue = filterOptions[key as keyof AssetsMinFilterOptions];
        if (filterValue !== undefined) {
          acc[key as keyof Filter] = { filterValue: filterValue as never };
        }
        return acc;
      }, {} as Filter);
      const sort = sortProperty
        ? {
            [sortProperty]: { sortOrder },
          }
        : undefined;

      const request: GetAssetsMinRequest = {
        pagination: { pageIndex: page, pageSize },
        filter,
        sort,
      };
      this.setIsLoading(true);

      const data = await apiClient.getAssetsMin(request);

      runInAction(() => {
        this.assetsMinResponse = data;
        this.isLoading = false;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  fetchAssetById = async (id: number) => {
    try {
      const asset = await apiClient.getAssetDetail(id);
      runInAction(() => {
        this.assetDetail = asset;
      });
    } catch (error) {
      console.error("Error fetching Asset:", error);
    }
  };

  getAssetById = async (id: number) : Promise<GetAssetDetailResponse | null> => {
    try {
      const asset = await apiClient.getAssetDetail(id);
      this.assetDetail = asset;
      return asset;
    } catch (error) {
      console.error("Error fetching Asset:", error);
    }
    return null;
  };

  fetchRelatedTagsById = async (id: number, projectId: number) => {
    try {
      const asset = await apiClient.getRelatedTagsById(id, projectId);
      runInAction(() => {
        this.relatedTags = asset;
      });
    } catch (error) {
      console.error("Error fetching Asset:", error);
    }
  };

  fetchTagAttributesById = async (id: number) => {
    try {
      const asset = await apiClient.getTagAttributesById(id, 0);
      runInAction(() => {
        this.tagAttributes = asset;
      });
    } catch (error) {
      console.error("Error fetching Asset:", error);
    }
  };

  fetchExTagAttributesById = async (id: number) => {
    try {
      const asset = await apiClient.getTagAttributesById(id, 1);
      runInAction(() => {
        this.exTagAttributes = asset;
      });
    } catch (error) {
      console.error("Error fetching Asset:", error);
    }
  };

  fetchCmmsTagAttributesById = async (id: number) => {
    try {
      const asset = await apiClient.getTagAttributesById(id, 2);
      runInAction(() => {
        this.cmmsTagAttributes = asset;
      });
    } catch (error) {
      console.error("Error fetching Asset:", error);
    }
  };

  fetchDocumentsById = async (id: number) => {
    try {
      const asset = await apiClient.getDocumentsById(id);
      runInAction(() => {
        this.documents = asset;
      });
    } catch (error) {
      console.error("Error fetching Documents:", error);
    }
  };

  fetchValidationsById = async (id: number): Promise<void> => {
    if (!id) return;

    try {
      const response = await apiClient.getTagValidationsById(id);
      runInAction(() => {
        this.tagValidations = [];
        
        if(response.attributes && response.attributes.length > 0){
          response.attributes.forEach((attribute) => {
            this.tagValidations.push({validation: "Attribute", id: attribute.id, type: attribute.type, name: attribute.name});
          });
        }

        if(response.documents && response.documents.length > 0){
          response.documents.forEach((document) => {
            this.tagValidations.push({validation: "Document", id: document.id, type: document.type, name: document.name});
          });
        }

        if(response.relations && response.relations.length > 0){
          response.relations.forEach((relation) => {
            this.tagValidations.push({validation: "Relation", id: relation.id, type: relation.type, name: relation.name});
          });
        }

        if(response.equipments && response.equipments.length > 0){
          response.equipments.forEach((equipment) => {
            this.tagValidations.push({validation: "Equipment", id: 0, type: equipment.type, name: equipment.name});
          });
        }
      });
    } catch (error) {
      console.error("Error while getting tag validation details:", error);
    }
  }
}

export default ProjectAreaCardViewStore;
