import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Popover,
  TextField,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useStores } from "../../../../hooks/useStores";
import { useDebounce } from "../../../../hooks/useDebounce";
import { SelectOption } from "../../../../models/common/selectOption";
import { FILTERS } from "../../../../constants/filters";

interface CustomFilterButtonMasterAreaProps {
  applyFilter: boolean;
}

const CustomFilterButtonMasterArea = observer((props: CustomFilterButtonMasterAreaProps) => {
  
  const { masterAssetsStore, dictionaryStore, masterAreaGridViewExportStore } = useStores();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [serviceDescriptionInput, setServiceDescriptionInput] = useState("");
  const [sequenceNumberInput, setSequenceNumberInput] = useState("");
  const [remarksInput, setRemarksInput] = useState("");
  const [requestNumberInput, setRequestNumberInput] = useState("");
  const [suffixInput, setSuffixInput] = useState("");
  const [tagNumberInput, setTagNumberInput] = useState("");
  const [parentTagNumberInput, setParentTagNumberInput] = useState("");

  useEffect(() => {
    dictionaryStore.fetchProjectOptions('');
    dictionaryStore.fetchTagNumberOptions('');
    dictionaryStore.fetchEquipmentStatusOptions('');
    dictionaryStore.fetchFacilityOptions('');
    dictionaryStore.fetchAreaOptions('');
    dictionaryStore.fetchUnitOptions('');
    dictionaryStore.fetchTagCategoryOptions('');
    dictionaryStore.fetchParentTagNumberOptions('');
    dictionaryStore.fetchTagTypeOptions('');
   

    initFilters();
  }, [props.applyFilter]);

  const initFilters = () => {
    initFilterOption(FILTERS.projectNumberId, FILTERS.projectNumberValue, masterAssetsStore.setSelectedProjectOption, masterAreaGridViewExportStore.setSelectedProjectOption);
    initFilterOption(FILTERS.tagNumberId, FILTERS.tagNumberValue, masterAssetsStore.setSelectedTagNumberOption, masterAreaGridViewExportStore.setSelectedTagNumberOption);
    initFilterOption(FILTERS.statusId, FILTERS.statusValue, masterAssetsStore.setSelectedEquipmentStatusOption, masterAreaGridViewExportStore.setSelectedEquipmentStatusOption);
    initFilterOption(FILTERS.facilityId, FILTERS.facilityValue, masterAssetsStore.setSelectedFacilityOption, masterAreaGridViewExportStore.setSelectedFacilityOption);
    initFilterOption(FILTERS.areaId, FILTERS.areaValue, masterAssetsStore.setSelectedAreaOption, masterAreaGridViewExportStore.setSelectedAreaOption);
    initFilterOption(FILTERS.unitId, FILTERS.unitValue, masterAssetsStore.setSelectedUnitOption, masterAreaGridViewExportStore.setSelectedUnitOption);
    initFilterOption(FILTERS.categoryId, FILTERS.categoryValue, masterAssetsStore.setSelectedCategoryOption, masterAreaGridViewExportStore.setSelectedTagCategoryOption);
    initFilterValue(FILTERS.serviceDescriptionValue, masterAssetsStore.setSelectedServiceDescriptionOption, masterAreaGridViewExportStore.setSelectedServiceDescriptionOption, setServiceDescriptionInput);
    initFilterOption(FILTERS.typeId, FILTERS.typeValue, masterAssetsStore.setSelectedTagTypeOption, masterAreaGridViewExportStore.setSelectedTagTypeOption);
    initFilterValue(FILTERS.parentTagNumberValue, masterAssetsStore.setSelectedParentTagNumberOption, masterAreaGridViewExportStore.setSelectedParentTagNumberOption, setParentTagNumberInput);
    initFilterValue(FILTERS.sequenceNumberValue, masterAssetsStore.setSelectedSequenceNumberOption, masterAreaGridViewExportStore.setSelectedSequenceNumberOption, setSequenceNumberInput);
    initFilterValue(FILTERS.remarksValue, masterAssetsStore.setSelectedRemarksOption, masterAreaGridViewExportStore.setSelectedRemarksOption, setRemarksInput);
    initFilterValue(FILTERS.requestNumberValue, masterAssetsStore.setSelectedRequestNumberOption, masterAreaGridViewExportStore.setSelectedRequestNumberOption, setRequestNumberInput);
    initFilterValue(FILTERS.suffixValue, masterAssetsStore.setSelectedSuffixOption, masterAreaGridViewExportStore.setSelectedSuffixOption, setSuffixInput);
  }

  const initFilterOption = (filterIdInLocalStorage: string, filterValueInLocalStorage: string, setFilterOption: any, setExportFilterOption: any) => {
    const selectedOptionId = localStorage.getItem(filterIdInLocalStorage) || 0;
    const selectedOptionValue = localStorage.getItem(filterValueInLocalStorage) || "";
    if(selectedOptionId && selectedOptionValue && selectedOptionId !== "undefined" && selectedOptionValue !== "undefined") {
      const selectedOption: SelectOption = {id: parseInt(selectedOptionId), label: selectedOptionValue};
      setFilterOption(selectedOption);
      setExportFilterOption(selectedOption)
    }
  }

  const initFilterValue = (filterValueInLocalStorage: string, setFilterOption: any, setExportFilterOption: any, setInputValue: any) => {
    const value = localStorage.getItem(filterValueInLocalStorage) || "";
    if(value && value !== "undefined") {
      setFilterOption(value);
      setExportFilterOption(value);
      setInputValue(value);
    }
  }

  const saveFilterOption = (option: SelectOption | null, filterIdInLocalStorage: string, filterValueInLocalStorage: string, setFilterOption: any, setExportFilterOption: any) => {
    setFilterOption(option);
    setExportFilterOption(option);
    localStorage.setItem(filterIdInLocalStorage, option?.id + "");
    localStorage.setItem(filterValueInLocalStorage, option?.label ?? "");
  }

  const saveFilterValue = (value: string, filterValueInLocalStorage: string, setFilterOption: any, setExportFilterOption: any) => {
    setFilterOption(value);
    setExportFilterOption(value);
    localStorage.setItem(filterValueInLocalStorage, value);
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  const debouncedHandleInputChange = useDebounce(500);

  const handleProjectInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchProjectOptions(inputValue);
    });
  };

  const handleAreaInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchAreaOptions(inputValue);
    });
  };

  const handleUnitInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchUnitOptions(inputValue);
    });
  };

  const handleTagCategoryInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchTagCategoryOptions(inputValue);
    });
  };

  const handleFacilityInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchFacilityOptions(inputValue);
    });
  };

  const handleTagNumberInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchMasterTagNumberOptions(inputValue);
    });
  };

  const handleParentTagNumberInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchParentTagNumberOptions(inputValue);
    });
  };

  const handleEquipmentStatusInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchEquipmentStatusOptions(inputValue);
    });
  };

  const handleTagTypeInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchTagTypeOptions(inputValue);
    });
  };

  const handleTagNumberKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      const inputValue = (event.target as HTMLInputElement).value;

      masterAssetsStore.setSelectedTagNumberOption({
        id: 0,
        label: inputValue,
      });
      masterAreaGridViewExportStore.setSelectedTagNumberOption({
        id: 0,
        label: inputValue,
      });
    }
  };

  const resetFilters = () => {
    saveFilterOption(null, FILTERS.projectNumberId, FILTERS.projectNumberValue, masterAssetsStore.setSelectedProjectOption, masterAreaGridViewExportStore.setSelectedProjectOption);
    saveFilterOption(null, FILTERS.statusId, FILTERS.statusValue, masterAssetsStore.setSelectedEquipmentStatusOption, masterAreaGridViewExportStore.setSelectedEquipmentStatusOption);
    saveFilterOption(null, FILTERS.tagNumberId, FILTERS.tagNumberValue, masterAssetsStore.setSelectedTagNumberOption, masterAreaGridViewExportStore.setSelectedTagNumberOption);
    saveFilterOption(null, FILTERS.facilityId, FILTERS.facilityValue, masterAssetsStore.setSelectedFacilityOption, masterAreaGridViewExportStore.setSelectedFacilityOption);
    saveFilterOption(null, FILTERS.areaId, FILTERS.areaValue, masterAssetsStore.setSelectedAreaOption, masterAreaGridViewExportStore.setSelectedAreaOption);
    saveFilterOption(null, FILTERS.unitId, FILTERS.unitValue, masterAssetsStore.setSelectedUnitOption, masterAreaGridViewExportStore.setSelectedUnitOption);
    saveFilterOption(null, FILTERS.categoryId, FILTERS.categoryValue, masterAssetsStore.setSelectedCategoryOption, masterAreaGridViewExportStore.setSelectedTagCategoryOption);
    saveFilterOption(null, FILTERS.typeId, FILTERS.typeValue, masterAssetsStore.setSelectedTagTypeOption, masterAreaGridViewExportStore.setSelectedTagTypeOption);
    saveFilterOption(null, FILTERS.parentTagNumberId, FILTERS.parentTagNumberValue, masterAssetsStore.setSelectedParentTagNumberOption, masterAreaGridViewExportStore.setSelectedParentTagNumberOption);
    saveFilterValue("", FILTERS.serviceDescriptionValue, masterAssetsStore.setSelectedServiceDescriptionOption, masterAreaGridViewExportStore.setSelectedServiceDescriptionOption);
    saveFilterValue("", FILTERS.sequenceNumberValue, masterAssetsStore.setSelectedSequenceNumberOption, masterAreaGridViewExportStore.setSelectedSequenceNumberOption);
    saveFilterValue("", FILTERS.remarksValue, masterAssetsStore.setSelectedRemarksOption, masterAreaGridViewExportStore.setSelectedRemarksOption);
    saveFilterValue("", FILTERS.requestNumberValue, masterAssetsStore.setSelectedRequestNumberOption, masterAreaGridViewExportStore.setSelectedRequestNumberOption);
    saveFilterValue("", FILTERS.suffixValue, masterAssetsStore.setSelectedSuffixOption, masterAreaGridViewExportStore.setSelectedSuffixOption);

    setServiceDescriptionInput("");
    setSequenceNumberInput("");
    setRemarksInput("");
    setRequestNumberInput("");
    setSuffixInput("");
    setTagNumberInput("");
    setParentTagNumberInput("");
  };

  const handleResetButtonClick = () => {
    resetFilters();
  };

  return (
    <>
      <Button
        startIcon={<FilterListIcon />}
        variant="text"
        size="small"
        onClick={handleClick}
      >
        <span>Filter</span>
      </Button>
      <Popover
        keepMounted
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ mt: "5px" }}
      >
        <Box
          sx={{
            pl: 2,
            pt: 2,
            pr: 2,
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
          }}
        >
          <Autocomplete
            size="small"
            id="projectNumber"
            options={dictionaryStore.projectOptions}
            value={masterAssetsStore.selectedProjectOption}
            getOptionLabel={(option) => option.label}
            sx={{
              width: 178,
              mr: 1,
              mb: 1,
            }}
            renderInput={(params) => (
              <TextField {...params} label="Project Number" />
            )}
            onInputChange={(event, newInputValue) => {
              handleProjectInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              saveFilterOption(selectedValue, FILTERS.projectNumberId, FILTERS.projectNumberValue, masterAssetsStore.setSelectedProjectOption, masterAreaGridViewExportStore.setSelectedProjectOption);
  
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <Autocomplete
            id="tagNumber"
            size="small"
            freeSolo
            sx={{ width: 178, mr: 1, mb: 1 }}
            options={dictionaryStore.masterTagNumberOptions}
            value={masterAssetsStore.selectedTagNumberOption}
            inputValue={tagNumberInput}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tag Number"
                onKeyDown={handleTagNumberKeyDown}
                onChange={(event) => setTagNumberInput(event.target.value)}
              />
            )}
            onInputChange={(event, newInputValue) => {
              handleTagNumberInputChange(newInputValue);
              setTagNumberInput(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              saveFilterOption(selectedValue, FILTERS.tagNumberId, FILTERS.tagNumberValue, 
                masterAssetsStore.setSelectedTagNumberOption, masterAreaGridViewExportStore.setSelectedTagNumberOption);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <Autocomplete
            size="small"
            id="status"
            options={dictionaryStore.equipmentStatusOptions}
            value={masterAssetsStore.selectedEquipmentStatusOption}
            getOptionLabel={(option) => option.label}
            sx={{
              width: 178,
              mr: 1,
              mb: 1,
            }}
            renderInput={(params) => <TextField {...params} label="Status" />}
            onInputChange={(event, newInputValue) => {
              handleEquipmentStatusInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              saveFilterOption(selectedValue, FILTERS.statusId, FILTERS.statusValue, 
                masterAssetsStore.setSelectedEquipmentStatusOption, masterAreaGridViewExportStore.setSelectedEquipmentStatusOption);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <Autocomplete
            size="small"
            id="facility"
            options={dictionaryStore.facilityOptions}
            value={masterAssetsStore.selectedFacilityOption}
            getOptionLabel={(option) => option.label}
            sx={{ width: 178, mr: 1, mb: 1 }}
            renderInput={(params) => <TextField {...params} label="Facility" />}
            onInputChange={(event, newInputValue) => {
              handleFacilityInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              saveFilterOption(selectedValue, FILTERS.facilityId, FILTERS.facilityValue, 
                masterAssetsStore.setSelectedFacilityOption, masterAreaGridViewExportStore.setSelectedFacilityOption);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Box>
        <Box
          sx={{
            pl: 2,
            pr: 2,
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
          }}
        >
          <Autocomplete
            size="small"
            id="area"
            options={dictionaryStore.areaOptions}
            value={masterAssetsStore.selectedAreaOption}
            getOptionLabel={(option) => option.label}
            sx={{
              width: 178,
              mr: 1,
              mb: 1,
            }}
            renderInput={(params) => <TextField {...params} label="Area" />}
            onInputChange={(event, newInputValue) => {
              handleAreaInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              saveFilterOption(selectedValue, FILTERS.areaId, FILTERS.areaValue, 
                masterAssetsStore.setSelectedAreaOption, masterAreaGridViewExportStore.setSelectedAreaOption);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <Autocomplete
            size="small"
            id="unit"
            options={dictionaryStore.unitOptions}
            value={masterAssetsStore.selectedUnitOption}
            getOptionLabel={(option) => option.label}
            sx={{ width: 178, mr: 1, mb: 1 }}
            renderInput={(params) => <TextField {...params} label="Unit " />}
            onInputChange={(event, newInputValue) => {
              handleUnitInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              saveFilterOption(selectedValue, FILTERS.unitId, FILTERS.unitValue, 
                masterAssetsStore.setSelectedUnitOption, masterAreaGridViewExportStore.setSelectedUnitOption);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <Autocomplete
            size="small"
            id="category"
            options={dictionaryStore.tagCategoryOptions}
            value={masterAssetsStore.selectedCategoryOption}
            getOptionLabel={(option) => option.label}
            sx={{ width: 178, mr: 1, mb: 1 }}
            renderInput={(params) => <TextField {...params} label="Category" />}
            onInputChange={(event, newInputValue) => {
              handleTagCategoryInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              saveFilterOption(selectedValue, FILTERS.categoryId, FILTERS.categoryValue, 
                masterAssetsStore.setSelectedCategoryOption, masterAreaGridViewExportStore.setSelectedTagCategoryOption);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <TextField
            id="serviceDescription"
            label="Service Description"
            size="small"
            sx={{ width: 178, mr: 1, mb: 1 }}
            value={serviceDescriptionInput}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setServiceDescriptionInput(selectedValue);
              saveFilterValue(selectedValue, FILTERS.serviceDescriptionValue, 
                masterAssetsStore.setSelectedServiceDescriptionOption, masterAreaGridViewExportStore.setSelectedServiceDescriptionOption);
            }}
          />
        </Box>
        <Box
          sx={{
            pl: 2,
            pr: 2,
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
          }}
        >
          <Autocomplete
            size="small"
            id="tagType"
            options={dictionaryStore.tagTypeOptions}
            value={masterAssetsStore.selectedTagTypeOption}
            getOptionLabel={(option) => option.label}
            sx={{ width: 178, mr: 1, mb: 1 }}
            renderInput={(params) => <TextField {...params} label="Type" />}
            onInputChange={(event, newInputValue) => {
              handleTagTypeInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              saveFilterOption(selectedValue, FILTERS.typeId, FILTERS.typeValue, 
                masterAssetsStore.setSelectedTagTypeOption, masterAreaGridViewExportStore.setSelectedTagTypeOption);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <Autocomplete
            size="small"
            id="parentTagNumber"
            freeSolo
            options={dictionaryStore.parentTagNumberOptions}
            value={masterAssetsStore.selectedParentTagNumberOption}
            inputValue={parentTagNumberInput}
            getOptionLabel={(option) => option.label}
            sx={{ width: 178, mr: 1, mb: 1 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Parent Tag Number"
                onChange={(event) =>
                  setParentTagNumberInput(event.target.value)
                }
              />
            )}
            onInputChange={(event, newInputValue) => {
              handleParentTagNumberInputChange(newInputValue);
              setParentTagNumberInput(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              saveFilterOption(selectedValue, FILTERS.parentTagNumberId, FILTERS.parentTagNumberValue, 
                masterAssetsStore.setSelectedParentTagNumberOption, masterAreaGridViewExportStore.setSelectedParentTagNumberOption);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <TextField
            id="sequenceNumber"
            label="Sequence Number"
            size="small"
            sx={{ width: 178, mr: 1, mb: 1 }}
            value={sequenceNumberInput}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setSequenceNumberInput(selectedValue);
              saveFilterValue(selectedValue, FILTERS.sequenceNumberValue, 
                masterAssetsStore.setSelectedSequenceNumberOption, masterAreaGridViewExportStore.setSelectedSequenceNumberOption);
            }}
          />
          <TextField
            id="remarks"
            label="Remarks"
            size="small"
            sx={{ width: 178, mr: 1, mb: 1 }}
            value={remarksInput}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setRemarksInput(selectedValue);
              saveFilterValue(selectedValue, FILTERS.remarksValue, 
                masterAssetsStore.setSelectedRemarksOption, masterAreaGridViewExportStore.setSelectedRemarksOption);
            }}
          />
        </Box>
        <Box
          sx={{
            pl: 2,
            pr: 2,
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
          }}
        >
          <TextField
            id="requestNumber"
            label="Request Number"
            size="small"
            sx={{ width: 178, mr: 1, mb: 1 }}
            value={requestNumberInput}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setRequestNumberInput(selectedValue);
              saveFilterValue(selectedValue, FILTERS.requestNumberValue, 
                masterAssetsStore.setSelectedRequestNumberOption, masterAreaGridViewExportStore.setSelectedRequestNumberOption);
            }}
          />
          <TextField
            id="suffix"
            label="Suffix"
            size="small"
            sx={{ width: 178, mr: 1, mb: 1 }}
            value={suffixInput}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setSuffixInput(selectedValue);
              saveFilterValue(selectedValue, FILTERS.suffixValue, 
                masterAssetsStore.setSelectedSuffixOption, masterAreaGridViewExportStore.setSelectedSuffixOption);
            }}
          />
        </Box>
        <Divider />
        <Box
          sx={{
            pt: 1,
            pl: 2,
            pr: 2,
            pb: 1,
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
          }}
        >
          <Button variant="contained" onClick={handleResetButtonClick}>
            Clear
          </Button>
        </Box>
      </Popover>
    </>
  );
});

export default CustomFilterButtonMasterArea;
