import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import {
  Box,
  Button,
  TextField,
  Stack,
  Typography,
  Backdrop,
  CircularProgress,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { useState, useEffect } from "react";
import usePageTitle from "../../../hooks/usePageTitle";
import { areaboxStyle} from "../../../constants/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { NOTIFICATION_TYPES } from "../../../constants/notificationTypes";
import { NullableNumber, NullableString } from "../../../models/common/response/types";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { UpdateSequenceRequest } from "../../../models/Dictionaries/request/updateSequenceRequest";
import { validationColor, validationColorForNumberField } from "../../../utils/validation";

const SequenceItem = observer(() => {
  usePageTitle("References - Sequence");

  const navigate = useNavigate();

  const [urlParams] = useSearchParams()

  const { referencesStore, notificationStore } = useStores();
  
  const [sequenceId, setSequenceId] = useState<number>(0);
  const [sequence, setSequence] = useState<NullableString>("");
  const [sequenceValue, setSequenceValue] = useState<NullableNumber>(null);
  const [sequenceIsLocked, setSequenceIsLocked] = useState<boolean>(false);

 
  useEffect(() => {
    (async () => {   
      await initSequence();
    })()
  }, []);

  const initSequence = async () => {

    const idParam = parseInt(urlParams.get("id") ?? "0");
    await referencesStore.fetchSequenceById(idParam);

    setSequenceId(idParam);
    setSequence(referencesStore.sequence?.sequence ?? "");
    setSequenceValue(referencesStore.sequence?.value);
    setSequenceIsLocked(referencesStore.sequence?.isLocked ?? false);
  }
  
  const handleCloseClick = () => {
    navigate(-1);
  };

  const handleSaveClick = async () => {
    try {
      const sequenceItem: UpdateSequenceRequest = {
        id: sequenceId,
        sequence: sequence,
        value: sequenceValue,
        locked: sequenceIsLocked
      }
      await referencesStore.updateSequence(sequenceItem);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Sequence edited successfully");
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error editing sequence: " + error);
    }
    finally {
      notificationStore.setShowNotification(true);
      navigate(ROUTES.referencesSequences);
    }
  }

  return (
    referencesStore.sequence ?
    <form>
    <Box
          sx={ areaboxStyle }
        > 
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "#fff",
              display: { xs: "block", md: "flex", width: "50%" }
            }}
          >
            <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
              <ArrowBackOutlinedIcon />
            </Button>
            <Button 
              variant="contained" 
              sx={{ ml: 2 }} 
              disabled={!sequence || !sequenceValue}
              onClick={() => handleSaveClick()}>
              <span>Save</span>
            </Button> 
          </Box>
        </Stack>
      </Box>
    {    
    <Box sx={{ "overflow-y": "scroll", height: "80vh" }}> 
      <Box
          sx={ areaboxStyle }
        >  
        <Typography
            id="add-tag-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
          Sequence
        </Typography>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Sequence"
            value={sequence}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(sequence) }}
            onChange={(e) => setSequence(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Value"
            type="number"
            value={sequenceValue}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColorForNumberField(sequenceValue) }}
            onChange={(e) => setSequenceValue(parseInt(e.target.value))}
          />
        </Stack>
        <Stack direction="row">
        <FormControlLabel control={
              <Checkbox defaultChecked={false} onChange={(e) => setSequenceIsLocked(e.target.checked) } />
            } label="Is Locked"  sx={{ width: "50%" }} 
          /> 
        </Stack>
        </Box> 
      </Box>
      }
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={referencesStore.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </form>
      :
      null
  );
});

export default SequenceItem;
