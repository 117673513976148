import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { useStores } from "../../../hooks/useStores";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import {
  exRegisterAttributeStyle,
  largeTextFieldStyle,
  photoStyle,
  smallTextFieldStyle,
  textFieldStyle,
} from "../../projectArea/cardView/styles/styles";
import CustomTabPanel from "../../projectArea/cardView/components/CustomTabPanel";
import { ExRegisterAttributes } from "../../../models/ExRegister/response/getExRegisterAttributesByIdResponse";
import { UpdateExRegisterAttributesRequest } from "../../../models/ExRegister/request/updateExRegisterAttributesRequest";
import { getRefValue } from "../../../utils/getRefValue";
import { NOTIFICATION_TYPES } from "../../../constants/notificationTypes";
import { SelectOption } from "../../../models/common/selectOption";
import { useDebounce } from "../../../hooks/useDebounce";
import { processString } from "../../MasterArea/gridView/utils/utils";
import ExRegisterDocumentationTab from "./ExRegisterDocumentationTab";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ExRegisterDetails = observer(() => {
  const [value, setValue] = useState(0);

  const {
    exRegisterStore: {
      exRegisterAttributes,
      updateExRegisterAttributes,
      fetchExRegisterAttributesById,
    },
    dictionaryStore,
    notificationStore,
    masterAreaDocumentsStore,
  } = useStores();

  const serviceDescriptionRef = useRef<HTMLInputElement>(null);
  const manufacturerRef = useRef<HTMLInputElement>(null);
  const modelNumberRef = useRef<HTMLInputElement>(null);
  const serialNoRef = useRef<HTMLInputElement>(null);
  const iecExOrECEURef = useRef<HTMLInputElement>(null);
  const iecEAtexRef = useRef<HTMLInputElement>(null);
  const eacExCertificateNoRef = useRef<HTMLInputElement>(null);
  const exProtectionConceptRef = useRef<HTMLInputElement>(null);
  const ambTempRangeRef = useRef<HTMLInputElement>(null);
  const exSpecialConditionsRef = useRef<HTMLInputElement>(null);
  const exInspectionRequiredRef = useRef<HTMLInputElement>(null);
  const isBarrierCertBodyRef = useRef<HTMLInputElement>(null);
  const isBarrierExCertNumberRef = useRef<HTMLInputElement>(null);
  const isBarrierManufacturerRef = useRef<HTMLInputElement>(null);
  const isBarrierModelRef = useRef<HTMLInputElement>(null);

  const [selectedIpRatingOption, setSelectedIpRatingOption] =
    useState<SelectOption | null>(null);
  const [selectedHacsOption, setSelectedHacsOption] = useState<SelectOption | null>(null);
  const [selectedExGasGroupsOption, setSelectedExGasGroupsOption] =
    useState<SelectOption | null>(null);
  const [selectedEquipmentZonesOption, setSelectedEquipmentZonesOption] =
    useState<SelectOption | null>(null);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    dictionaryStore.fetchIpRatings("");
    dictionaryStore.fetchHacs("");
    dictionaryStore.fetchExGasGroups("");
    dictionaryStore.fetchEquipmentZones("");
  }, []);

  const prepareUpdateData = () => {
    const tagId = exRegisterAttributes?.id!;
    const serviceDescription = exRegisterAttributes?.serviceDescription!;
    const manufacturer = exRegisterAttributes?.manufacturer!;
    const modelNumber = exRegisterAttributes?.modelNumber!;
    const serialNo = exRegisterAttributes?.serialNo!;
    const iecExOrECEU = exRegisterAttributes?.iecExOrECEU!;
    const ieceAtex = exRegisterAttributes?.ieceAtex!;
    const eacExCertificateNo = exRegisterAttributes?.eacExCertificateNo!;
    const exProtectionConcept = exRegisterAttributes?.exProtectionConcept!;
    const ambTempRange = exRegisterAttributes?.ambTempRange!;
    const exSpecialConditions = exRegisterAttributes?.exSpecialConditions!;
    const exInspectionRequired = exRegisterAttributes?.exInspectionRequired!;
    const isBarrierCertBody = exRegisterAttributes?.isBarrierCertBody!;
    const isBarrierExCertNumber = exRegisterAttributes?.isBarrierExCertNumber!;
    const isBarrierManufacturer = exRegisterAttributes?.isBarrierManufacturer!;
    const isBarrierModel = exRegisterAttributes?.isBarrierModel!;
    const ipRating = selectedIpRatingOption?.id ?? null;
    const hac = selectedHacsOption?.id ?? null;
    const exGasGroup = selectedExGasGroupsOption?.id ?? null;
    const equipmentZone = selectedEquipmentZonesOption?.id ?? null;

    return {
      tagId,
      serviceDescription,
      manufacturer,
      modelNumber,
      serialNo,
      iecExOrECEU,
      ieceAtex,
      eacExCertificateNo,
      exProtectionConcept,
      ambTempRange,
      exSpecialConditions,
      exInspectionRequired,
      isBarrierCertBody,
      isBarrierExCertNumber,
      isBarrierManufacturer,
      isBarrierModel,
      ipRating,
      hac,
      exGasGroup,
      equipmentZone,
    };
  };

  const handleUpdateSuccess = (id: number) => {
    const successMessage = "EX Register Attributes updated successfully.";
    notificationStore.setNotificationMessage(successMessage);
    notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
    notificationStore.setShowNotification(true);
    fetchExRegisterAttributesById(id);
  };

  const handleUpdateError = () => {
    const errorMessage = "Error while updating attributes. Please try again later.";
    notificationStore.setNotificationMessage(errorMessage);
    notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
    notificationStore.setShowNotification(true);
  };

  const handleUpdate = async (data: UpdateExRegisterAttributesRequest) => {
    const id = data.tagId;
    const res = await updateExRegisterAttributes(data);
    const success = res?.status === 200;

    if (success) {
      handleUpdateSuccess(id);
    } else {
      handleUpdateError();
    }
  };

  const handleSaveButtonClick = async () => {
    debugger;
    const data = prepareUpdateData();
    await handleUpdate(data);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const debouncedHandleInputChange = useDebounce(500);

  const handleIpRatingInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchIpRatings(inputValue);
    });
  };

  const handleHacInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchHacs(inputValue);
    });
  };

  const handleEquipmentZoneInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchEquipmentZones(inputValue);
    });
  };

  const handleExGasGroupInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchExGasGroups(inputValue);
    });
  };

  return exRegisterAttributes ? (
    <>
      <Box>
        <Tabs
          value={value}
          onChange={handleTabChange}
          variant="scrollable"
          aria-label="Detail-Scrollable-Tags"
          sx={{ "& .MuiTabScrollButton-horizontal": { width: "25px" } }}
        >
          <Tab label="ATTRIBUTES" {...a11yProps(0)} />
          <Tab label="DOCUMENTATION" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Box sx={{ width: "100%", marginBottom: 4 }}>
        <CustomTabPanel value={value} index={0}>
          <Stack
            direction="column"
            sx={{
              paddingTop: "1%",
              backgroundColor: "#e8f4d9",
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              label="Name"
              value={exRegisterAttributes.tagNumber ?? "-"}
              sx={photoStyle}
            />
            <TextField
              variant="outlined"
              size="small"
              label="Service Description"
              value={exRegisterAttributes.serviceDescription}
              onChange={(e) => (exRegisterAttributes.serviceDescription = e.target.value)}
              sx={photoStyle}
            />
            <TextField
              variant="outlined"
              size="small"
              label="Manufacturer"
              value={exRegisterAttributes.manufacturer ?? "-"}
              onChange={(e) => (exRegisterAttributes.manufacturer = e.target.value)}
              sx={photoStyle}
            />
            <TextField
              variant="outlined"
              size="small"
              label="Model Number"
              value={exRegisterAttributes.modelNumber ?? "-"}
              onChange={(e) => (exRegisterAttributes.modelNumber = e.target.value)}
              sx={photoStyle}
            />
            <TextField
              variant="outlined"
              size="small"
              label="Serial No"
              value={exRegisterAttributes.serialNo ?? "-"}
              onChange={(e) => (exRegisterAttributes.serialNo = e.target.value)}
              sx={exRegisterAttributeStyle}
            />
          </Stack>
          <Stack
            direction="column"
            sx={{
              paddingTop: "1%",
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              label="IECEx or EC/EU Type Examination Certificate"
              value={exRegisterAttributes.iecExOrECEU ?? "-"}
              onChange={(e) => (exRegisterAttributes.iecExOrECEU = e.target.value)}
              sx={photoStyle}
            />
            <TextField
              variant="outlined"
              size="small"
              label="IECEx/'ATEX' 'Certified Body' (NoBo) reference"
              value={exRegisterAttributes.ieceAtex ?? "-"}
              onChange={(e) => (exRegisterAttributes.ieceAtex = e.target.value)}
              sx={photoStyle}
            />
            <TextField
              variant="outlined"
              size="small"
              label="EACEx Certificate No"
              value={exRegisterAttributes.eacExCertificateNo ?? "-"}
              onChange={(e) => (exRegisterAttributes.eacExCertificateNo = e.target.value)}
              sx={photoStyle}
            />
            <TextField
              variant="outlined"
              size="small"
              label="Ex Protection Concept"
              value={exRegisterAttributes.exProtectionConcept ?? "-"}
              onChange={(e) =>
                (exRegisterAttributes.exProtectionConcept = e.target.value)
              }
              sx={photoStyle}
            />
            <Autocomplete
              size="small"
              id="IpRating"
              options={dictionaryStore.ipRatingOptions ?? []}
              value={selectedIpRatingOption ?? null}
              getOptionLabel={(option) => option.label}
              sx={photoStyle}
              renderInput={(params) => <TextField {...params} label="IP Rating" />}
              onInputChange={(event, newInputValue) => {
                handleIpRatingInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setSelectedIpRatingOption(selectedValue);
                exRegisterAttributes.ipRating = selectedValue ? selectedValue.id : null;
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            {/* 
            <TextField
              variant="outlined"
              size="small"
              label="Equipment Gas Group"
              value={exRegisterAttributes.equipmentGasGroup ?? "-"}
              sx={smallTextFieldStyle}
            /> */}

            <Autocomplete
              size="small"
              id="HacId"
              options={dictionaryStore.exGasGroupsOptions ?? []}
              value={selectedExGasGroupsOption ?? null}
              getOptionLabel={(option) => option.label}
              sx={photoStyle}
              renderInput={(params) => (
                <TextField {...params} label="Equipment Gas Group" />
              )}
              onInputChange={(event, newInputValue) => {
                handleExGasGroupInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setSelectedExGasGroupsOption(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            <TextField
              variant="outlined"
              size="small"
              label="Equipment Temp Class"
              value={exRegisterAttributes.equipmentTempClass ?? "-"}
              sx={photoStyle}
            />
            <TextField
              variant="outlined"
              size="small"
              label="Amb Temp Range"
              value={exRegisterAttributes.ambTempRange ?? "-"}
              onChange={(e) => (exRegisterAttributes.ambTempRange = e.target.value)}
              sx={photoStyle}
            />
            <TextField
              variant="outlined"
              size="small"
              label="Ex Special Conditions"
              value={exRegisterAttributes.exSpecialConditions ?? "-"}
              onChange={(e) =>
                (exRegisterAttributes.exSpecialConditions = e.target.value)
              }
              sx={photoStyle}
            />
            <TextField
              variant="outlined"
              size="small"
              label="Ex Inspection Required"
              value={exRegisterAttributes.exInspectionRequired ?? "-"}
              onChange={(e) =>
                (exRegisterAttributes.exInspectionRequired = e.target.value)
              }
              sx={photoStyle}
            />
          </Stack>
          <Stack
            direction="column"
            sx={{
              paddingTop: "1%",
              backgroundColor: "#cce4f6",
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              label="IS Barrier Cert Body"
              value={exRegisterAttributes.isBarrierCertBody ?? "-"}
              onChange={(e) => (exRegisterAttributes.isBarrierCertBody = e.target.value)}
              sx={photoStyle}
            />
            <TextField
              variant="outlined"
              size="small"
              label="IS Barrier Ex Cert Number"
              value={exRegisterAttributes.isBarrierExCertNumber ?? "-"}
              onChange={(e) =>
                (exRegisterAttributes.isBarrierExCertNumber = e.target.value)
              }
              sx={photoStyle}
            />
            <TextField
              variant="outlined"
              size="small"
              label="IS Barrier Manufacturer"
              value={exRegisterAttributes.isBarrierManufacturer ?? "-"}
              onChange={(e) =>
                (exRegisterAttributes.isBarrierManufacturer = e.target.value)
              }
              sx={photoStyle}
            />
            <TextField
              variant="outlined"
              size="small"
              label="IS Barrier Model"
              value={exRegisterAttributes.isBarrierModel ?? "-"}
              onChange={(e) => (exRegisterAttributes.isBarrierModel = e.target.value)}
              sx={exRegisterAttributeStyle}
            />
          </Stack>
          <Stack
            direction="column"
            sx={{
              paddingTop: "1%",
              backgroundColor: "#f1f7b5",
            }}
          >
            {/* <TextField
              variant="outlined"
              size="small"
              label="Zone Equipment is suitable for"
              value={exRegisterAttributes.zoneEquipmentIsSuitableFor ?? "-"}
              sx={smallTextFieldStyle}
            /> */}
            <Autocomplete
              size="small"
              id="EquipmentZoneId"
              options={dictionaryStore.equipmentZoneOptions ?? []}
              value={selectedEquipmentZonesOption ?? null}
              getOptionLabel={(option) => option.label}
              sx={photoStyle}
              renderInput={(params) => (
                <TextField {...params} label="Zone Equipment is suitable for" />
              )}
              onInputChange={(event, newInputValue) => {
                handleEquipmentZoneInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setSelectedEquipmentZonesOption(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            {/* <TextField
              variant="outlined"
              size="small"
              label="Hazardous Area Classification"
              value={exRegisterAttributes.hazardousAreaClassification ?? "-"}
              sx={smallTextFieldStyle}
            /> */}
            <Autocomplete
              size="small"
              id="HacId"
              options={dictionaryStore.hacsOptions ?? []}
              value={selectedHacsOption ?? null}
              getOptionLabel={(option) => option.label}
              sx={exRegisterAttributeStyle}
              renderInput={(params) => (
                <TextField {...params} label="Hazardous Area Classification" />
              )}
              onInputChange={(event, newInputValue) => {
                handleHacInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setSelectedHacsOption(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Stack>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={{ mt: 4 }}
          >
            <Button 
              variant="contained" 
              sx={{ ml: 2 }}>
              <span>Send Notification</span>
            </Button> 
            <Button 
              variant="contained" 
              sx={{ ml: 2 }} 
              onClick={() => handleSaveButtonClick()}>
              <span>Save</span>
            </Button> 
          </Stack>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <ExRegisterDocumentationTab />
        </CustomTabPanel>
      </Box>
    </>
  ) : (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Typography component={"span"} sx={{ textAlign: "center" }}>
        Please, select tag from gallery
      </Typography>
    </Container>
  );
});

export default ExRegisterDetails;
