import { observer } from "mobx-react";
import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import CreateUserCategoryMapButton from "../features/create/CreateUserCategoryMapButton";
import DeleteUserCategoryMapButton from "../features/delete/DeleteUserCategoryMapButton";
import EditUserCategoryMapButton from "../features/edit/EditUserCategoryMapButton";

const CustomToolBarUserCategoriesManagement = observer(() => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 0 0 10px",
        }}
      >
        <GridToolbarContainer>
          <CreateUserCategoryMapButton />
          <EditUserCategoryMapButton />
          <DeleteUserCategoryMapButton />
        </GridToolbarContainer>
      </div>
    </>
  );
});

export default CustomToolBarUserCategoriesManagement;
