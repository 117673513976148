import { observer } from "mobx-react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";

const AddAttribute = observer(() => {
  const navigate = useNavigate();

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<AddIcon />}
        onClick={() => navigate(ROUTES.masterAreaAddTagAttribute)}
      >
        <span>New</span>
      </Button>
    </>
  );
});

export default AddAttribute;
