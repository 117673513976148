import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const DeleteUserCategoryMapButton = observer(() => {
  const { userCategoryMapStore, notificationStore } = useStores();

  const handleDeleteClick = async () => {
    const usersIds = userCategoryMapStore.selectedIds;

    await userCategoryMapStore.deleteUserCategoryMap({
      usersIds: usersIds
    });

    userCategoryMapStore.fetchUserCategoryMaps();
    notificationStore.setNotificationMessage("Users categorys map deleted successfully");
    notificationStore.setShowNotification(true);
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<DeleteIcon />}
        onClick={handleDeleteClick}
        disabled={userCategoryMapStore.selectedIds.length < 1}
      >
        <span>Delete</span>
      </Button>
    </>
  );
});

export default DeleteUserCategoryMapButton;
