import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import {
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  Backdrop,
  CircularProgress
} from "@mui/material";
import { useState, useEffect } from "react";
import usePageTitle from "../../../hooks/usePageTitle";
import { areaboxStyle} from "../../../constants/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { NOTIFICATION_TYPES } from "../../../constants/notificationTypes";
import { NullableString } from "../../../models/common/response/types";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { UpdateFacilityRequest } from "../../../models/Dictionaries/request/updateFacilityRequest";
import { validationColor } from "../../../utils/validation";

const FacilityItem = observer(() => {
  usePageTitle("References - Facility");

  const navigate = useNavigate();

  const [urlParams] = useSearchParams()

  const { referencesStore, notificationStore } = useStores();
  
  const [facilityId, setFacilityId] = useState<number>(0);
  const [facility, setFacility] = useState<NullableString>("");
  const [facilityCode, setFacilityCode] = useState<NullableString>("");
  const [facilityDescription, setFacilityDescription] = useState<NullableString>("");
  const [facilityRemarks, setFacilityRemarks] = useState<NullableString>("");

 
  useEffect(() => {
    (async () => {   
      await initFacility();
    })()
  }, []);

  const initFacility = async () => {

    const idParam = parseInt(urlParams.get("id") ?? "0");
    await referencesStore.fetchFacilityById(idParam);

    setFacilityId(idParam);
    setFacility(referencesStore.facility?.facility ?? "");
    setFacilityCode(referencesStore.facility?.facilityCode ?? "");
    setFacilityDescription(referencesStore.facility?.facilityDescription ?? "");
    setFacilityRemarks(referencesStore.facility?.facilityRemarks ?? "");
  }
  
  const handleCloseClick = () => {
    navigate(-1);
  };

  const handleSaveClick = async () => {
    try {
      const facilityItem: UpdateFacilityRequest = {
        id: facilityId,
        facility: facility,
        facilityCode: facilityCode,
        facilityDescription: facilityDescription,
        facilityRemarks: facilityRemarks
      }
      await referencesStore.updateFacility(facilityItem);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Facility edited successfully");
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error editing facility: " + error);
    }
    finally {
      notificationStore.setShowNotification(true);
      navigate(ROUTES.referencesFacilities);
    }
  }

  return (
    referencesStore.facility ?
    <form>
    <Box
          sx={ areaboxStyle }
        > 
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "#fff",
              display: { xs: "block", md: "flex", width: "50%" }
            }}
          >
            <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
              <ArrowBackOutlinedIcon />
            </Button>
            <Button 
              variant="contained" 
              sx={{ ml: 2 }} 
              disabled={!facility || !facilityCode || !facilityDescription}
              onClick={() => handleSaveClick()}>
              <span>Save</span>
            </Button> 
          </Box>
        </Stack>
      </Box>
    {    
    <Box sx={{ "overflow-y": "scroll", height: "80vh" }}> 
      <Box
          sx={ areaboxStyle }
        >  
         <Typography
            id="add-tag-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
          Facility
          </Typography> 
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Facility"
            value={facility}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(facility) }}
            onChange={(e) => setFacility(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Facility Code"
            value={facilityCode}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(facilityCode) }}
            onChange={(e) => setFacilityCode(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Facility Description"
            value={facilityDescription}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColor(facilityDescription) }}
            onChange={(e) => setFacilityDescription(e.target.value)}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Facility Remarks"
            value={facilityRemarks}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            onChange={(e) => setFacilityRemarks(e.target.value)}
          />
        </Stack>
        </Box> 
      </Box>
      }
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={referencesStore.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </form>
      :
      null
  );
});

export default FacilityItem;
