export enum RelationType {
    "Unknown" = 0,
    "Child" = 1,
    "Parent" = 2,
    "Loop" = 3,
    "Reference" = 4,
    "Association" = 5,
    "From" = 6,
    "To" = 7,
    "Child Loop" = 8
  }