import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { useStores } from "../../../../hooks/useStores";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DataGridBox } from "../../../../styles/dataGridBox";
import TagDetailRelatedTagToolBar from "../components/tagRelated/TagDetailRelatedTagToolBar"
import { formatRelationType } from "../utils/utils"
import { MasterAssetDetailRelatedTags } from "../../../../models/MasterArea/response/getAssetsDetailResponse";
import { ROUTES } from "../../../../constants/routes";
import { useNavigate } from "react-router-dom";
import { formatDateWithTime } from "../../../../utils/formatDate";
import { NullableString } from "../../../../models/common/response/types";
import { RelationType } from "../../../../models/common/response/relationType";

const useMasterGridColumns = () => {
  
  const navigate = useNavigate();

  const { dictionaryStore } = useStores();

  const [relatyonTypeFilter, setRelatyonTypeFilter] = useState<NullableString[]>([]);
  const [controlAutonamingFilter, setControlAutonamingFilter] = useState<NullableString[]>([]);

  useEffect(() => {
    (async () => {        
      setRelatyonTypeFilter(Object.values(RelationType) as string[]);
      setControlAutonamingFilter(["Yes", "No"]);
    })();
  }, []); 

  const columns: GridColDef[] = useMemo(
    () => [
      { field: "tagNumber", headerName: "Tag Number", flex: 1, minWidth: 180,
        renderCell: (params) => {
          return (
            <>
              <a href="javascript:void(0);" rel="noreferrer" onClick={() => navigate(ROUTES.masterAreaEditTag + "?id=" + params.row.relatedTagId)}>
                {params.value}
              </a>
            </>
          );
        }
      },
      {
        field: "relationType",
        headerName: "Relation Type",
        flex: 1,
        minWidth: 150,
        renderCell: (params) => <span>{formatRelationType(params.value)}</span>,
        type: "singleSelect", valueOptions: relatyonTypeFilter
      },
      { field: "createdOn", headerName: "Created On", flex: 1, minWidth: 150, valueFormatter: formatDateWithTime },
      { field: "createdBy", headerName: "Created By", flex: 1, minWidth: 150 },
      { field: "category", headerName: "Category", flex: 1, minWidth: 150 },
      { field: "status", headerName: "Status", flex: 1, minWidth: 150 },
      { field: "controlAutonaming", headerName: "Control Autonaming", flex: 1, minWidth: 150, type: "singleSelect", valueOptions: controlAutonamingFilter  },
      
      { field: "disciplineTagNumber", headerName: "Discipline Tag Number", flex: 1, minWidth: 180 }
    ],
  [relatyonTypeFilter]
);

return columns;
};

interface MasterTagDetailRelatedTagTabProps {
  Toolbar: boolean;
  setIsFormChanged: any;
  displayChildsOnly: boolean;
  handleParentTagChange: any;
}

const MasterRelatedTagsTab = observer((props: MasterTagDetailRelatedTagTabProps) => {
  const columns = useMasterGridColumns();

  const {
    masterAreaCardViewStore: { masterRelatedTags, masterRelatedChildTags, setMasterRelatedTags, setMasterRelatedChildTags },
    masterAreaCardViewRelatedTagStore: relatedTagStore
  } = useStores();

  const [relatedTags, setRelatedTags] = useState<MasterAssetDetailRelatedTags[]>(props.displayChildsOnly ? masterRelatedChildTags : masterRelatedTags);

  /*const handleAddRelatedTag = (rTag: MasterAssetDetailRelatedTags) => {
    if (masterRelatedTags?.filter(r => rTag.id === r.id).length === 0 ) {
      const rTags = Object.assign([], masterRelatedTags) as MasterAssetDetailRelatedTags[];
      rTags.push(rTag);
      setRelatedTags([...rTags]);
      setMasterRelatedTags(rTags);
      setMasterRelatedChildTags(rTags.filter(t => t.relationTypeId === 1));
      props.setIsFormChanged(true);
    }
  };*/

  const handleAddRelatedTags = (addedRelatedTags: MasterAssetDetailRelatedTags[]) => {
    const rTags = Object.assign([], relatedTags) as MasterAssetDetailRelatedTags[];

    for (const addedRelatedTag of addedRelatedTags) {
      rTags.push(addedRelatedTag);    
    }

    setRelatedTags([...rTags]);
    setMasterRelatedTags(rTags);
    setMasterRelatedChildTags(rTags.filter(t => t.relationTypeId === 8));

    props.handleParentTagChange(rTags);
    props.setIsFormChanged(true);
  };

  const handleDeleteRelatedTag = () => {
    const rTags = relatedTags?.filter(r => relatedTagStore.selectedIds.indexOf(r.id) < 0 );
    setRelatedTags([...rTags]);
    setMasterRelatedTags(rTags);
    setMasterRelatedChildTags(rTags.filter(t => t.relationTypeId === 8));

    props.handleParentTagChange(rTags);
    props.setIsFormChanged(true);
  };

  return (
    <DataGridBox>
        <DataGrid 
          columns={columns} 
          rows={relatedTags ?? []} 
          getRowId={(row) => row.id} 
          density="comfortable"
          checkboxSelection
          onRowSelectionModelChange={(ids) => {
            const selectedIds = new Set(ids);
            relatedTagStore.setSelectedIds(
              Array.from(selectedIds).map((id) => parseInt(id as string))
            );
          }}
          slots={{
            toolbar: props.Toolbar ? TagDetailRelatedTagToolBar : null,
          }}
          slotProps={{ toolbar: { RelatedTags: relatedTags, handleAddRelatedTags: handleAddRelatedTags, handleDeleteRelatedTag: handleDeleteRelatedTag, displayChildsOnly: props.displayChildsOnly } }}
          />
      </DataGridBox>
  );
});

export default MasterRelatedTagsTab;
