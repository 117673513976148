import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { SelectOption } from "../../../../../models/common/selectOption";
import { ProjectEquipmentTag } from "../../../../../stores/MasterArea/MasterAreaProjectsStore";

interface ProjectAddEquipmentTagModalProps {
  handleAddEquipmentTag: any;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const ProjectAddEquipmentTagModal = observer((props: ProjectAddEquipmentTagModalProps) => {
  const {
    masterAreaProjectsStore, dictionaryStore
  } = useStores();

  const [selectedEquipmentTag, setSelectedEquipmentTag] = useState<SelectOption | null>(null);

  const handleCancelClick = () => {
    setSelectedEquipmentTag(null);
    masterAreaProjectsStore.setShowAddEquipmentTagModal(false);
  };

  const handleAddClick = () => {
    if(selectedEquipmentTag) {
      const eqTag: ProjectEquipmentTag = {
        status: "new",
        id: selectedEquipmentTag.id,
        tagNumber: selectedEquipmentTag.label,
        category: "",
        createdBy: "",
        createdOn: null,
        controlAutonaming: null
      };

      props.handleAddEquipmentTag(eqTag);

      setSelectedEquipmentTag(null);
      masterAreaProjectsStore.setShowAddEquipmentTagModal(false);
    }
  }; 

  const handleEquipmentTagChange = (selectedValue: SelectOption | null) => {
    setSelectedEquipmentTag(selectedValue);
  };

  const debouncedHandleInputChange = useDebounce(500);
  const handleEquipmentTagInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchMasterTagNumberOptions(inputValue);
    });
  };

  return (
    <Modal
      open={masterAreaProjectsStore.showAddEquipmentTagModal}
      onClose={handleCancelClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="add-tag-attributes-title"
          variant="h6"
          component="h2"
          sx={{ mb: 4 }}
        >
          Add Equipment Tag
        </Typography>
        <Box
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <Autocomplete
          size="small"
          id="EquipmentTag"         
          options={dictionaryStore.masterTagNumberOptions ?? []}
          value={selectedEquipmentTag}
          getOptionLabel={(option) => option.label}
          sx={{ width: "100%", mr: 1, mb: 2, display: "inline-flex" }}
          renderInput={(params) => (
            <TextField {...params} label="Equipment Tag" />
          )}
          onInputChange={(event, newInputValue) => {
            handleEquipmentTagInputChange(newInputValue);
          }}
          onChange={(event, selectedValue) => {
            handleEquipmentTagChange(selectedValue);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
            justifyContent: "flex-end",
          }}
        >
          <Button 
            variant="contained" 
            sx={{ ml: 2 }} 
            onClick={handleAddClick}
            disabled={!selectedEquipmentTag}>
            <span>Add</span>
          </Button>
          <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCancelClick}>
            <span>Cancel</span>
          </Button>
        </Box>
      </Box>
      
    </Modal>
  );
});

export default ProjectAddEquipmentTagModal;
