import { GridSortModel } from "@mui/x-data-grid";
import { makeAutoObservable, runInAction } from "mobx";
import { Filter } from "../../models/ProjectArea/request/getAssetsMinRequest";
import { SortOrder } from "../../models/common/request/sorting";
import apiClient from "../../api/clients";
import { GetMasterAssetsMinResponse } from "../../models/MasterArea/response/getMasterAssetsMinResponse";
import {
  GetMasterAssetsMinRequest,
  MasterAssetsMinFilterOptions,
  MasterAssetsMinSortOptions,
} from "../../models/MasterArea/request/getMasterAssetsMinRequest";
import { GetMasterAssetDetailResponse, MasterAssetDetail, MasterAssetDetailHistory, MasterAssetDetailRelatedTags, MasterAssetDetailAttributes, MasterDocuments } from "../../models/MasterArea/response/getAssetsDetailResponse";

class MasterAreaCardViewStore {
  showCardModal = false;
  showDetailLoading = false;
  showWorkingCopyModal = false;
  showDuplicateWarningModal = false;
  showDuplicateRelatedTagModal = false;
  showValidateSequenceNumberModal = false;
  masterAssetDetail?: MasterAssetDetail;
  masterRelatedTags: MasterAssetDetailRelatedTags[] = [];
  masterRelatedChildTags: MasterAssetDetailRelatedTags[] = [];
  masterTagAttributes: MasterAssetDetailAttributes[] = [];
  masterDocuments: MasterDocuments[] = [];
  masterHistory: MasterAssetDetailHistory[] = [];
  isValidForm: boolean = false;

  masterAssetsMinResponse: GetMasterAssetsMinResponse = {
    items: [],
    pageIndex: 0,
    pageSize: 0,
    totalPages: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    totalRecords: 0,
  };

  paginationModel = {
    pageSize: 100,
    page: 0,
  };

  sortModel: GridSortModel = [];
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setShowDetailLoading = (showDetailLoading: boolean) => {
    this.showDetailLoading = showDetailLoading;
  };

  setPaginationModel = (paginationModel: any) => {
    this.paginationModel = paginationModel;
  };

  setSortModel = (sortModel: any) => {
    this.sortModel = sortModel;
  };

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  setShowCardModal = (value: boolean) => {
    this.showCardModal = value;
  };

  setShowWorkingCopyModal = (value: boolean) => {
    this.showWorkingCopyModal = value;
  };

  setShowDuplicateWarningModal = (value: boolean) => {
    this.showDuplicateWarningModal = value;
  };

  setShowDuplicateRelatedTagModal = (value: boolean) => {
    this.showDuplicateRelatedTagModal = value;
  };

  setShowValidateSequenceNumberModal = (value: boolean) => {
    this.showValidateSequenceNumberModal = value;
  };

  setMasterHistory = (value: MasterAssetDetailHistory[]) => {
    this.masterHistory = value;
  };

  setMasterDocuments = (value: MasterDocuments[]) => {
    this.masterDocuments = value;
  };

  setMasterRelatedTags = (value: MasterAssetDetailRelatedTags[]) => {
    this.masterRelatedTags = value;
  };

  setMasterRelatedChildTags = (value: MasterAssetDetailRelatedTags[]) => {
    this.masterRelatedChildTags = value.map(tag => { if (tag.relationType === "Child Loop") tag.relationType = "Child"; return tag })
  };

  setMasterAttributes = (value: MasterAssetDetailAttributes[]) => {
    this.masterTagAttributes = value;
  };

  setIsValidForm = (value: boolean) => {
    this.isValidForm = value;
  };

  fetchMasterAssetsMin = async (
    prjId?: number,
    tagNo?: string,
    areaId?: number,
    fcltId?: number,
    untId?: number,
    srchTxt?: string
  ) => {
    try {
      const { page, pageSize } = this.paginationModel;

      const filterOptions: MasterAssetsMinFilterOptions = {
        projectNumberId: prjId,
        tagNumber: tagNo,
        areaId: areaId,
        facilityId: fcltId,
        unitId: untId,
        searchText: srchTxt,
      };

      const sortOptions: MasterAssetsMinSortOptions = {
        projectNumber: "projectNumber",
        tagNumber: "tagNumber",
        status: "status",
      };

      const sortField = this.sortModel[0]?.field;
      const sortOrder =
        this.sortModel[0]?.sort === "asc" ? SortOrder.ASC : SortOrder.DESC;
      const sortProperty = sortField
        ? sortOptions[sortField as keyof MasterAssetsMinSortOptions]
        : undefined;
      const filter = Object.keys(filterOptions).reduce((acc, key) => {
        const filterValue =
          filterOptions[key as keyof MasterAssetsMinFilterOptions];
        if (filterValue !== undefined) {
          acc[key as keyof Filter] = { filterValue: filterValue as never };
        }
        return acc;
      }, {} as Filter);
      const sort = sortProperty
        ? {
            [sortProperty]: { sortOrder },
          }
        : undefined;

      const request: GetMasterAssetsMinRequest = {
        pagination: { pageIndex: page, pageSize },
        filter,
        sort,
      };
      this.setIsLoading(true);

      const data = await apiClient.getMasterAssetsMin(request);

      runInAction(() => {
        this.masterAssetsMinResponse = data;
        this.isLoading = false;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  fetchMasterAssetById = async (id: number) => {
    try {
      const asset = await apiClient.getMasterAssetDetail(id);
      runInAction(() => {
        this.masterAssetDetail = asset;
      });
    } catch (error) {
      console.error("Error fetching Master Asset:", error);
    }
  };

  getMasterAssetById = async (id: number) : Promise<GetMasterAssetDetailResponse | null> => {
    try {
      const asset = await apiClient.getMasterAssetDetail(id);
      this.masterAssetDetail = asset;
      return asset;
    } catch (error) {
      console.error("Error fetching Master Asset:", error);
    }
    return null;
  };

}

export default MasterAreaCardViewStore;
