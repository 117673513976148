import { makeAutoObservable, runInAction } from "mobx";
import { GetInfoByIdResponse } from "../../models/ProjectArea/response/getInfoByIdResponse";
import apiClient from "../../api/clients";

class ProjectAreaCardViewInfoStore {
  infoList?: GetInfoByIdResponse[];

  constructor() {
    makeAutoObservable(this);
  }

  fetchInfoById = async (id: number) => {
    try {
      const info = await apiClient.getInfoById(id);
      runInAction(() => {
        this.infoList = info;
      });
    } catch (error) {
      console.error("Error fetching info:", error);
    }
  };
}

export default ProjectAreaCardViewInfoStore;
