import { GridColDef } from "@mui/x-data-grid";
import { useStores } from "../../../../hooks/useStores";
import { observer } from "mobx-react";
import { formatDateWithTime } from "../../../../utils/formatDate";
import { DataGridBox } from "../../../../styles/dataGridBox";
import TagDetailDocumentToolBar from "../components/documents/TagDetailDocumentToolBar";
import { useEffect } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";

const columns: GridColDef[] = [
  { field: "type", headerName: "Type", flex: 1, minWidth: 150 },
  {
    field: "documentCode",
    headerName: "Code",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "documentNumber",
    headerName: "Number",
    flex: 1,
    minWidth: 300,
    renderCell: (params) => {
      const href = params.row.documentUrl;
      return href ? (
        <a href={href} target="_blank" rel="noreferrer">
          {params.value}
        </a>
      ) : (
        <span>{params.value}</span>
      );
    },
  },
  {
    field: "documentTitle",
    headerName: "Title",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "createdOn",
    headerName: "Created On",
    flex: 1,
    minWidth: 120,
    valueFormatter: formatDateWithTime,
  },
  {
    field: "createdBy",
    headerName: "Created By",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "modifiedOn",
    headerName: "Modified On",
    flex: 1,
    minWidth: 120,
    valueFormatter: formatDateWithTime,
  },
  {
    field: "modifiedBy",
    headerName: "Modified By",
    flex: 1,
    minWidth: 200,
  },
];

const TagDetailDocumentationTab = observer(() => {
  const {
    projectAreaCardViewStore: { documents },
    projectAreaCardViewDocumentStore,
    dictionaryStore
  } = useStores();

  useEffect(() => {
    dictionaryStore.fetchDocTypeOptions("");
    projectAreaCardViewDocumentStore.getProjDocOptions("");
  }, []);

  return (
    <DataGridBox>
      <DataGridPro
        columns={columns}
        rows={documents ?? []}
        density="comfortable"
        checkboxSelection
        onRowSelectionModelChange={(ids) => {
          const selectedIds = new Set(ids);
          projectAreaCardViewDocumentStore.setSelectedIds(
            Array.from(selectedIds).map((id) => parseInt(id as string))
          );
         
        }}
        slots={{
          toolbar: TagDetailDocumentToolBar,
        }}
        sx={{height: "700px"}}
      />
    </DataGridBox>
  );
});

export default TagDetailDocumentationTab;
