import { makeAutoObservable, runInAction } from "mobx";
import apiClient from "../../../api/clients";
import { GetFacilitiesDataTableResponse, GetFacilityResponse } from "../../../models/Dictionaries/response/getFacilitiesResponse";
import { GetFluidsDataTableResponse, GetFluidsResponse } from "../../../models/Dictionaries/response/getFluidsResponse";
import { GetLocationsDataTableResponse, GetLocationsResponse } from "../../../models/Dictionaries/response/getLocationsResponse";
import { GetAreaResponse, GetAreasDataTableResponse } from "../../../models/Dictionaries/response/getAreasResponse";
import { GetUnitResponse, GetUnitsDataTableResponse } from "../../../models/Dictionaries/response/getUnitsResponse";
import { GetBuildingsDataTableResponse, GetBuildingsResponse } from "../../../models/Dictionaries/response/getBuildingsResponse";
import { GetPipeClassesDataTableResponse, GetPipeClassesResponse } from "../../../models/Dictionaries/response/getPipeClassesResponse";
import { GetSubstationsDataTableResponse, GetSubstationsResponse } from "../../../models/Dictionaries/response/getSubstationsResponse";
import { GetWellsDataTableResponse, GetWellsResponse } from "../../../models/Dictionaries/response/getWellsResponse";
import { UpdateFluidRequest } from "../../../models/Dictionaries/request/updateFluidRequest";
import { GetSequenceResponse, GetSequencesResponse } from "../../../models/Dictionaries/response/getSequencesResponse";
import { UpdateSequenceRequest } from "../../../models/Dictionaries/request/updateSequenceRequest";
import { UpdateAreaRequest } from "../../../models/Dictionaries/request/updateAreaRequest";
import { UpdateBuildingRequest } from "../../../models/Dictionaries/request/updateBuildingRequest";
import { UpdateFacilityRequest } from "../../../models/Dictionaries/request/updateFacilityRequest";
import { UpdateLocationRequest } from "../../../models/Dictionaries/request/updateLocationRequest";
import { UpdatePipeClassRequest } from "../../../models/Dictionaries/request/updatePipeClassRequest";
import { UpdateSubstationRequest } from "../../../models/Dictionaries/request/updateSubstationRequest";
import { UpdateUnitRequest } from "../../../models/Dictionaries/request/updateUnitRequest";
import { UpdateWellRequest } from "../../../models/Dictionaries/request/updateWellRequest";
import { GetReferenceRequest } from "../../../models/Dictionaries/request/getReferenceRequest";
import { CreateAreaRequest } from "../../../models/Dictionaries/request/createAreaRequest";
import { CreateFacilityRequest } from "../../../models/Dictionaries/request/createFacilityRequest";
import { CreateUnitRequest } from "../../../models/Dictionaries/request/createUnitRequest";
import { CreateBuildingRequest } from "../../../models/Dictionaries/request/createBuildingRequest";
import { CreateFluidRequest } from "../../../models/Dictionaries/request/createFluidRequest";
import { CreateLocationRequest } from "../../../models/Dictionaries/request/createLocationRequest";
import { CreatePipeClassRequest } from "../../../models/Dictionaries/request/createPipeClassRequest";
import { CreateSequenceRequest } from "../../../models/Dictionaries/request/createSequenceRequest";
import { CreateSubstationRequest } from "../../../models/Dictionaries/request/createSubstationRequest";
import { CreateWellRequest } from "../../../models/Dictionaries/request/createWellRequest";
import { GetParallelTrainsDataTableResponse, GetParallelTrainsResponse } from "../../../models/Dictionaries/response/getParallelTrainsResponse";
import { CreateParallelTrainRequest } from "../../../models/Dictionaries/request/createParallelTrainRequest";
import { UpdateParallelTrainRequest } from "../../../models/Dictionaries/request/updateParallelTrainRequest";
import { GridFilterModel } from "@mui/x-data-grid";

class ReferencesStore {
  isLoading = false;

  area: GetAreaResponse | null = null;
  areas: GetAreasDataTableResponse | null = null;
  building: GetBuildingsResponse | null = null;
  buildings: GetBuildingsDataTableResponse | null = null;
  facility: GetFacilityResponse | null = null;
  facilities: GetFacilitiesDataTableResponse | null = null;
  fluid: GetFluidsResponse | null = null;
  fluids: GetFluidsDataTableResponse | null = null;
  location: GetLocationsResponse | null = null;
  locations: GetLocationsDataTableResponse | null = null;
  parallelTrain: GetParallelTrainsResponse | null = null;
  parallelTrains: GetParallelTrainsDataTableResponse | null = null;
  pipeClass: GetPipeClassesResponse | null = null;
  pipeClasses: GetPipeClassesDataTableResponse | null = null;
  sequence: GetSequenceResponse | null = null;
  sequenceResponse: GetSequencesResponse | null = null;
  substation: GetSubstationsResponse | null = null;
  substations: GetSubstationsDataTableResponse | null = null;
  unit: GetUnitResponse | null = null;
  units: GetUnitsDataTableResponse | null = null;
  well: GetWellsResponse | null = null;
  wells: GetWellsDataTableResponse | null = null;

  paginationModel = {
    pageSize: 250,
    page: 0,
  };

  filterModel: GridFilterModel = { items: [] } ;

  setPaginationModel = (paginationModel: any) => {
    this.paginationModel = paginationModel;
  };

  constructor() {
    makeAutoObservable(this);
  }

  setFilterModel = (filterModel: any) => {
    this.filterModel = filterModel;
  };

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  fetchAreas = async () => {
    const { page, pageSize } = this.paginationModel;
    const request: GetReferenceRequest = {
      pagination: { pageIndex: page, pageSize },
      dataGridFilter: this.filterModel
    };

    try {
      this.setIsLoading(true);
      const response = await apiClient.fetchAreasDataTable(request);

      runInAction(() => {
        this.areas = response;
        this.setIsLoading(false);
      });
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error fetching areas:", error);
    }
  };

  fetchAreaById = async (id: number) => {    
    try {
      this.setIsLoading(true);
      const data = await apiClient.fetchAreaById(id);
      runInAction(() => {
        this.area = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

  getAreaById = async (id: number) => {    
    try {
      const data = await apiClient.fetchAreaById(id);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  
  async createArea(request: CreateAreaRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.createArea(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error creating area:", error);
      throw error;
    }
  }

  async updateArea(request: UpdateAreaRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.updateArea(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error editing area:", error);
      throw error;
    }
  }

  fetchBuildings = async () => {
    const { page, pageSize } = this.paginationModel;
    const request: GetReferenceRequest = {
      pagination: { pageIndex: page, pageSize },
      dataGridFilter: this.filterModel
    };

    try {
      this.setIsLoading(true);
      const response = await apiClient.fetchBuildingsDataTable(request);

      runInAction(() => {
        this.buildings = response;
        this.setIsLoading(false);
      });
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error fetching buildings:", error);
    }
  };

  fetchBuildingById = async (id: number) => {    
    try {
      this.setIsLoading(true);
      const data = await apiClient.fetchBuildingById(id);
      runInAction(() => {
        this.building = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

  async createBuilding(request: CreateBuildingRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.createBuilding(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error creating building:", error);
      throw error;
    }
  }

  async updateBuilding(request: UpdateBuildingRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.updateBuilding(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error editing building:", error);
      throw error;
    }
  }

  fetchFacilities = async () => {
    const { page, pageSize } = this.paginationModel;
    const request: GetReferenceRequest = {
      pagination: { pageIndex: page, pageSize },
      dataGridFilter: this.filterModel
    };

    try {
      this.setIsLoading(true);
      const response = await apiClient.fetchFacilitiesDataTable(request);

      runInAction(() => {
        this.facilities = response;
        this.setIsLoading(false);
      });
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error fetching facilities:", error);
    }
  };

  fetchFacilityById = async (id: number) => {    
    try {
      this.setIsLoading(true);
      const data = await apiClient.fetchFacilityById(id);
      runInAction(() => {
        this.facility = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

  getFacilityById = async (id: number) => {    
    try {
      const data = await apiClient.fetchFacilityById(id);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async createFacility(request: CreateFacilityRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.createFacility(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error creating facility:", error);
      throw error;
    }
  }

  async updateFacility(request: UpdateFacilityRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.updateFacility(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error editing facility:", error);
      throw error;
    }
  }

  fetchFluids = async () => {
    const { page, pageSize } = this.paginationModel;
    const request: GetReferenceRequest = {
      pagination: { pageIndex: page, pageSize },
      dataGridFilter: this.filterModel
    };

    try {
      this.setIsLoading(true);
      const response = await apiClient.fetchFluidsDataTable(request);

      runInAction(() => {
        this.fluids = response;
        this.setIsLoading(false);
      });
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error fetching fluids:", error);
    }
  };

  fetchFluidById = async (id: number) => {    
    try {
      this.setIsLoading(true);
      const data = await apiClient.fetchFluidById(id);
      runInAction(() => {
        this.fluid = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

  async createFluid(request: CreateFluidRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.createFluid(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error creating fluid:", error);
      throw error;
    }
  }

  async updateFluid(request: UpdateFluidRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.updateFluid(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error editing fluid:", error);
      throw error;
    }
  }

  fetchLocations = async () => {
    const { page, pageSize } = this.paginationModel;
    const request: GetReferenceRequest = {
      pagination: { pageIndex: page, pageSize },
      dataGridFilter: this.filterModel
    };

    try {
      this.setIsLoading(true);
      const response = await apiClient.fetchLocationsDataTable(request);
      runInAction(() => {
        this.locations = response;
        this.setIsLoading(false);
      });
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error fetching locations:", error);
    }
  };

  fetchLocationById = async (id: number) => {    
    try {
      this.setIsLoading(true);
      const data = await apiClient.fetchLocationById(id);
      runInAction(() => {
        this.location = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

  async createLocation(request: CreateLocationRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.createLocation(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error creating location:", error);
      throw error;
    }
  }

  async updateLocation(request: UpdateLocationRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.updateLocation(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error editing location:", error);
      throw error;
    }
  }

  fetchParallelTrains = async () => {
    const { page, pageSize } = this.paginationModel;
    const request: GetReferenceRequest = {
      pagination: { pageIndex: page, pageSize },
      dataGridFilter: this.filterModel
    };

    try {
      this.setIsLoading(true);
      const response = await apiClient.fetchParallelTrainsDataTable(request);

      runInAction(() => {
        this.parallelTrains = response;
        this.setIsLoading(false);
      });
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error fetching parallel trains:", error);
    }
  };

  fetchParallelTrainById = async (id: number) => {    
    try {
      this.setIsLoading(true);
      const data = await apiClient.fetchParallelTrainById(id);
      runInAction(() => {
        this.parallelTrain = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

  async createParallelTrain(request: CreateParallelTrainRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.createParallelTrain(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error creating parallel train: ", error);
      throw error;
    }
  }

  async updateParallelTrain(request: UpdateParallelTrainRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.updateParallelTrain(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error editing parallel train: ", error);
      throw error;
    }
  }

  fetchPipeClasses = async () => {
    const { page, pageSize } = this.paginationModel;
    const request: GetReferenceRequest = {
      pagination: { pageIndex: page, pageSize },
      dataGridFilter: this.filterModel
    };

    try {
      this.setIsLoading(true);
      const response = await apiClient.fetchPipeClassesDataTable(request);

      runInAction(() => {
        this.pipeClasses = response;
        this.setIsLoading(false);
      });
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error fetching pipe classes:", error);
    }
  };

  fetchPipeClassById = async (id: number) => {    
    try {
      this.setIsLoading(true);
      const data = await apiClient.fetchPipeClassById(id);
      runInAction(() => {
        this.pipeClass = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

  async createPipeClass(request: CreatePipeClassRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.createPipeClass(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error creating pipe class:", error);
      throw error;
    }
  }

  async updatePipeClass(request: UpdatePipeClassRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.updatePipeClass(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error editing pipe class:", error);
      throw error;
    }
  }

  fetchSequences = async () => {
    const { page, pageSize } = this.paginationModel;
    const request: GetReferenceRequest = {
      pagination: { pageIndex: page, pageSize },
      dataGridFilter: this.filterModel
    };

    try {
      this.setIsLoading(true);
      const response = await apiClient.fetchSequences(request);

      runInAction(() => {
        this.sequenceResponse = response;
        this.setIsLoading(false);
      });
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error fetching fluids:", error);
    }
  };

  fetchSequenceById = async (id: number) => {    
    try {
      this.setIsLoading(true);
      const data = await apiClient.fetchSequenceById(id);
      runInAction(() => {
        this.sequence = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

  async createSequence(request: CreateSequenceRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.createSequence(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error creating sequence:", error);
      throw error;
    }
  }

  async updateSequence(request: UpdateSequenceRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.updateSequence(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error editing fluid:", error);
      throw error;
    }
  }

  fetchSubstations = async () => {
    const { page, pageSize } = this.paginationModel;
    const request: GetReferenceRequest = {
      pagination: { pageIndex: page, pageSize },
      dataGridFilter: this.filterModel
    };

    try {
      this.setIsLoading(true);
      const response = await apiClient.fetchSubstationsDataTable(request);

      runInAction(() => {
        this.substations = response;
        this.setIsLoading(false);
      });
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error fetching substations:", error);
    }
  };

  fetchSubstationById = async (id: number) => {    
    try {
      this.setIsLoading(true);
      const data = await apiClient.fetchSubstationById(id);
      runInAction(() => {
        this.substation = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

  async createSubstation(request: CreateSubstationRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.createSubstation(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error creating building:", error);
      throw error;
    }
  }

  async updateSubstation(request: UpdateSubstationRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.updateSubstation(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error editing substation:", error);
      throw error;
    }
  }

  fetchUnits = async () => {
    const { page, pageSize } = this.paginationModel;
    const request: GetReferenceRequest = {
      pagination: { pageIndex: page, pageSize },
      dataGridFilter: this.filterModel
    };

    try {
      this.setIsLoading(true);
      const response = await apiClient.fetchUnitsDataTable(request);

      runInAction(() => {
        this.units = response;
        this.setIsLoading(false);
      });
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error fetching units:", error);
    }
  };

  fetchUnitById = async (id: number) => {    
    try {
      this.setIsLoading(true);
      const data = await apiClient.fetchUnitById(id);
      runInAction(() => {
        this.unit = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

  getUnitById = async (id: number) => {    
    try {
      const data = await apiClient.fetchUnitById(id);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async createUnit(request: CreateUnitRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.createUnit(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error creating unit:", error);
      throw error;
    }
  }

  async updateUnit(request: UpdateUnitRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.updateUnit(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error editing unit:", error);
      throw error;
    }
  }

  fetchWells = async () => {
    const { page, pageSize } = this.paginationModel;
    const request: GetReferenceRequest = {
      pagination: { pageIndex: page, pageSize },
      dataGridFilter: this.filterModel
    };

    try {
      this.setIsLoading(true);
      const response = await apiClient.fetchWellsDataTable(request);

      runInAction(() => {
        this.wells = response;
        this.setIsLoading(false);
      });
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error fetching wells:", error);
    }
  };

  getWellById = async (id: number) => {    
    try {
      const data = await apiClient.fetchWellById(id);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  fetchWellById = async (id: number) => {    
    try {
      this.setIsLoading(true);
      const data = await apiClient.fetchWellById(id);
      runInAction(() => {
        this.well = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

  async createWell(request: CreateWellRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.createWell(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error creating well:", error);
      throw error;
    }
  }

  async updateWell(request: UpdateWellRequest) {
    try {
      this.setIsLoading(true);
      await apiClient.updateWell(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error editing well:", error);
      throw error;
    }
  }

}

export default ReferencesStore;
