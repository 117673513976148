import React, { useState } from "react";
import { Stack } from '@mui/material';
import { observer } from "mobx-react";
import LoadingButton from "@mui/lab/LoadingButton";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useStores } from "../../../../../hooks/useStores";

const ImportFromExcelFileAttributesButton = observer(() => {
  const [selectedAttributeFile, setSelectedAttributeFile] = useState<File | null>(null);

  const { masterAreaImportFromExcel } = useStores();

  const handleAttributeFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedAttributeFile(file);
    masterAreaImportFromExcel.setImportResponse("");
  };


  const handleImport = async () => {
    if (selectedAttributeFile) {
      await masterAreaImportFromExcel.importFromExcelMasterTagAttributes(selectedAttributeFile);
      setSelectedAttributeFile(null);
    }
  };

  return (
    <div>
      <input
        accept=".xlsx, .xls"
        type="file"
        id="excel-file-input-attributes"
        style={{ display: "none" }}
        onChange={handleAttributeFileChange}
      />
      <Stack direction="row">
        <Stack sx={{ mr: 5 }}>
        <label htmlFor="excel-file-input-attributes">
          <LoadingButton
            loadingPosition="start"
            variant="text"
            size="small"
            component="span"
            loading={masterAreaImportFromExcel.isLoading}
            startIcon={<UploadFileIcon />}
          >
            Import Tag Attributes
          </LoadingButton>
        </label> 
        {selectedAttributeFile && (
          <div>
            <p>File selected: {selectedAttributeFile.name}</p>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleImport}
              loading={masterAreaImportFromExcel.isLoading}
              startIcon={<UploadFileIcon />}
            >
              Import
            </LoadingButton>
          </div>
        )}
        </Stack>
      
      </Stack>
      
      {!masterAreaImportFromExcel.isLoading &&
        masterAreaImportFromExcel.importResponse && (
          <p>{masterAreaImportFromExcel.importResponse}</p>
        )}
    </div>
  );
});

export default ImportFromExcelFileAttributesButton;
