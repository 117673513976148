import { GridColDef } from "@mui/x-data-grid";
import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { useStores } from "../../../../hooks/useStores";
import { DataGridBox } from "../../../../styles/dataGridBox";
import { formatDateWithTime } from "../../../../utils/formatDate";
import TagDetailTagAttributesToolBar from "../components/tagAttributes/TagDetailTagAttributesToolBar"
import { MasterAssetDetailAttributes } from "../../../../models/MasterArea/response/getAssetsDetailResponse";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { ROUTES } from "../../../../constants/routes";
import { useNavigate } from "react-router";
import { NullableString } from "../../../../models/common/response/types";

const useMasterGridColumns = () => {
  const navigate = useNavigate();

  const { dictionaryStore } = useStores();

  const [attributeClassFilter, setAttributeClassFilter] = useState<NullableString[]>([]);
  const [uomFilter, setUomFilter] = useState<NullableString[]>([]);

  useEffect(() => {
    (async () => {  
      await dictionaryStore.fetchEclTagAttributesOptions("");
      await dictionaryStore.fetchUnitOfMeasuresOptions("");
      
      setAttributeClassFilter(dictionaryStore.eclTagAttributesOptions.map(o => o.label));
      setUomFilter(dictionaryStore.uomOptions.map(o => o.label));
    })();
  }, []); 
  
  const columns: GridColDef[] = useMemo(
    () => [
        {
          field: "tagAttributeValue",
          headerName: "Tag Attribute Value",
          flex: 1,
          minWidth: 180,
              renderCell: (params) => {
                return (
                  <>
                    <a href="javascript:void(0);" rel="noreferrer" onClick={() => navigate(ROUTES.masterAreaEditTagAttribute + "?id=" + params.row.id)}>
                      {params.value}
                    </a>
                  </>
                );
              } 
        },
        {
          field: "uom",
          headerName: "UOM",
          flex: 1,
          minWidth: 100,
          type: "singleSelect", valueOptions: uomFilter
        },
        {
          field: "attributeClass",
          headerName: "Attribute Class",
          flex: 1,
          minWidth: 200,
          type: "singleSelect", valueOptions: attributeClassFilter
        },
        {
          field: "createdOn",
          headerName: "Created On",
          flex: 1,
          minWidth: 150,
          valueFormatter: formatDateWithTime,
        },
        {
          field: "createdBy",
          headerName: "Created By",
          flex: 1,
          minWidth: 200,
        },
        {
          field: "modifiedOn",
          headerName: "Modified On",
          flex: 1,
          minWidth: 150,
          valueFormatter: formatDateWithTime,
        },
        {
          field: "modifiedBy",
          headerName: "Modified By",
          flex: 1,
          minWidth: 200,
        },
    ],
  [attributeClassFilter, uomFilter]
);
return columns;
};

interface MasterTagDetailAttributesTabProps {
  Toolbar: boolean;
  setIsFormChanged: any;
}

const MasterTagDetailAttributesTab = observer((props: MasterTagDetailAttributesTabProps) => {
  const columns = useMasterGridColumns();

  const {
    masterAreaCardViewStore: { masterTagAttributes, setMasterAttributes },
    masterAreaTagAttributesStore
  } = useStores();

  const [attributes, setAttributes] = useState<MasterAssetDetailAttributes[]>(masterTagAttributes);

  const handleAddAttribute = (attr: MasterAssetDetailAttributes) => {
    const attrs = Object.assign([], attributes);
    attrs.push(attr);
    setAttributes([...attrs]);
    setMasterAttributes(attrs);
    props.setIsFormChanged(true);
  };

  const handleDeleteAttribute = () => {
    const attrs = attributes?.filter(d => masterAreaTagAttributesStore.selectedIds.indexOf(d.id) < 0);
    setAttributes([...attrs]);
    setMasterAttributes(attrs);
    props.setIsFormChanged(true);
  };

  const handleEditAttribute = (attr: MasterAssetDetailAttributes) => {
    const attrs = attributes?.filter(d => d.id === attr.id);
    if(attrs.length > 0) {
      const editedAttr = attrs[0];
      editedAttr.tagAttributeValue = attr.tagAttributeValue;
      editedAttr.uom = attr.uom;
      editedAttr.uomId = attr.uomId;
      editedAttr.modifiedBy = attr.modifiedBy;
      editedAttr.modifiedOn = attr.modifiedOn;

      const newAttrs = attributes?.filter(d => d.id !== attr.id);
      const newObjAttrs = Object.assign([], newAttrs);
      newObjAttrs.push(editedAttr);
      setAttributes([...newObjAttrs]);
      setMasterAttributes(newObjAttrs);
      props.setIsFormChanged(true);
    }
    return true;
  };

  return (
    <DataGridBox>
      <DataGridPro
        columns={columns}
        rows={attributes ?? []}
        getRowId={(row) => row.id} 
        density="comfortable"
        checkboxSelection
        onRowSelectionModelChange={(ids) => {
          const selectedIds = new Set(ids);
          masterAreaTagAttributesStore.setSelectedIds(
            Array.from(selectedIds).map((id) => parseInt(id as string))
          );
          masterAreaTagAttributesStore.setTagAttributeValue("");
          masterAreaTagAttributesStore.setUom("");
          masterAreaTagAttributesStore.setSelectedId(masterAreaTagAttributesStore.selectedId);
        }}
        slots={{
          toolbar: props.Toolbar ? TagDetailTagAttributesToolBar : null,
        }}
        slotProps={{ toolbar: { Attributes: attributes, handleAddAttribute: handleAddAttribute, handleDeleteAttribute: handleDeleteAttribute, handleEditAttribute: handleEditAttribute } }}
      />
    </DataGridBox>
  );
});

export default MasterTagDetailAttributesTab;
