import { NullableBoolean, NullableString, NullableNumber } from "../../../../models/common/response/types";
import { TagCategories } from "../constants/constants";
import { RelationType } from "../../../../models/common/response/relationType";
import { SelectOption } from "../../../../models/common/selectOption";
import { MasterDocuments } from "../../../../models/MasterArea/response/getAssetsDetailResponse";

export function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  export function isNullValueString(str: NullableString) {
    return str === null || str === undefined || str === ""; 
  }

  export function isNoStatus(statusId: NullableNumber) {
    return statusId === null || statusId === 8;
  }

  export function processBoolean(bool: NullableBoolean) {
    return bool ?? false;
  }

  export function processString(str: NullableString) {
    return str ?? "";
  }

  export function buildStringOption(param1: NullableString, param2: NullableString) {
    if(!param1 || !param2) return "";
    return param1 + " - " + param2;
  }

  export function validateField(optionValue: NullableString) {
    return optionValue ? true : false;
  }

  export function validateFieldWithControlAutonaming(optionValue: NullableString, isControlAutonaming: boolean) {
    const isValid = optionValue || !isControlAutonaming ? true : false;
    return isValid;
  }

  export function validationColorWithControlAutonaming(optionValue: NullableString, isControlAutonaming: boolean) {
    const isValid = optionValue || !isControlAutonaming ? true : false;
    return isValid ? "#fff" : "#ffd8d8";
  }

  export function validatePrimaryTag(primaryTagValue?: NullableString, tagCategoryCode?: NullableString, isControlAutonaming?: boolean) {
    const isValid = (tagCategoryCode === TagCategories.Instrument && isControlAutonaming) 
    || (tagCategoryCode === TagCategories.InstrumentCable && isControlAutonaming) 
    || (tagCategoryCode === TagCategories.ProcessTelecomPAGASecurityEquipment && isControlAutonaming)
    || tagCategoryCode === TagCategories.FireandGasInstrument || !isControlAutonaming || primaryTagValue ? true : false;
    return  isValid;
  }

  export function validationPrimaryTagColor(primaryTagValue?: NullableString, tagCategoryCode?: NullableString, isControlAutonaming?: boolean) {
    const isValid = (tagCategoryCode === TagCategories.Instrument && isControlAutonaming) 
    || (tagCategoryCode === TagCategories.InstrumentCable && isControlAutonaming) 
    || (tagCategoryCode === TagCategories.ProcessTelecomPAGASecurityEquipment && isControlAutonaming)
    || tagCategoryCode === TagCategories.FireandGasInstrument || !isControlAutonaming || primaryTagValue ? true : false;
    return  isValid? "#fff" : "#ffd8d8";
  }

  export function validateDisciplineTagNumber(isControlAutonaming: boolean, optionValue: NullableString) {
    const isValid = isControlAutonaming || optionValue ? true : false;
    return isValid;
  }

  export function disciplineTagNumberColor(isControlAutonaming: boolean, optionValue: NullableString) {
    const isValid = isControlAutonaming || optionValue ? true : false;
    return isValid ? "#fff" : "#ffd8d8";
  }

  export function validateBuilding(optionValue: NullableString, tagCategoryCode: NullableString, isControlAutonaming: boolean) {
    return tagCategoryCode !== TagCategories.OnPlotBuildServices || optionValue || !isControlAutonaming ? true: false;
  }

  export function buildingColor(optionValue: NullableString, tagCategoryCode: NullableString, isControlAutonaming: boolean) {
    return tagCategoryCode !== TagCategories.OnPlotBuildServices || optionValue || !isControlAutonaming ? "#fff" : "#ffd8d8";
  }

  export function validateSequenceNumber(isControlAutonaming: boolean, optionValue: NullableString, tagCategoryCode?: string) {
    const isValid = (!optionValue &&
                    (tagCategoryCode === TagCategories.AuxiliaryElectricalEquipment || tagCategoryCode === TagCategories.AuxiliaryEquipmentCables 
                    || tagCategoryCode === TagCategories.DistributionCircuit || tagCategoryCode === TagCategories.InstrumentLoop
                    || tagCategoryCode === TagCategories.JunctionBox || tagCategoryCode === TagCategories.PrimaryEquipmentCable)) 
                  ||
                    (!optionValue && !isControlAutonaming && (tagCategoryCode === TagCategories.FireandGasInstrument || tagCategoryCode === TagCategories.Instrument
                    || tagCategoryCode === TagCategories.InstrumentAirManifoldJBFrame || tagCategoryCode === TagCategories.InstrumentCable
                    || tagCategoryCode === TagCategories.Manifold || tagCategoryCode === TagCategories.MechanicalEquipment
                    || tagCategoryCode === TagCategories.OnPlotBuildServices || tagCategoryCode === TagCategories.OtherElectricalCable
                    || tagCategoryCode === TagCategories.Panel || tagCategoryCode === TagCategories.PipeRack
                    || tagCategoryCode === TagCategories.PipingLines || tagCategoryCode === TagCategories.PrimaryElectricalEquipment
                    || tagCategoryCode === TagCategories.ProcessTelecomPAGASecurityEquipment || tagCategoryCode === TagCategories.SpecialPipingItems
                    || tagCategoryCode === TagCategories.UndergroundServices || tagCategoryCode === TagCategories.WirelessEquipment
                    ))   
? false : true;
    return isValid;
  }

  export function sequenceNumberColor(isControlAutonaming: boolean, optionValue: NullableString, tagCategoryCode?: string) {
    const isValid = (!optionValue &&
                    (tagCategoryCode === TagCategories.AuxiliaryElectricalEquipment || tagCategoryCode === TagCategories.AuxiliaryEquipmentCables 
                    || tagCategoryCode === TagCategories.DistributionCircuit || tagCategoryCode === TagCategories.InstrumentLoop
                    || tagCategoryCode === TagCategories.JunctionBox || tagCategoryCode === TagCategories.PrimaryEquipmentCable)) 
                  ||
                    (!optionValue && !isControlAutonaming && (tagCategoryCode === TagCategories.FireandGasInstrument || tagCategoryCode === TagCategories.Instrument
                    || tagCategoryCode === TagCategories.InstrumentAirManifoldJBFrame || tagCategoryCode === TagCategories.InstrumentCable
                    || tagCategoryCode === TagCategories.Manifold || tagCategoryCode === TagCategories.MechanicalEquipment
                    || tagCategoryCode === TagCategories.OnPlotBuildServices || tagCategoryCode === TagCategories.OtherElectricalCable
                    || tagCategoryCode === TagCategories.Panel || tagCategoryCode === TagCategories.PipeRack
                    || tagCategoryCode === TagCategories.PipingLines || tagCategoryCode === TagCategories.PrimaryElectricalEquipment
                    || tagCategoryCode === TagCategories.ProcessTelecomPAGASecurityEquipment || tagCategoryCode === TagCategories.SpecialPipingItems
                    || tagCategoryCode === TagCategories.UndergroundServices || tagCategoryCode === TagCategories.WirelessEquipment
                    ))   
      ? false : true;
    return isValid ? "#fff" : "#ffd8d8";
  }

  export function hideTagLimit(tagCategoryCode: NullableString) {
    return tagCategoryCode === TagCategories.DistributionCircuit || tagCategoryCode === TagCategories.InstrumentAirManifoldJBFrame ||
           tagCategoryCode === TagCategories.Manifold || tagCategoryCode === TagCategories.OtherElectricalCable ||
           tagCategoryCode === TagCategories.Panel || tagCategoryCode === TagCategories.PipeRack ||
           tagCategoryCode === TagCategories.PipingLines ||
           tagCategoryCode === TagCategories.SpecialPipingItems || tagCategoryCode === TagCategories.Structures ||
           tagCategoryCode === TagCategories.UndergroundServices || tagCategoryCode === TagCategories.WirelessEquipment; 
  }

  export function checkTagLimitCondition(isControlAutonaming: boolean, multipleTags: NullableNumber) {
    return !isControlAutonaming || !multipleTags || multipleTags < 2;
  }

  export function formatRelationType(value: string | number) {
    if (typeof value === "number")
      return RelationType[value];  
    else return value;
  }

  export function isPrimaryTagRequired(tagCategoryCode?: string) {
    return tagCategoryCode === TagCategories.AuxiliaryElectricalEquipment || tagCategoryCode === TagCategories.AuxiliaryEquipmentCables 
    || tagCategoryCode === TagCategories.DistributionCircuit || tagCategoryCode === TagCategories.Instrument   
    || tagCategoryCode === TagCategories.InstrumentCable || tagCategoryCode === TagCategories.InstrumentLoop
    || tagCategoryCode === TagCategories.JunctionBox || tagCategoryCode === TagCategories.PrimaryEquipmentCable
    || tagCategoryCode === TagCategories.ProcessTelecomPAGASecurityEquipment || tagCategoryCode === TagCategories.FireandGasInstrument;
  }

  export function getPrimaryTagLabel(tagCategoryCode?: string) {
    if (tagCategoryCode === TagCategories.AuxiliaryElectricalEquipment)
      return "Primary Equipment Tag Number";
    else if (tagCategoryCode === TagCategories.AuxiliaryEquipmentCables)
      return "Auxillary Equipment Tag";
    else if (tagCategoryCode === TagCategories.FireandGasInstrument)
      return "Loop number";
    else if (tagCategoryCode === TagCategories.Instrument)
      return "Loop/Group/Primary number";
    else if (tagCategoryCode === TagCategories.PrimaryEquipmentCable)
      return "Primary Equipment Tag Number";
    else return "Primary Tag"
  }

  export function isFittingNumberRequired(tagCategoryCode?: string) {
    return tagCategoryCode === TagCategories.DistributionCircuit;  
  }

  export function isCircuitNumberRequired(tagCategoryCode?: string) {
    return tagCategoryCode === TagCategories.DistributionCircuit;  
  }

  export function isBuildingRequired(tagCategoryCode?: string) {
    return tagCategoryCode === TagCategories.MechanicalEquipment || tagCategoryCode === TagCategories.OnPlotBuildServices;  
  }

  export function isLocationRequired(tagCategoryCode?: string) {
    return tagCategoryCode === TagCategories.Panel;  
  }

  export function isSubstationRequired(tagCategoryCode?: string) {
    return tagCategoryCode === TagCategories.PrimaryElectricalEquipment; 
  }

  export function isInsulationTypeRequired(tagCategoryCode?: string) {
    return tagCategoryCode === TagCategories.PipingLines;  
  }

  export function isNominalDiameterRequired(tagCategoryCode?: string) {
    return tagCategoryCode === TagCategories.PipingLines;  
  }

  export function isPipeClassRequired(tagCategoryCode?: string) {
    return tagCategoryCode === TagCategories.PipingLines || tagCategoryCode === TagCategories.SpecialPipingItems;  
  }

  export function isFluidRequired(tagCategoryCode?: string) {
    return tagCategoryCode === TagCategories.UndergroundServices;  
  }

  export function isServiceDescriptionRequired(tagCategoryCode?: string) {
    return tagCategoryCode !== TagCategories.PipingLines;  
  }

  export function selectOptionToMasterDocument(options: SelectOption[], masterDocs: MasterDocuments[]): MasterDocuments[] {
    const masterDocuments: MasterDocuments[] = [];
    for (const option of options)
    {
      let document: MasterDocuments | null = null;
      const selectedDocs = masterDocs?.filter(d => d.documentId === option.id);
      if(selectedDocs.length > 0) {
        document = selectedDocs[0];
      }
      else {
        document = {
          documentId: option.id,
          documentCode: option.label,
          documentType: null,
          documentUrl: null,
          createdOn: null,
          createdBy: null,
          modifiedOn: null,
          modifiedBy: null
        };
      }
      masterDocuments.push(document);
    }
    return masterDocuments;
  }

  export function masterDocumentsToSelectOptions(masterDocuments: MasterDocuments[]): SelectOption[] {
    const options: SelectOption[] = [];
    for (const masterDocument of masterDocuments)
    {
        const option: SelectOption = {
          id: masterDocument.documentId,
          label: masterDocument.documentCode ?? ""
        };
        options.push(option);
    }
    return options;
  }