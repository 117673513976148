  export const smallTextFieldStyle = {
    mr: 1,
    mb: 2,
    width: "90%",
    "min-width": "142px"
  };

  export const largeTextFieldStyle = {
    mr: 1,
    mb: 2,
    width: "90%",
    "min-width": "250px"
  };
  
  export  const textFieldStyle = {
    mr: 1,
    mb: 2,
    width: "24%",
  };
  
  export const photoStyle = {
    mr: 1,
    mb: 2,
    width: "100%",
    "margin-top": "5px"
  };

  export const exRegisterAttributeStyle = {
    mr: 1,
    // mb: 2,
    width: "100%",
    "margin-top": "5px"
  };
  
  export const remarksStyle = {
    mr: 1,
    mb: 2,
    width: "100%",
    "margin-top": "5px"
  };
  
  export const documentNumberFieldStyle = {
    mr: 1,
    mb: 2,
    width: "90%",
    "min-width": "320px"
  };

  export const longTextFieldStyle = {
    mr: 1,
    mb: 2,
    width: "97%",
    "min-width": "300px"
  };